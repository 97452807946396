<template>
  <div class="page-wrapper">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.tenants') }}</h1>
    </div>

    <div class="cards cards-bookkeeping">
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.menu.organizations') }}</div>

        <router-link class="btn btn-secondary link-not-like" to="/tenants/organizations">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.menu.tariffPlans') }}</div>

        <router-link class="btn btn-secondary link-not-like" to="/tenants/tariff-plans">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TenantsView"
}
</script>

<style scoped>

</style>