<template>
  <div class="page-wrap">

    <div class="page-head">
      <div class="breadcrumbs">
        <div class="h1">
          <router-link class="link-not-like" to="/settings">{{ $vuetify.lang.t('$vuetify.menu.settings') }}
          </router-link>
        </div>
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.settings.language-settings') }}
        </div>
      </div>
    </div>

    <div class="">
      <h2 class="h2">
        {{ $vuetify.lang.t("$vuetify.usedLanguages") }}
      </h2>

      <div class="flex no-error-messages">
        <template v-for="(lang) in Object.keys($vuetify.lang.locales)">
          <v-chip :key="lang" v-if="lang !== 'en' && lang !== 'ru'"
                  color="secondary"
          >
            <v-checkbox v-model="langSettings[lang]" hide-details
                        :label="lang + ' ' + emoji[lang]"></v-checkbox>
          </v-chip>
        </template>
      </div>
    </div>

    <div v-if="primary">
      <h2 class="h2 mt-4">
        {{ $vuetify.lang.t("$vuetify.editedLanguages") }}
      </h2>

      <div class="flex">
        <template v-for="(lang) in Object.keys($vuetify.lang.locales)">
          <v-chip v-if="lang !== 'en' && lang !== 'ru'" :key="lang + '1'"
                  color="secondary"
          >
            <v-checkbox v-model="editSettings[lang]" hide-details
                        :label="lang + ' ' + emoji[lang]"></v-checkbox>
          </v-chip>

        </template>
      </div>
    </div>
    <div class="page-head mt-4" v-if="primary">
      <!--      <button class="btn btn-primary" @click="closeAll">-->
      <!--        {{ $vuetify.lang.t('$vuetify.closeAll') }}-->
      <!--      </button>-->
      <div class="page__sorts">
        <v-text-field class="search-field"
                      v-model="search" @keypress.enter="searchTranslations"
                      @click:clear="clearSearch"
                      :placeholder="$vuetify.lang.t('$vuetify.sorts.search')"
                      outlined clearable
                      prepend-inner-icon="$search" hide-details>
        </v-text-field>
      </div>
      <div>
        <button class="btn btn-primary" @click="openCreate">
          {{ $vuetify.lang.t('$vuetify.add') }}
        </button>
      </div>
    </div>

    <div class="languages pb-3" v-if="primary">
      <div class="lang__list" v-if="languages.length">
        <!--        <language-item v-for="item in languages[0].content" :key="item" :item="item"></language-item>-->
        <language-item
          v-for="(item, key) in languages[0].content"
          :key="key"
          :item-key="String(key)"
          :item-value="item"
          :translations="translations"
          :closed="closed"
          :item-path="[key]"

          @update-language="updateLanguage"
        ></language-item>
      </div>
    </div>
    <v-dialog v-model="create_modal">
      <div class="modal">
        <v-text-field v-model="new_item.key" :label="$vuetify.lang.t('$vuetify.key')"></v-text-field>
        <ul>
          <li v-for="lang in languages" :key="lang.filename">
            <v-text-field v-model="new_item.values[lang.filename]"
                          :label="emoji[lang.filename] + ' ' +lang.filename"></v-text-field>
          </li>
        </ul>
        <button class="btn btn-primary" @click="create">{{ $vuetify.lang.t('$vuetify.save') }}</button>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import LanguageItem from "@/components/LanguageItem.vue";

export default {
  name: "LanguageSettingsView",
  components: {LanguageItem},

  data: function () {
    return {
      emoji: {
        "az": "🇦🇿", // Azerbaijan
        "be": "🇧🇾", // Belarus
        "bg": "🇧🇬", // Bulgaria
        "cs": "🇨🇿", // Czech Republic (formerly Czechoslovakia)
        "da": "🇩🇰", // Denmark
        "de": "🇩🇪", // Germany
        "el": "🇬🇷", // Greece
        "eng": "🇬🇧", // United Kingdom
        "es": "🇪🇸", // Spain
        "est": "🇪🇪", // Estonia
        "fin": "🇫🇮", // Finland
        "fr": "🇫🇷", // France
        "he": "🇮🇱", // Israel
        "hy": "🇦🇲", // Armenia
        "it": "🇮🇹", // Italy
        "kk": "🇰🇿", // Kazakhstan
        "lt": "🇱🇹", // Lithuania
        "lv": "🇱🇻", // Latvia
        "no": "🇳🇴", // Norway
        "pl": "🇵🇱", // Poland
        "pt": "🇵🇹", // Portugal
        "ro": "🇷🇴", // Romania
        "rus": "🇷🇺", // Russia
        "sv": "🇸🇪", // Sweden
        "tg": "🇹🇯", // Tajikistan
        "tr": "🇹🇷", // Turkey
        "uk": "🇺🇦"  // Ukraine
      },
      languages: {},
      languages_clear: {},
      translations: {},
      closed: true,
      create_modal: false,
      new_item: {
        key: '',
        values: []
      },
      langSettings: {},
      editSettings: {
        [localStorage.getItem('lang') ?? 'eng']: true
      },
      search: '',
      primary: JSON.parse(localStorage.getItem('organization')).is_primary,
    }
  },

  mounted() {
    this.loadLanguageSettings()
    if (JSON.parse(localStorage.getItem('organization')).is_primary) {
      this.loadEditingLanguages()
    }
  },

  methods: {
    loadLanguageSettings() {
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings?key=languages',
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then(response => {
        this.langSettings = response.data.data.data
      })
    },

    saveLanguageSettings() {
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings',
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          key: 'languages',
          value: JSON.stringify(this.langSettings),
        }
      })
    },

    loadEditingLanguages() {
      this.$axios({
        url: this.$hostname + "time-tracking/languages",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          langs: JSON.stringify(Object.keys(this.editSettings).filter(key => this.editSettings[key]))
        },
      }).then((response) => {
        this.languages_clear = response.data.data
        this.languages = response.data.data
        this.buildTranslations()
        this.languages = []

        this.languages = response.data.data
      })
    },

    searchTranslations() {
      const searchKeys = new Set();
      const lowerCaseSearch = this.search.toLowerCase();

      // Сначала находим все ключи, в которых содержится подстрока
      this.languages.forEach(language => {
        this.collectMatchingKeys(language.content, lowerCaseSearch, searchKeys);
      });

      // Затем фильтруем контент всех языков, оставляя только найденные ключи
      const results = this.languages.map(language => {
        const filteredContent = this.filterContentByKeys(language.content, searchKeys);
        return {
          filename: language.filename,
          content: filteredContent
        };
      });

      this.languages = results;
      this.buildTranslations();
    },

    collectMatchingKeys(content, search, searchKeys, path = []) {
      for (const key in content) {
        const value = content[key];
        const currentPath = path.concat(key);

        if (typeof value === 'object' && value !== null) {
          this.collectMatchingKeys(value, search, searchKeys, currentPath);
        } else if (typeof value === 'string' && value.toLowerCase().includes(search)) {
          searchKeys.add(currentPath.join('.'));
        }
      }
    },

    filterContentByKeys(content, searchKeys, path = []) {
      const filteredContent = {};

      for (const key in content) {
        const value = content[key];
        const currentPath = path.concat(key).join('.');

        if (typeof value === 'object' && value !== null) {
          const nestedContent = this.filterContentByKeys(value, searchKeys, path.concat(key));
          if (Object.keys(nestedContent).length > 0) {
            filteredContent[key] = nestedContent;
          }
        } else if (searchKeys.has(currentPath)) {
          filteredContent[key] = value;
        }
      }

      return filteredContent;
    },

    clearSearch() {
      this.languages = this.languages_clear
      this.buildTranslations()
    },

    create() {
      let path = this.new_item.key.split('.');
      Object.entries(this.new_item.values).forEach(([lang, value]) => {
        this.updateLanguage({path, value, lang})
      })

      this.create_modal = false
    },
    openCreate() {
      this.new_item = {
        key: '',
        values: []
      }
      this.languages.forEach(el => {
        this.new_item.values[el.filename] = ''

      })
      this.create_modal = true
    },
    closeAll() {
      this.closed = new Boolean(true)
    },

    buildTranslations() {
      // Создаем объект переводов для каждого пути
      let translations = {};
      // Строим объект переводов для всех языков, кроме первого
      for (let i = 0; i < this.languages.length; i++) {
        this.addTranslationsAtPath(this.languages[i].content, translations, this.languages[i].filename, []);
      }
      this.translations = translations;
    },
    addTranslationsAtPath(content, translations, lang, path) {
      for (const key in content) {
        const newPath = path.concat(key);
        if (typeof content[key] === 'object' && content[key] !== null) {
          this.addTranslationsAtPath(content[key], translations, lang, newPath);
        } else {
          // Устанавливаем значение по пути
          let ref = translations;
          for (const step of newPath.slice(0, -1)) {
            if (!ref[step]) ref[step] = {};
            ref = ref[step];
          }
          if (!ref[newPath[newPath.length - 1]]) {
            ref[newPath[newPath.length - 1]] = {};
          }
          ref[newPath[newPath.length - 1]][lang] = content[key];
        }
      }
    },
    updateLanguage({path, value, lang}) {
      // Находим нужный языковой объект и обновляем значение по пути
      let ref = this.languages.find(l => {
        return l.filename === lang
      }).content;
      for (const step of path.slice(0, -1)) {
        ref = ref[step];
      }
      ref[path[path.length - 1]] = value;

      this.$axios({
        url: this.$hostname + "time-tracking/languages",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          language: lang,
          content: JSON.stringify(this.languages.find(el => el.filename == lang).content)
        },
      }).then(() => {
      }).catch((response) => {
        console.log(response)
      })
    }
  },

  watch: {
    langSettings: {
      handler() {
        this.saveLanguageSettings()
      },
      deep: true
    },
    editSettings: {
      handler() {
        this.closeAll()
        this.loadEditingLanguages()
      },
      deep: true
    },
  }
}
</script>
