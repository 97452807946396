<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1 class="h1 dTm-none">{{ $vuetify.lang.t('$vuetify.menu.news') }}</h1>
      <div class="page-head__buttons">
        <button class="btn-secondary btn " @click="arch = !arch; page=1">
          <span v-if="!arch">{{ $vuetify.lang.t('$vuetify.archive') }}</span>
          <span v-else>{{ $vuetify.lang.t('$vuetify.outArchive') }}</span>
        </button>
        <router-link class="btn btn-secondary link-not-like" to="/newsAdd" v-if="permissions.admin">+
          {{ $vuetify.lang.t('$vuetify.addNew') }}
        </router-link>
      </div>
    </div>
    <div class="cards news-cards">
      <news-card
          v-for="newsCard in news"
          :key="newsCard.id"
          :is-can-edit="permissions.admin"
          :image="$media_url + newsCard.photo_path"
          :title="newsCard.title"
          :id="newsCard.id"
          @onArchive="onArchive"
          @onDelete="onDelete"
          :author="newsCard.author ? (newsCard.author.name + ' ' + newsCard.author.lastname) : ''"
          :date="newsCard.create_date"
      ></news-card>
    </div>

    <ConfirmArchiveModal v-model="confirmArchive" @confirm="archiveNew" :active="isNewsActive">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>

    <ConfirmModal v-model="confirmDelete" @confirm="deleteNew">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>
</template>

<script>
import NewsCard from "./parts/NewsCard.vue";
import ConfirmModal from "@/components/confirmModal.vue";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "news-list",
  components: {
    ConfirmArchiveModal,
    ConfirmModal,
    NewsCard,
  },

  computed: {
    ...mapGetters(['permissions']),

  isNewsActive() {
      return this.candidateNews?.active ?? false
    },
    candidateNews() {
      return this.news.find(e => e.id === this.candidate)
    }
  },
  data: function () {
    return {
      news: [],
      arch: false,
      candidate: null,
      confirmArchive: false,
      confirmDelete: false,

      alertError: false,
      alertMsg: "",
    };
  },
  watch: {
    arch: function () {
      this.loadData()
    },
  },

  mounted: function () {
    this.loadData();
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
        params: {
          arch: !this.arch
        }
      }).then((response) => {
        this.news = response.data.data.data;
      });
      if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
        this.$axios({
          url: this.$hostname + "time-tracking/news/unread",
          method: "DELETE",
          headers: {
            Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                    sessionStorage.getItem("auth_token")),
          },
        })
      }
    },
    onArchive(id) {
      this.candidate = id
      this.confirmDelete = false
      this.confirmArchive = true
    },
    onDelete(id) {
      this.candidate = id
      this.confirmDelete = true
      this.confirmArchive = false
    },

    archiveNew() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.candidateNews.id,
          title: this.candidateNews.title,
          text: this.candidateNews.text,
          active: !this.candidateNews.active
        },
      }).then(() => {
        this.confirmArchive = false
        this.loadData()
      }).catch((errors) => {
        this.alertError = true
        this.alertMsg = this.$catch(errors)
      });
    },
    deleteNew() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "DELETE",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.candidate,
        },
      }).then(() => {
        this.confirmDelete = false
        this.loadData()
      })
    }
  },
};
</script>
