<template>
  <div class="cards__single document-card">
    <h1>
      {{ $vuetify.lang.t('$vuetify.accounting.' + mode + '.new') }}
    </h1>
    <div class="document-card__columns">
      <div>
        <div class="document-card__documents-body" v-if="newDocument.path.length>0 || documents.length>0">
          <div class="h2"> {{ $vuetify.lang.t('$vuetify.accounting.documents.label') }}</div>
          <ul v-if="id">
            <li v-for="document in newDocument.path" :key="document"><a
              :href="$media_url + document" target="_blank">
              {{
                document.substr(document.lastIndexOf('/') + 1, document.lastIndexOf('_') + 1) + document.substr(document.lastIndexOf('.'))
              }}
            </a></li>
          </ul>
          <ul>
            <li v-for="document in documents" :key="document.name"><a href="">{{ document.name }}</a>
            </li>
          </ul>
        </div>

        <div class="document-card__fields form">
          <div class="h2"> {{ $vuetify.lang.t('$vuetify.accounting.serviceList') }}</div>
          <button class="btn-secondary btn" @click="openAddItem">
            + {{ $vuetify.lang.t('$vuetify.accounting.card.addService') }}
          </button>

          <div class="document-card__items">
            <div class="items__list" v-for="(item, i) in items" :key="item.id || i">
              <div class="items__row">
                <span class="bolder">{{ item.name }}</span>
                <div class="items__buttons">
                  <button @click="openEditItem(item)">
                    <edit-icon/>
                  </button>
                  <button @click="deleteItem(item)">
                    <delete-icon/>
                  </button>
                </div>
              </div>
              <div class="items__row">
              <span>{{ Number(item.quantity).toFixed(2) }} x
                {{ Number(item.price * (1 - item.discount / 100)).toFixed(2) }}€</span>
                <span>{{ (item.quantity * (item.price * (1 - item.discount / 100))).toFixed(1) }}€</span>
              </div>
            </div>
            <div class="document-card__divider"></div>
            <div class="items__list">
              <div class="items__row">
                <span>{{ $vuetify.lang.t('$vuetify.accounting.totalWithoutTax') }}:</span>
                <span>{{ totalWithoutTax }}</span>
              </div>
              <div class="items__row">
                <span>{{ $vuetify.lang.t('$vuetify.accounting.card.tax') }}:</span>
                <span>{{ tax }}</span>
              </div>
              <div class="items__row">
                <span>{{ $vuetify.lang.t('$vuetify.accounting.total') }}:</span>
                <span>{{ total }}</span>
              </div>
            </div>
          </div>

          <v-form ref="addItem" v-if="add_item_form" @submit.prevent="">
            <div class="document-card__subtitle bolder"> {{ $vuetify.lang.t('$vuetify.accounting.newService') }}</div>
            <div class="form__row">
              <v-text-field v-model="newItem.name"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.name')"
                            :rules="[$rules.required]" outlined></v-text-field>
              <v-btn icon @click="add_item_form=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-select v-model="newItem.type"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.type')"
                      :items="selectTypes" item-text="name" item-value="value" outlined attach
                      :rules="[$rules.required]"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }">
              <template v-slot:selection="{ item }">
                {{
                  $vuetify.lang.t('$vuetify.accounting.card.type' + item.value[0].toUpperCase() + item.value.substr(1))
                }}
              </template>
              <template v-slot:item="{ item }">
                {{
                  $vuetify.lang.t('$vuetify.accounting.card.type' + item.value[0].toUpperCase() + item.value.substr(1))
                }}
              </template>
            </v-select>
            <div class="form__row">
              <v-text-field type="number" v-model="newItem.quantity"
                            :label="$vuetify.lang.t('$vuetify.wages.quantity')"
                            outlined :rules="[$rules.required]"></v-text-field>
              <v-combobox class="custom" v-model="newItem.measurement"
                          :value="$vuetify.lang.t('$vuetify.measurements.' + item) ? $vuetify.lang.t('$vuetify.measurements.' + item) : item"
                          :label="$vuetify.lang.t('$vuetify.wages.measurement')"
                          :items="measurements" item-text="name" item-value="value" outlined attach
                          :menu-props="{
                          bottom: true,
                          offsetY: true,
                          maxHeight: width<1024 ? 200 : 800,
                          contentClass: 'select-menu',
                        }">
                <template v-slot:item="{ item }">
                  {{
                    $vuetify.lang.t('$vuetify.measurements.' + item) ? $vuetify.lang.t('$vuetify.measurements.' + item) : item
                  }}
                </template>
                <template v-slot:selection="{ item}">
                  <span>{{
                      $vuetify.lang.t('$vuetify.measurements.' + item) ? $vuetify.lang.t('$vuetify.measurements.' + item) : item
                    }}</span>
                </template>
              </v-combobox>
            </div>
            <div class="form__row">
              <v-text-field v-model="newItem.price" type="number"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.price')"
                            :rules="[$rules.required, $rules.positiveNumbers]" outlined></v-text-field>
              <v-select v-model="newItem.tax"
                        :label="$vuetify.lang.t('$vuetify.accounting.card.tax')"
                        :items="taxes" item-text="tax" item-value="tax" outlined attach
                        :rules="[$rules.requiredNumber]"
                        :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"></v-select>
              <v-text-field v-model="newItem.discount" type="number"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.discount')" outlined></v-text-field>
            </div>
            <div class="form__row">
              <v-text-field type="number"
                            :value="(newItem.price * newItem.quantity * (1-newItem.discount/100)).toFixed(2)"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.cost')" outlined readonly
                            hide-details></v-text-field>
              <button class="btn btn-primary" @click="addItem">{{ $vuetify.lang.t('$vuetify.add') }}</button>
            </div>
          </v-form>
        </div>
      </div>

      <v-form ref="form" class="document-card__fields form">
        <div class="h2"> {{ $vuetify.lang.t('$vuetify.accounting.details') }}</div>
        <div class="document-card__subtitle">
          <span class="bolder" v-if="newDocument.id">
            {{ $vuetify.lang.t('$vuetify.accounting.' + mode + '.number') }}: {{ newDocument.id }}
          </span>
        </div>
        <v-select v-model="newDocument.client" @change="loadObjects"
                  :label="$vuetify.lang.t('$vuetify.accounting.card.client')"
                  :items="clients" item-text="name" item-value="id"
                  :menu-props="{
                        bottom: true,
                        offsetY: true,
                        maxHeight: width<1024 ? 200 : 800,
                        contentClass: 'select-menu',
                    }" outlined attach :rules="[$rules.required]"></v-select>
        <v-autocomplete v-model="newDocument.object"
                        :items="objects" item-text="name" item-value="id"
                        :label="$vuetify.lang.t('$vuetify.accounting.card.object')"
                        :menu-props="{
                        bottom: true,
                        offsetY: true,
                        maxHeight: width<1024 ? 200 : 800,
                        contentClass: 'select-menu',
                    }" outlined attach>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title v-if="newDocument.client == 0">
                {{ $vuetify.lang.t('$vuetify.selectClient') }}
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ $vuetify.lang.t('$vuetify.noDataText') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-menu ref="date" v-model="dateMenu" :close-on-content-click="false"
                transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="new Date(newDocument.create_date).toLocaleDateString('ru-Ru')"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.dateCreate')" append-icon="$calendar"
                          v-bind="attrs"
                          v-on="on"
                          outlined readonly :rules="[$rules.required]">
            </v-text-field>
          </template>
          <v-date-picker v-model="newDocument.create_date" @input="dateMenu = false" :allowed-dates="allowedDates"
                         no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                         show-adjacent-months></v-date-picker>
        </v-menu>
        <v-select v-model="newDocument.term"
                  :label="$vuetify.lang.t('$vuetify.accounting.card.term')"
                  :items="terms" item-text="days" item-value="id" outlined attach
                  :rules="[$rules.required]"
                  :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"></v-select>
        <div v-if="mode == 'sales'">
          <v-select v-if="id" v-model="newDocument.status"
                    :label="$vuetify.lang.t('$vuetify.accounting.card.status')"
                    :items="selectStatus" item-text="name" item-value="value" outlined attach
                    :rules="[$rules.required]"
                    :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }">
            <template v-slot:selection="{ item }">
              {{
                $vuetify.lang.t('$vuetify.accounting.card.status' + item.value[0].toUpperCase() + item.value.substr(1))
              }}
            </template>
            <template v-slot:item="{ item }">
              {{
                $vuetify.lang.t('$vuetify.accounting.card.status' + item.value[0].toUpperCase() + item.value.substr(1))
              }}
            </template>
          </v-select>
          <v-menu ref="date" v-model="menu.datePayment" :close-on-content-click="false"
                  transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="newDocument.payment_date ? new Date(newDocument.payment_date).toLocaleDateString('ru-Ru') : '' "
                :label="$vuetify.lang.t('$vuetify.accounting.card.datepurchases')" append-icon="$calendar"
                v-bind="attrs"
                v-on="on"
                outlined readonly>
              </v-text-field>
            </template>
            <v-date-picker v-model="newDocument.payment_date" :min="newDocument.create_date"
                           @input="menu.datePayment = false"
                           no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                           show-adjacent-months></v-date-picker>
          </v-menu>
        </div>
        <div class="document-card__divider"></div>
        <div v-if="mode == 'sales'">
          <div class="form__row">
            <v-menu ref="date" v-model="menu.dateRemind" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="newDocument.remind_date ? new Date(newDocument.remind_date).toLocaleDateString('ru-Ru') : '' "
                  :label="$vuetify.lang.t('$vuetify.accounting.card.dateRemind')" append-icon="$calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined readonly>
                </v-text-field>
              </template>
              <v-date-picker v-model="newDocument.remind_date" @input="menu.dateRemind = false"
                             no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                             show-adjacent-months></v-date-picker>
            </v-menu>
            <v-text-field v-model.number="newDocument.percent_delay" type="number" suffix="%"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.percentDelay')" outlined></v-text-field>
          </div>
          <div class="form__row">
            <v-text-field v-model="newDocument.number_link"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.numberLink')" outlined></v-text-field>
          </div>
          <v-textarea v-model="newDocument.description"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.description')"
                      outlined></v-textarea>
        </div>
        <div v-if="mode == 'offers'" class="document-card__checkboxes">
          <div class="document-card__subtitle bolder"> {{ $vuetify.lang.t('$vuetify.accounting.fromClient') }}</div>
          <v-checkbox v-model="newDocument.from_client.material"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.material')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.delivery"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.delivery')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.tool"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.tool')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.lifts"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.lifts')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.scaffolding"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.scaffolding')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.montage_scaffolding"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.montage_scaffolding')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.garbage"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.garbage')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.garbage_containers"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.garbage_containers')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.security"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.security')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.social_room"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.social_room')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.unloading"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.unloading')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.spreading"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.spreading')"></v-checkbox>
          <v-checkbox v-model="newDocument.from_client.cleaning"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.cleaning')"></v-checkbox>

        </div>
        <v-textarea v-model="newDocument.comment"
                    :label="$vuetify.lang.t('$vuetify.accounting.card.comment')" outlined></v-textarea>
        <v-file-input accept=".jpg, .png, .pdf, .doc, .docx" class="file-input" v-model="documents" multiple
                      :placeholder="$vuetify.lang.t('$vuetify.downloadDocument')" prepend-icon="$document">
        </v-file-input>
      </v-form>
    </div>

    <div class="document-card__bottom">
      <div></div>
      <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
      <div v-if="successMsg" class="success--text">{{ successMsg }}</div>
      <div class="btn-group">
        <div class="btn-primary btn" @click="uploadFile">Сохранить</div>
        <div class="btn btn-danger" @click="tmp_delete">Удалить</div>
      </div>
    </div>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>
  </div>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import SpinnerLoader from "@/components/SpinnerLoader";
import {mapGetters} from "vuex";

export default {
  name: 'add-document-big',
  components: {SpinnerLoader, DeleteIcon, EditIcon},
  props: {
    mode: [String],
    id: [String, Number]
  },

  data: function () {
    return {
      url: "",

      clients: [],
      terms: [],
      taxes: [],
      objects: [],
      selectStatus: [
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.statusCreated'), value: 'created'},
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.statusPayed'), value: 'payed'},
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.statusExpired'), value: 'expired'},
      ],
      selectTypes: [
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.typeMaterial'), value: 'material'},
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.typeService'), value: 'service'},
      ],
      measurements: [
        'kg',
        'g',
        'm',
        'm2',
        'm3',
        'pc',
        'rll'
      ],

      documents: [],
      newDocument: {
        create_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        client: 0,
        items: [],
        path: [],
      },
      loadedFiles: 0,

      items: [],
      newItem: {},
      deletedItems: [],
      add_item_form: false,
      editedItems: 0,
      editing_item: false,

      dateMenu: false,
      menu: {
        datePayment: false,
        dateDelivery: false,
      },

      alertMsg: "",
      successMsg: "",
      alertError: false,
      width: screen.width,
      loader: false,
    }
  },

  computed: {
    ...mapGetters(['permissions']),

    totalWithoutTax: function () {
      let result = 0
      this.items.forEach(item => {
        result += (item.price * (1 - item.discount / 100)) * item.quantity
      })
      return result.toFixed(1)
    },
    tax: function () {
      let result = 0
      this.items.forEach(item => {
        result += item.price * item.quantity * (item.tax / 100)
      })
      return result.toFixed(1)
    },
    total: function () {
      return (Number(this.totalWithoutTax) + Number(this.tax)).toFixed(1)
    },
  },

  watch: {
    loadedFiles: function (val) {
      if (val == this.documents.length) {
        this.addDocument()
      }
    },
    editedItems: function (val) {
      if (val == (this.items.length + this.deletedItems.length) && this.id != undefined) {
        this.loader = false
        this.loadData()
        this.editedItems = 0
        this.$router.push({name: 'documents', params: {mode: this.mode}})
      }
    }
  },

  created() {
    switch (this.mode) {
      case 'sales':
        this.url = "time-tracking/cheque/sales"
        break
      case 'offers':
        this.url = "time-tracking/offer"
        this.newDocument.from_client = {}
        break
    }
    this.loadClients()
    this.loadTerms()
    this.loadTaxes()
    if (this.id) {
      this.loadData()
    }
  },

  methods: {
    allowedDates: val => val <= new Date().toISOString(),

    loadData() {
      this.$axios({
        url: this.$hostname + this.url + "/" + this.id,
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.newDocument = response.data.data.data
        this.items = this.newDocument.items
        if (this.mode == 'offers' && this.newDocument.client.id)
          this.loadObjects()
        if (this.newDocument.from_client == null)
          this.newDocument.from_client = {}
        if (this.newDocument.path == null)
          this.newDocument.path = []
        else
          this.newDocument.path = JSON.parse(this.newDocument.path)
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadClients() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/clients",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.clients = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadTerms() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/terms",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.terms = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadTaxes() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/taxes",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.taxes = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadObjects() {
      let client = this.newDocument.client.id ? this.newDocument.client.id : this.newDocument.client
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/objects/" + client,
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.objects = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    addDocument() {
      if (this.$refs.form.validate()) {
        this.alertError = false
        this.newDocument.items = JSON.stringify(this.items)
        if (this.newDocument.from_client)
          this.newDocument.from_client = JSON.stringify(this.newDocument.from_client)
        this.newDocument.path = JSON.stringify(this.newDocument.path)
        if (this.id) {
          this.editDocument()
        } else {
          this.$axios({
            url: this.$hostname + this.url,
            method: "POST",
            headers: {
              "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
              "Content-Type": "multipart/form-data",
            },
            data: this.newDocument,
          }).then(() => {
            this.successMsg = "Сохранено"
            this.$router.push({name: 'documents', params: {mode: this.mode}})
          }).catch((errors) => {
            this.alertMsg = this.$catch(errors)
            this.alertError = true
            this.newDocument.path = JSON.parse(this.newDocument.path)
            this.newDocument.items = JSON.parse(this.items)
            if (this.newDocument.from_client)
              this.newDocument.from_client = JSON.parse(this.newDocument.from_client)
          })
        }
      } else {
        this.alertMsg = this.$vuetify.lang.t("$vuetify.fillForm")
        this.alertError = true
      }
    },
    editDocument() {
      this.loader = true
      this.newDocument.client = this.newDocument.client.id ? this.newDocument.client.id : this.newDocument.client
      if (this.newDocument.object)
        this.newDocument.object = this.newDocument.object.id
      this.$axios({
        url: this.$hostname + this.url,
        method: "PUT",
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: this.newDocument,
      }).then(() => {
        this.editItems()
        this.loadData()
        this.successMsg = 'Сохранено'
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        this.alertError = true
        this.newDocument.path = JSON.parse(this.newDocument.path)
        this.newDocument.items = JSON.parse(this.items)
        if (this.newDocument.from_client)
          this.newDocument.from_client = JSON.parse(this.newDocument.from_client)
      }).then(() => {
        this.loader = false
      })
    },
    deleteDocument() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "DELETE",
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: {
          "id": this.newDocument.id
        },
      }).then(() => {
        this.$router.push({name: 'documents', params: {mode: this.mode}})
      }).catch(error => {
        this.alertMsg = this.$catch(error)
        this.alertError = true
      })
    },

    openAddItem() {
      this.newItem = {name: '', price: '', tax: 0, discount: 0, quantity: '', measurement: ''}
      this.add_item_form = true
      this.editing_item = false
    },
    openEditItem(item) {
      this.newItem = item;
      this.editing_item = true
      this.add_item_form = true;
    },
    addItem() {
      if (this.$refs.addItem.validate()) {
        if (!this.newItem.id && !this.editing_item)
          this.items.push(this.newItem)
        this.add_item_form = false
      }
    },
    editItems() {
      if ((this.items.length + this.deletedItems.length) == 0) {
        this.loadData()
      }
      this.items.forEach(item => {
        if (item.id !== undefined) {
          this.$axios({
            url: this.$hostname + "time-tracking/item",
            method: "PUT",
            headers: {
              "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
              "Content-Type": "multipart/form-data",
            },
            data: item,
          }).then(() => {
            this.editedItems++
          }).catch((response) => {
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      })
      this.deletedItems.forEach(item => {
        this.$axios({
          url: this.$hostname + "time-tracking/item",
          method: "DELETE",
          headers: {
            "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
            "Content-Type": "multipart/form-data",
          },
          data: {
            sale_id: this.mode == "sales" ? this.id : null,
            offer_id: this.mode == "offers" ? this.id : null,
            id: item.id
          },
        }).then(() => {
          this.editedItems++
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true
        })
      })
    },
    deleteItem(item) {
      if (item.id !== undefined) {
        this.deletedItems.push(item)
      }
      this.items.splice(item, 1)
    },

    uploadFile() {
      if (this.$refs.form.validate()) {
        this.loader = true
        if (this.documents.length == 0)
          this.addDocument()
        else
          this.documents.forEach(doc => {
            this.$axios({
              url: this.$hostname + "time-tracking/images/upload/" + (this.mode == 'sales' ? "cheque" : "accounting"),
              method: "POST",
              headers: {
                Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                    sessionStorage.getItem("auth_token")),
                "Content-Type": "multipart/form-data",
              },
              data: {
                file: doc,
              },
            })
              .then((response) => {
                this.newDocument.path.push(response.data.data)
                this.loadedFiles++
              })
              .catch((error) => {
                alert("Ошибка загрузки файла: " + error.message);
              });

          })
      } else {
        this.alertMsg = "Заполните обязательные поля"
        this.alertError = true
      }
    },
    tmp_delete() {
      if (this.id == undefined) {
        this.$router.push({name: 'documents', params: {mode: this.mode}})
      } else {
        this.deleteDocument()
      }
    },
  },


}
</script>