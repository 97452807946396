<template>
  <v-dialog v-model="makeOfferToWorkerModal">
    <div class="modal modal-big modal-offer">
      <h2>
          <span v-if="cv_id">
            {{ $vuetify.lang.t('$vuetify.hh.respond') }}
          </span>
        <span v-else>
            {{ $vuetify.lang.t('$vuetify.hh.offer') }}
          </span>
      </h2>


      <v-select v-model="offer.vacancy" class="custom"
                :label="$vuetify.lang.t('$vuetify.menu.Vacancies')"
                :items="vacancies" item-text="name" item-value="id" outlined attach
                :menu-props="{
              bottom: true,
              offsetY: true,
              maxHeight: 200,
              contentClass: 'select-menu',
            }">
        <template v-slot:selection="{item }">
          {{ item.name }}
        </template>
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
      </v-select>
      <button class="btn btn-primary" @click="makeOfferToWorker">
        {{ $vuetify.lang.t('$vuetify.save') }}
      </button>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "MakeOfferToWorkerModal",

  props: {
    cv_id: [Number, null],
    auth_user_id: Number,
    value: {
      type: Boolean,
      required: true
    },
  },

  data: function () {
    return {
      makeOfferToWorkerModal: this.value,
      offer: {},

      vacancies: [],
    }
  },

  methods: {
    loadVacancies() {
      this.$axios({
        url: this.$hostname + "time-tracking/my-vacancies",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.vacancies = response.data.data.data
        this.vacancies.unshift({name: this.$vuetify.lang.t('$vuetify.hh.noVacancy'), id: null})
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },

    makeOfferToWorker() {
      this.$axios({
        url: this.$hostname + "time-tracking/make-offer-to-worker",
        method: "POST",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          auth_user: this.auth_user_id,
          resume: this.cv_id,
          vacancy: this.offer.vacancy,
        },
        // eslint-disable-next-line no-unused-vars
      }).then((response) => {
        this.vacancyFormModal = false
        this.$router.push('/organization-responds')
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
      }).finally(() => {
        this.form_loader = false
      });
    }
  },

  mounted() {
    this.loadVacancies()
  },

  watch: {
    value(){
      this.makeOfferToWorkerModal = this.value
    },
    makeOfferToWorkerModal(){
      this.$emit("input", this.makeOfferToWorkerModal);
    },
  }
}
</script>

<style scoped>

</style>