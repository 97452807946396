<template>
  <div class="container">
    <div class="page">
      <h1>Privacy Policy</h1>
      <p>Work24.fi</p>

      <p><strong>Last Updated:</strong> 17.06.2024</p>
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Work24.fi (hereinafter referred to as "we", "us", or "our"). We are committed to protecting your
          privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices
          regarding the collection, use, and disclosure of your information when you use our website and services.</p>
      </section>
      <section>
        <h2>2. Information We Collect</h2>
        <p>We may collect and process the following types of information:</p>
        <h3>2.1 Personal Information</h3>
        <ul>
          <li><strong>Contact Information:</strong> Name, email address, phone number, and address.</li>
          <li><strong>Professional Information:</strong> Employment history, qualifications, and other job-related
            information.
          </li>
        </ul>
        <h3>2.2 Non-Personal Information</h3>
        <ul>
          <li><strong>Usage Data:</strong> Information on how you use our website, including the pages you visit, the
            time spent on those pages, and other diagnostic data.
          </li>
          <li><strong>Technical Data:</strong> IP address, browser type and version, time zone setting, browser plug-in
            types and versions, operating system and platform, and other technology on the devices you use to access
            this website.
          </li>
        </ul>
      </section>
      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect for various purposes, including:</p>
        <ul>
          <li>To provide and maintain our services</li>
          <li>To notify you about changes to our services</li>
          <li>To allow you to participate in interactive features of our website when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our services</li>
          <li>To monitor the usage of our services</li>
          <li>To detect, prevent, and address technical issues</li>
        </ul>
      </section>
      <section>
        <h2>4. Disclosure of Your Information</h2>
        <p>We may share your personal information in the following situations:</p>
        <ul>
          <li><strong>With Service Providers:</strong> We may share your personal information with service providers to
            monitor and analyze the use of our services.
          </li>
          <li><strong>For Business Transfers:</strong> We may share or transfer your personal information in connection
            with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
            portion of our business to another company.
          </li>
          <li><strong>With Business Partners:</strong> We may share your information with our business partners to offer
            you certain products, services, or promotions.
          </li>
          <li><strong>With Your Consent:</strong> We may disclose your personal information for any other purpose with
            your consent.
          </li>
        </ul>
      </section>
      <section>
        <h2>5. Security of Your Information</h2>
        <p>The security of your personal information is important to us, but remember that no method of transmission
          over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyLayout"
}
</script>

<style scoped>

</style>