<template>

  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div
          class="dgantt__list-item"
          @click.stop="openTaskEmit(task)"
      >
        <div class="dgantt__list-task"
             v-bind="attrs"
             v-on="on">
          <button v-if="getSubTasks(task.id).length" class="dgantt__toggle-hide" @click.stop="toggleHide">

            <svg class="rotate" v-if="isHidden" width="13" height="9" viewBox="0 0 13 9" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.9718 0.680664L6.49997 5.15254L2.02809 0.680664L0.555176 2.15358L6.49997 8.09837L12.4448 2.15358L10.9718 0.680664Z"
                  fill="currentcolor"></path>
            </svg>
            <svg v-if="!isHidden" width="13" height="9" viewBox="0 0 13 9" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.9718 0.680664L6.49997 5.15254L2.02809 0.680664L0.555176 2.15358L6.49997 8.09837L12.4448 2.15358L10.9718 0.680664Z"
                  fill="currentcolor"></path>
            </svg>
          </button>
          <div class="dgantt__list-task-name">
            {{ workType }}
          </div>
          <div class="dgantt__list-task-days">
            {{ getTaskLengthDays() }}
          </div>
        </div>
        <div
            @mouseover.stop=""
            @click.stop=""
             v-if="!isHidden &&  getSubTasks(task.id).length"
            class="dgantt__list dgantt__list--childs">
          <DGanttListTask v-for="taskItem in getSubTasks(task.id)" :key="taskItem.id" :task="taskItem"
                          :tasks="tasks"
                          @openTask="openTaskEmit($event)"

          ></DGanttListTask>
        </div>
      </div>
    </template>
    <span>{{ task.date_start }} — {{ task.date_end }}</span>
  </v-tooltip>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "DGanttListTask",

  props: ['tasks', 'task'],

  methods: {
    ...mapActions(['openTask', 'hideTask']),

    openTaskEmit(task) {
      this.$emit('openTask', task)
    },

    toggleHide() {
      if (this.isHidden) {
        this.openTask(this.task.id)
      } else {
        this.hideTask(this.task.id)
      }
    },
    getParentTask(task) {
      return this.tasks.find(t => t.id == task.parent_task)
    },

    getSubTasks(id = null) {
      return this.tasks.filter(t => t.parent_task == id)
    },

    info(t) {

      const minDate = new Date(t.parent_task ? this.getParentTask(t).date_start : this.minDate);
      return {
        minDate
      }
    },

    calculateLeftPosition(t) {
      const minDate = new Date(t.parent_task ? this.getParentTask(t).date_start : this.minDate);
      const currentDate = new Date(t.date_start);
      const differenceInDays = (currentDate - minDate) / (1000 * 60 * 60 * 24);
      return differenceInDays * 25; // предполагаем, что 1 день равен 25px
    },

    getTaskLength(task) {
      return 25 * (new Date(task.date_end) - new Date(task.date_start)) / (1000 * 60 * 60 * 24);
    },

    getTaskLengthDays() {
      const startDate = new Date(this.task.date_start);
      const endDate = new Date(this.task.date_end);
      let count = 0;

      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        // Проверка, не является ли текущий день субботой (6) или воскресеньем (0)
        if (date.getDay() !== 0 && date.getDay() !== 6) {
          count++;
        }
      }

      return count;
    }
  },

  computed: {
    ...mapGetters(["hiddenTasks", 'workTypes']),

    workType(){
      if (!this.workTypes.length) return ''

      return this.workTypes.find(el=> el.id == this.task.work_type).name[this.$vuetify.lang.current]

    },

    getWorkTypes(){
      return this.workTypes ?? []
    },

    isHidden() {
      return this.hiddenTasks.includes(this.task.id)
    },

    minDate() {
      return this.tasks.reduce((min, task) => {
        return task.date_start < min ? task.date_start : min;
      }, this.tasks[0].date_start);
    },

    maxDate() {
      return this.tasks.reduce((max, task) => {
        return task.date_end > max ? task.date_end : max;
      }, this.tasks[0].date_end);
    },
  }
}
</script>
