<template>
  <div>
    <div class="big-card">
      <h2></h2>
      <v-card-text>
        <v-form ref="form" @submit.prevent="">
          <div class="form__row">
            <v-autocomplete v-model="newOrder.object" :items="objects"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.object')"
                            item-text="name" item-value="id" outlined return-object
                            attach :rules="[$rules.required]" @change="newOrder.client=newOrder.object.client_id"
                            :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
            ></v-autocomplete>

            <v-text-field :value="newOrder.client ? newOrder.client.name :''"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.client')"
                          readonly :rules="[$rules.required]" outlined
            ></v-text-field>
          </div>
          <div class="form__row">
            <v-autocomplete v-model="newOrder.shop" :items="shops"
                            :label="$vuetify.lang.t('$vuetify.shop')"
                            item-text="name" item-value="id" outlined clearable
                            attach @change="newOrder.shop ? loadSellers() : newOrder.shop "
                            :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
            ></v-autocomplete>

            <v-autocomplete v-model="newOrder.seller" :items="sellers"
                            :label="$vuetify.lang.t('$vuetify.seller')" outlined
                            item-text="name" item-value="id" attach
                            :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
            >
              <template v-slot:selection="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
            </v-autocomplete>
          </div>
          <div class="btn-group">
            <button class="btn btn-secondary" @click="$router.go(-1)">{{
                $vuetify.lang.t('$vuetify.cancel')
              }}
            </button>
            <button class="btn btn-primary" @click="addOrder">
              {{ $vuetify.lang.t('$vuetify.save') }}
            </button>
          </div>

          <h3 class="mb-3">{{ $vuetify.lang.t('$vuetify.products') }}</h3>
          <v-autocomplete class="sorts__select show" v-model="filter" :items="categories" return-object
                          :item-text="$vuetify.lang.current" clearable
                          :label="$vuetify.lang.t('$vuetify.accounting.card.category')" outlined attach
                          :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }" hide-details>
          </v-autocomplete>

          <div class="cards my-3">
            <div class="cards__single flex-column justify-between" v-for="(product) in products" :key="product.id">
              <div>
                <a :href="product.link" target="_blank" class="link-not-like photo photo-human">
                  <img v-if="product.photo" :src="$media_url + product.photo" loading="lazy" alt="photo"/>
                </a>
                <div class="cards__main-info">
                  <h2>
                    <a class="link-not-like" :href="product.link" target="_blank">
                      {{
                        product.name[$vuetify.lang.current] ? product.name[$vuetify.lang.current] : product.name['eng']
                      }}
                    </a>
                  </h2>
                  <h3 class="bolder">
                    {{ product.price }} €
                  </h3>
                  <div class="main-info__description">
                    <div class="timing-pre__wrap cp" :class="{'truncate-text': product.id!==notruncate}"
                         @click="truncate(product.id)"
                         :style="'max-height: 100%; min-height: 60px; height:' + (product.id!==notruncate ? '60px' : (inHeight+'px'))">
                      <pre :id="'item'+product.id">{{ product.description }}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards__buttons">
                <button class="btn-icon btn-action"
                        @click="removeItem(product.id)">
                  -
                </button>
                <v-text-field v-model.number="newOrder.items[product.id]"
                              :value="newOrder.items[product.id]?newOrder.items[product.id]:0"></v-text-field>
                <v-text-field
                  :value=" product.measurement ? ($vuetify.lang.t('$vuetify.measurements.' + product.measurement) ? $vuetify.lang.t('$vuetify.measurements.' + product.measurement) : product.measurement) : ''"
                  disabled></v-text-field>
                <button class="btn-icon btn-action"
                        @click="addItem(product.id)">
                  +
                </button>
              </div>
            </div>
          </div>
          <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
          <div class="document-card__bottom">
            <div class="btn-group">
              <button class="btn btn-secondary" @click="$router.go(-1)">{{
                  $vuetify.lang.t('$vuetify.cancel')
                }}
              </button>
              <button class="btn btn-primary" @click="addOrder">
                {{ $vuetify.lang.t('$vuetify.save') }}
              </button>
            </div>
          </div>
        </v-form>
      </v-card-text>
    </div>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";

export default {
  name: "AddOrder",
  components: {SpinnerLoader},
  props: {
    id: {String, Number},
  },


  data: function () {
    return {
      objects: [],
      shops: [],
      sellers: [],
      categories: [],
      products: [],
      filter: null,

      newOrder: {
        id: 0,
        object: null,
        client: '',
        shop: null,
        seller: null,
        items: {},
      },

      notruncate: 0,
      inHeight: 0,
      action: 'add',
      loader: false,
      alertError: false,
      alertMsg: "",
      width: screen.width,
    };
  },

  watch: {
    'newOrder.shop': function () {
      this.loadSellers()
      this.loadData()
    },
    filter: function () {
      this.loadData()
    }
  },

  mounted() {
    this.loader = true
    this.loadData()
    if (this.id) {
      this.action = 'edit'
      this.loadOrder();
    }
  },

  methods: {
    loadOrder() {
      this.$axios({
        url: this.$hostname + "time-tracking/catalog/order/" + this.id,
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        this.newOrder = response.data.data.data

        this.newOrder.items = {};
        this.newOrder.products.forEach(orderProduct => {
          this.$set(this.newOrder.items, orderProduct.product, orderProduct.quantity)
        })
        this.newOrder = Object.assign({}, this.newOrder)
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },
    loadData() {
      this.loadObjects()
      this.$axios({
        url: this.$hostname + "time-tracking/catalog",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          arch: true,
          sort: "name",
          filter: this.filter ? this.filter.eng : '',
          shop: this.newOrder.shop,
        }
      }).then((response) => {
        this.products = response.data.data.products
        this.shops = response.data.data.shops
        this.categories = response.data.data.categories
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },
    loadObjects() {
      this.$axios({
        url: this.$hostname + "time-tracking/time-reports/objects",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        }
      }).then((response) => {
        this.objects = response.data.data.data
        if (this.id) {
          this.action = 'edit'
          this.loadOrder();
        } else {
          if (this.$route.query.object) {
            const objectId = Number(this.$route.query.object); // Convert query parameter to number
            const foundObject = this.objects.find(({id}) => id === objectId);
            if (foundObject) {
              this.newOrder.object = foundObject;
              this.newOrder.client=this.newOrder.object.client_id
            }
          }
        }
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadSellers() {
      this.$axios({
        url: this.$hostname + "time-tracking/catalog/seller",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          shop: this.newOrder.shop,
        }
      }).then((response) => {
        this.sellers = response.data.data.data
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      })
    },

    addOrder() {
      this.loader = true
      if (this.$refs.form.validate()) {
        if (this.newOrder.id) {
          this.editOrder()
        } else {
          let orderData = {
            object: this.newOrder.object.id,
            client: this.newOrder.client.id,
            seller: this.newOrder.seller,
            shop: this.newOrder.shop,
            products: JSON.stringify(Object.entries(this.newOrder.items).map(([productId, quantity]) => ({
              product: productId,
              quantity: quantity
            })))
          };
          this.$axios({
            url: this.$hostname + "time-tracking/catalog/order",
            method: "POST",
            headers: {
              Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: orderData,
          }).then(response => {
            this.$axios({
              url: this.$hostname + "time-tracking/objects/comments",
              method: "POST",
              headers: {
                Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                    sessionStorage.getItem("auth_token")),
                "Content-Type": "multipart/form-data",
              },
              data: {
                text: "Новый заказ",
                files: JSON.stringify([response.data.data.pdf]),
                object_comments_id: null,
                objects_id: orderData.object,
                order_id: response.data.data.order,
              },
            }).then(() => {
              this.$router.push({
                "name": 'objects-item',
                params: {"id": this.$route.query.object ? this.$route.query.object : this.newOrder.object.id}
              })
            }).catch((response) => {
              this.alertMsg = this.$catch(response);
              this.alertError = true;
            });
          })
            .catch(error => {
              this.alertMsg = this.$catch(error);
              this.alertError = true;
            }).finally(() => {
            this.loader = false
          })
        }
      } else {
        this.alertMsg = this.$vuetify.lang.t("$vuetify.fillForm")
        this.alertError = true
        this.loader = false
      }
    },
    editOrder() {
      let orderData = {
        id: this.newOrder.id,
        object: this.newOrder.object.id,
        client: this.newOrder.client.id,
        seller: this.newOrder.seller,
        shop: this.newOrder.shop,
        products: JSON.stringify(Object.entries(this.newOrder.items).map(([productId, quantity]) => ({
          product: productId,
          quantity: quantity
        })))
      };
      this.$axios({
        url: this.$hostname + "time-tracking/catalog/order/" + this.id,
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: orderData,
      }).then(() => {
        this.loadOrder()
      })
        .catch(error => {
          this.alertMsg = this.$catch(error);
          this.alertError = true;
        }).finally(() => {
        this.loader = false
      })
    },

    addItem(productId) {
      if (this.newOrder.items[productId]) {
        this.newOrder.items[productId]++;
      } else {
        this.$set(this.newOrder.items, productId, 1);
      }
    },
    removeItem(productId) {
      if (this.newOrder.items[productId]) {
        if (this.newOrder.items[productId] === 1) {
          this.$delete(this.newOrder.items, productId);
        } else {
          this.newOrder.items[productId]--;
        }
      }
    },

    truncate(id) {
      let text = document.getElementById('item' + id)
      this.notruncate = this.notruncate == id ? 0 : id
      this.inHeight = text.clientHeight
    },
  }

}
</script>

<style scoped>

</style>