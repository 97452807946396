<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.MyVacancies') }}</h1>

      <div class="page-head__buttons">
        <button class="btn-secondary btn" @click="openCreate">
          {{ $vuetify.lang.t('$vuetify.add') }}
        </button>
      </div>
    </div>

    <div class="page-head page-head--sorts">
      <div class="page__sorts">
        <v-text-field class="search-field" v-model="search" @keypress.enter="page=1; loadData()"
                      @keypress.tab="page=1; loadData()" enterkeyhint="search"
                      :placeholder="$vuetify.lang.t('$vuetify.sorts.search')" outlined
                      prepend-inner-icon="$search" hide-details>
        </v-text-field>
      </div>
    </div>

    <vacancy-list :vacancies="vacancies" v-on:openEdit="openEdit" v-on:openDelete="confirmDelete"></vacancy-list>


    <!--    <div class="vacancies-list">-->
    <!--      <div class="vacancy-item" v-for="vacancy in vacancies" :key="vacancy.id">-->
    <!--        <a :href="'/vacancies/'+vacancy.id" class="vacancy-item__name">{{ vacancy.name }}</a>-->
    <!--        <div class="vacancy-item__sub-line">-->
    <!--        <div class="vacancy-item__salary">{{ vacancy.salary }}</div>-->
    <!--        <div v-if="vacancy.remote" class="vacancy-item__remote">{{$vuetify.lang.t('$vuetify.hh.remote')}}</div>-->
    <!--        </div>-->
    <!--        <div class="vacancy-item__company">-->
    <!--          ООО компания-->
    <!--        </div>-->
    <!--        <div class="vacancy-item__address">-->
    <!--          {{vacancy.country}}, {{ vacancy.region }} {{ vacancy.city }} {{ vacancy.address}}-->
    <!--        </div>-->

    <!--        <div class="vacancy-item__buttons">-->
    <!--          <button class="btn btn-primary">{{$vuetify.lang.t('$vuetify.hh.respond')}}</button>-->
    <!--          <button class="btn btn-primary" @click="openEdit(vacancy)">{{$vuetify.lang.t('$vuetify.edit')}}</button>-->
    <!--          <button class="btn btn-secondary" @click="confirmDelete(vacancy)">{{$vuetify.lang.t('$vuetify.delete')}}</button>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->


    <v-dialog v-model="vacancyFormModal">
      <div class="modal modal-big modal-vacancy modal-cv">
        <div class="modal-cv__header">
          <div class="modal-cv__nav">
            <div class="modal-cv__nav-step"
                 :class="{'active' : step===1}"
                 @click="step=1"
            >
              <div class="modal-cv__nav-number">1</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : step===2}"
                 @click="step=2"
            >
              <div class="modal-cv__nav-number">2</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : step===3}"
                 @click="step=3"
            >
              <div class="modal-cv__nav-number">3</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : step===4}"
                 @click="step=4"
            >
              <div class="modal-cv__nav-number">4</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : step===5}"
                 @click="step=5"
            >
              <div class="modal-cv__nav-number">5</div>
              <div class="modal-cv__nav-title"></div>
            </div>
          </div>
          <button class="btn btn-primary" @click="saveVacancy">{{ $vuetify.lang.t('$vuetify.saveAndClose') }}</button>
        </div>
        <div class="modal-cv__steps">
          <v-form ref="vacancyForm" @submit.prevent="" v-if="step===1" key="1-step">
            <h2>{{ $vuetify.lang.t('$vuetify.hh.mainInfo') }}</h2>

            <v-text-field v-model="currentVacancy.name" :rules="[$rules.required]"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.name')" outlined></v-text-field>
            <v-text-field v-model="currentVacancy.position"
                          :label="$vuetify.lang.t('$vuetify.hh.position')" outlined></v-text-field>
            <v-select class="custom" v-model="currentVacancy.type"
                      :label="$vuetify.lang.t('$vuetify.hh.type')"
                      :items="selectType"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: 200,
                                      contentClass: 'select-menu',
                                  }" outlined hide-details attach>

              <template v-slot:selection="{item }">
                {{ $vuetify.lang.t("$vuetify.hh." + item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $vuetify.lang.t("$vuetify.hh." + item) }}
              </template>
            </v-select>

            <div class="switch-wrap mt-3">
              <span>{{ $vuetify.lang.t('$vuetify.hh.not_remote') }}</span>
              <v-switch
                v-model="currentVacancy.remote"
                :label="$vuetify.lang.t('$vuetify.hh.remote')"
              ></v-switch>
            </div>
            <div class="form__row">
              <v-checkbox
                v-model="currentVacancy.need_experience"
                :label="$vuetify.lang.t('$vuetify.hh.need_experience')"
              >
              </v-checkbox>
              <v-text-field v-if="currentVacancy.need_experience" v-model="currentVacancy.experience"
                            :disabled="!currentVacancy.need_experience"
                            :label="$vuetify.lang.t('$vuetify.hh.experience')" outlined></v-text-field>
            </div>
            <h2>{{ $vuetify.lang.t('$vuetify.hh.salary') }}</h2>
            <v-text-field v-model="currentVacancy.salary_type"
                          :label="$vuetify.lang.t('$vuetify.hh.salaryType')" outlined></v-text-field>
            <div class="form__row">
              <v-text-field v-model.number="currentVacancy.salary_min" type="number"
                            :rules="[$rules.nullPositiveNumbers]"
                            :label="$vuetify.lang.t('$vuetify.min')" outlined></v-text-field>
              <v-text-field v-model.number="currentVacancy.salary_max" type="number"
                            :rules="[$rules.nullPositiveNumbers, maxSalary]"
                            :label="$vuetify.lang.t('$vuetify.max')" outlined></v-text-field>
              <v-text-field v-model="currentVacancy.currency"
                            :label="$vuetify.lang.t('$vuetify.hh.currency')" outlined></v-text-field>
            </div>

            <div class="switch-wrap">
              <span>{{ $vuetify.lang.t('$vuetify.hh.netto') }}</span>
              <v-switch v-model="currentVacancy.netto"
                        :label="$vuetify.lang.t('$vuetify.hh.brutto')"
              ></v-switch>
            </div>

            <v-checkbox
              v-model="currentVacancy.active"
              :label="$vuetify.lang.t('$vuetify.hh.active')"
            >
            </v-checkbox>
            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="prevStep">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>
          <v-form ref="vacancyForm" @submit.prevent="" v-if="step===2" key="2-step">
            <h2>{{ $vuetify.lang.t('$vuetify.hh.description') }}</h2>

            <editor
              v-model="currentVacancy.description"
              api-key="660vq0feuhjyub7s7o01nh9an48e4eq55ucbneldw55cr22l"
              :init="editorInit"
            />

            <v-textarea v-model="currentVacancy.responsibilities"
                        :label="$vuetify.lang.t('$vuetify.hh.responsibilities')" outlined></v-textarea>
            <v-textarea v-model="currentVacancy.skills"
                        :label="$vuetify.lang.t('$vuetify.hh.skills')" outlined></v-textarea>

            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="prevStep">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>

          <v-form ref="vacancyForm" @submit.prevent="" v-if="step===3" key="3-step">
            <h2>{{ $vuetify.lang.t('$vuetify.hh.additionalInfo') }}</h2>
            <v-textarea v-model="currentVacancy.documents"
                        :label="$vuetify.lang.t('$vuetify.hh.documents')" outlined></v-textarea>
            <v-textarea v-model="currentVacancy.useful"
                        :label="$vuetify.lang.t('$vuetify.hh.useful')" outlined></v-textarea>
            <v-textarea v-model="currentVacancy.conditions"
                        :label="$vuetify.lang.t('$vuetify.hh.conditions')" outlined></v-textarea>

            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="prevStep">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>
          <v-form ref="vacancyForm" @submit.prevent="" v-if="step===4" key="4-step">
            <h2>{{ $vuetify.lang.t('$vuetify.hh.address') }}</h2>


            <!--            <v-text-field v-model="currentVacancy.country" :label="$vuetify.lang.t('$vuetify.hh.country')"-->
            <!--                          outlined></v-text-field>-->
            <!--            <v-text-field v-model="currentVacancy.region" :label="$vuetify.lang.t('$vuetify.hh.region')"-->
            <!--                          outlined></v-text-field>-->
            <!--            <v-text-field v-model="currentVacancy.city" :label="$vuetify.lang.t('$vuetify.hh.city')"-->
            <!--                          outlined></v-text-field>-->
            <address-map-component v-model="currentVacancy.address"
                                   :label="$vuetify.lang.t('$vuetify.hh.address')"
                                   v-on:latitude="setLatitude"
                                   v-on:longitude="setLongitude"></address-map-component>

            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="prevStep">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>

          <v-form ref="vacancyForm" @submit.prevent="" v-if="step===5" key="5-step">
            <h2>{{ $vuetify.lang.t('$vuetify.hh.contact_person') }}</h2>

            <div class="modal-cv__main-info">
              <v-text-field v-model="currentVacancy.contact_firstname" :label="$vuetify.lang.t('$vuetify.hh.firstname')"
                            outlined></v-text-field>
              <v-text-field v-model="currentVacancy.contact_lastname" :label="$vuetify.lang.t('$vuetify.hh.lastname')"
                            outlined></v-text-field>
              <v-text-field v-model="currentVacancy.contact_email" :label="$vuetify.lang.t('$vuetify.email')"
                            outlined :rules="[$rules.nullEmail]"></v-text-field>
              <v-text-field v-model="currentVacancy.contact_phone" :label="$vuetify.lang.t('$vuetify.phone')"
                            outlined></v-text-field>
            </div>
            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="prevStep">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>
        </div>
      </div>
    </v-dialog>


    <ConfirmModal v-model="confirmDeleteModal" @confirm="deleteVacancy">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>

  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal.vue";
import VacancyList from "@/components/VacancyList.vue";
import {mapActions} from "vuex";
import AddressMapComponent from "@/components/AddressMapComponent.vue";
import Editor from "@tinymce/tinymce-vue";
import config from "@/assets/config";

export default {
  name: "my-vacancies",
  components: {AddressMapComponent, ConfirmModal, VacancyList, editor: Editor},

  data: function () {
    return {
      loader: false,
      form_loader: false,
      alertMsg: "",
      alertError: false,

      search: '',
      vacancies: [],
      currentVacancy: {
        active: true,
      },
      step: 1,

      deleting_vacancy_id: 0,
      confirmDeleteModal: false,

      vacancyFormModal: false,
      vacancyFormMode: 'create',
      selectType: [
        'fulltime',
        'parttime',
        'temporary',
        'contract',
        'seasonal',
        'volunteer'
      ],

      maxSalary: (v) => Number(v) >= Number(this.currentVacancy.salary_min) || v === "" || v === 0 || v == null || "Need to be more then min",

      editorInit: {
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | link | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | image | removeformat | help",
        file_picker_types: "image",
        relative_urls: false,
        image_prepend_url: this.$hostname,
        images_upload_base_path: this.$hostname,
        image_description: false,
        // eslint-disable-next-line no-unused-vars
        file_picker_callback: function (callback, value, meta) {
          document.querySelector(".tox-lock.tox-button").click();
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function () {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = function () {
              var img = new Image;
              const axios = require("axios");
              // чтение файла в formData
              let fd = new FormData();
              if (file !== null) {
                fd.append("file", file);
              }
              axios({
                method: "post",
                url: config.url + "time-tracking/images/upload",
                headers: {
                  Authorization:
                    "Token " +
                    (localStorage.getItem("auth_token") ||
                      sessionStorage.getItem("auth_token")),
                },
                data: fd,
              }).then((response) => {
                document.querySelector(
                  ".tox-control-wrap .tox-textfield"
                ).value = response.data.data;
                img.src = reader.result
                img.onload = function () {
                  document.getElementsByClassName(
                    "tox-textfield"
                  )[1].value = img.width > 500 ? 500 : img.width;
                  document.getElementsByClassName(
                    "tox-textfield"
                  )[2].value = img.height > 500 ? 500 * (img.height / img.width) : img.height;
                }
              });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    }
  },

  mounted() {
    this.checkPermission('HR').then(() => {
      this.loadData()
    })
  },

  methods: {
    ...mapActions(['checkPermission']),
    openCreate() {
      this.currentVacancy = {
        active: true
      }
      this.step = 1
      this.vacancyFormModal = true
      this.vacancyFormMode = 'create';
    },
    openEdit(vacancy) {
      this.vacancyFormModal = true
      this.step = 1
      this.vacancyFormMode = 'edit';
      this.currentVacancy = vacancy
    },

    confirmDelete(vacancy) {
      this.deleting_vacancy_id = vacancy.id
      this.confirmDeleteModal = true
    },

    deleteVacancy() {
      this.$axios({
        url: this.$hostname + "time-tracking/my-vacancies",
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.deleting_vacancy_id
        },
      }).then(() => {
        this.loadData()
        this.confirmDeleteModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      });
    },

    prevStep() {
      this.step--
      if (this.step <= 0) {
        this.vacancyFormModal = false
      }
    },
    nextStep() {
      if (this.$refs.vacancyForm.validate()) {
        if (this.step >= 5) {
          this.saveVacancy()
        }
        this.step++
      }
    },


    saveVacancy() {
      this.form_loader = true;
      let data = this.currentVacancy
      this.$axios({
        url: this.$hostname + "time-tracking/my-vacancies",
        method: this.currentVacancy.id ? "PUT" : "POST",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: data,
        // eslint-disable-next-line no-unused-vars
      }).then((response) => {
        // this.currentVacancy.id = response.data.data.data
        this.loadData()
        this.vacancyFormModal = false

        // }
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
      }).finally(() => {
        this.form_loader = false
      });
    },

    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/my-vacancies",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          search: this.search,
          page: this.page,
          per: this.perPage
        }
      }).then((response) => {
        this.vacancies = response.data.data.data
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },
    clickInput() {
      document.getElementById("photo").click();
    },

    setLatitude(value) {
      this.currentVacancy.latitude = value
    },
    setLongitude(value) {
      this.currentVacancy.longitude = value
    },

  }
}
</script>