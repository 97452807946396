<template>

  <accordion-component :closed="closed" :updFlag="updFlag" @switch="updateData">
    <template v-slot:title>
      {{ itemKey }}
    </template>
    <template v-slot:content>
      <div class="lang__content" v-if="!t_closed">
        <ul class="lang__ul" v-if="!isObject(itemValue)">
          <li v-for="(transValue, transKey) in tr" :key="transKey">
            <div class="lang__li-title">
              {{ emoji[transKey] }} {{ transKey }}:
            </div>
            <input
              class="lang__input"
              type="text"
              v-model="tr[transKey]"
              @input="updateValue(transKey)"
            />
          </li>
        </ul>
        <div class="lang__list" v-else>
          <language-item
            v-for="(childValue, childKey) in itemValue"
            :key="`${itemPath.join('.')}.${childKey}`"
            :item-key="childKey"
            :item-value="childValue"
            :item-path="[...itemPath, childKey]"
            :translations="translations"
            :closed="closed"

            @update-language="handleLanguageUpdate"

          ></language-item>
        </div>
      </div>

    </template>

  </accordion-component>


</template>

<script>
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import AccordionComponent from "@/components/AccordionComponent.vue";

export default {
  name: "LanguageItem",
  // eslint-disable-next-line vue/no-unused-components
  components: {AccordionComponent, EditIcon, DeleteIcon},

  data: function () {
    return {
      emoji: {
        "az": "🇦🇿", // Azerbaijan
        "be": "🇧🇾", // Belarus
        "bg": "🇧🇬", // Bulgaria
        "cs": "🇨🇿", // Czech Republic (formerly Czechoslovakia)
        "da": "🇩🇰", // Denmark
        "de": "🇩🇪", // Germany
        "el": "🇬🇷", // Greece
        "eng": "🇬🇧", // United Kingdom
        "es": "🇪🇸", // Spain
        "est": "🇪🇪", // Estonia
        "fin": "🇫🇮", // Finland
        "fr": "🇫🇷", // France
        "he": "🇮🇱", // Israel
        "hy": "🇦🇲", // Armenia
        "it": "🇮🇹", // Italy
        "kk": "🇰🇿", // Kazakhstan
        "lt": "🇱🇹", // Lithuania
        "lv": "🇱🇻", // Latvia
        "no": "🇳🇴", // Norway
        "pl": "🇵🇱", // Poland
        "pt": "🇵🇹", // Portugal
        "ro": "🇷🇴", // Romania
        "rus": "🇷🇺", // Russia
        "sv": "🇸🇪", // Sweden
        "tg": "🇹🇯", // Tajikistan
        "tr": "🇹🇷", // Turkey
        "uk": "🇺🇦"  // Ukraine
      },
      tr: {},
      timeOuts: [],
      t_closed: true,

      updFlag: false,
    }
  },

  mounted() {
    // console.log(this.translations[this.itemPath[0]])
    // this.tr = this.itemPath.reduce((result, key) => result[key], this.translations)
    this.t_closed = this.closed
  },

  props: {
    itemKey: String,
    itemValue: [Object, String], // Может быть объектом или строкой
    itemPath: {
      type: Array,
      default: () => []
    },
    translations: Object, // Объект со всеми переводами
    closed: Boolean
  },
  methods: {
    isObject(value) {
      return typeof value === 'object' && value !== null;
    },

    updateData(closed) {
      console.log(closed)
      this.t_closed = closed
      if (!closed) {
        this.tr = this.itemPath.reduce((result, key) => result[key], this.translations)
        this.$nextTick(() => {
          this.updFlag = !this.updFlag

        })
      }
    },
    updateValue(lang) {
      clearTimeout(this.timeOuts[lang])
      this.timeOuts[lang] = setTimeout(() => {
        this.$emit('update-language', {
          path: this.itemPath,
          value: this.tr[lang],
          lang: lang
        });
      }, 500)

    },
    handleLanguageUpdate(data) {
      this.$emit('update-language', data);
    },

  },

  watch: {
    closed() {
      this.t_closed = this.closed

    }
    // translations: {
    //   deep: true,
    //   handler(){
    //     this.tr = this.itemPath.reduce((result, key) => result[key], this.translations)
    //     this.t_closed = this.closed
    //   }
    // }
  }
}
</script>
