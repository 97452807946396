<template>
  <div class="page-wrap">
    <div class="page-head">
      <div class="breadcrumbs">
        <div class="h1">
          <router-link class="link-not-like" to="/settings">{{ $vuetify.lang.t('$vuetify.menu.settings') }}
          </router-link>
        </div>
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.settings.holidays') }}
        </div>
      </div>
    </div>

    <div class="">
      <h2 class="h2">
        {{ $vuetify.lang.t("$vuetify.workingDays") }}
      </h2>

      <div class="flex">
        <v-checkbox v-model="work_days.Mon"
                    :label="$vuetify.lang.t('$vuetify.days.Mon')"></v-checkbox>
        <v-checkbox v-model="work_days.Tue"
                    :label="$vuetify.lang.t('$vuetify.days.Tue')"></v-checkbox>
        <v-checkbox v-model="work_days.Wed"
                    :label="$vuetify.lang.t('$vuetify.days.Wed')"></v-checkbox>
        <v-checkbox v-model="work_days.Thu"
                    :label="$vuetify.lang.t('$vuetify.days.Thu')"></v-checkbox>
        <v-checkbox v-model="work_days.Fri"
                    :label="$vuetify.lang.t('$vuetify.days.Fri')"></v-checkbox>
        <v-checkbox v-model="work_days.Sat"
                    :label="$vuetify.lang.t('$vuetify.days.Sat')"></v-checkbox>
        <v-checkbox v-model="work_days.Sun"
                    :label="$vuetify.lang.t('$vuetify.days.Sun')"></v-checkbox>
      </div>

      <h2 class="h2">
        {{ $vuetify.lang.t('$vuetify.settings.holidays') }}

      </h2>
      <div class="flex holidays-flex">
        <div class="">
          <div class="bold">{{ $vuetify.lang.t("$vuetify.chosenHolidays") }}:</div>
          <ul>
          <li class="holidays-li" v-for="date in holidays" :key="date">
            {{ new Date(date).toLocaleDateString('ru-Ru') }}
            <div class="btn-icon btn-action" @click="deleteHoliday(date)">
              <svg height="12px" width="12px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>
            </div>
          </li>
          </ul>
        </div>
        <div class="">
        <v-date-picker v-model="holidays"
                       no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                       show-adjacent-months
                       multiple
        >
        </v-date-picker>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "HolidaySettingsView",

  data: function () {
    return {
      'work_days': {
        "Mon": false,
        "Tue": false,
        "Wed": false,
        "Thu": false,
        "Fri": false,
        "Sat": false,
        "Sun": false,
      },
        'holidays': [],
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    deleteHoliday(holiday){
      this.holidays = this.holidays.filter(el=> el!==holiday)
    },

    loadData(){
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings?key=holidays',
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then(response => {
        const plainObject = JSON.parse(JSON.stringify(response.data.data.data));
        if (Object.keys(plainObject).length !== 0) {
          this.holidays = response.data.data.data
          }
      })
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings?key=work_days',
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then(response => {
        this.work_days = response.data.data.data
      })
    },

    saveWorkDays() {
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings',
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          key: 'work_days',
          value: JSON.stringify(this.work_days),
        }
      })
    },
    saveHolidays(){
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings',
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",

        },
        data: {
          key: 'holidays',
          value: JSON.stringify(this.holidays)
        }
      })
    },
  },

  watch: {
    work_days: {
      handler()
      {
        this.saveWorkDays()
      },
      deep: true
    },

    holidays(){
      this.saveHolidays()
    }
  }
}
</script>
