<template>
  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true"
       viewBox="0 0 24 24" data-testid="FileDownloadOutlinedIcon">
    <path
        d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z"></path>
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon"
}
</script>

<style scoped>

</style>