<template>
  <div class="news-card cards__single">
    <router-link :to="'/news/'+id">
      <img class="news-card__img" :src="image" alt="">
    </router-link>
    <h2 class="news-card__title">
      <router-link class="link-not-like" :to="'/news/'+id">
        {{ title }}
      </router-link>
    </h2>
    <div class="news-card__desc">
      <div class="news-card__date">

        {{ new Date(date).toLocaleDateString({day: 'numeric', month: 'numeric', year: 'numeric'}) }}
      </div>

      <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="1" cy="1" r="1" fill="#ADB2B7"/>
      </svg>
      <div class="news-card__author">
        {{ author }}
      </div>
    </div>

    <div v-if="isCanEdit" class="news-item__buttons btn-action__group">
      <router-link class="btn-icon btn-action" :to="'/newsEdit/' + id">
        <picture>
          <edit-icon/>
        </picture>
      </router-link>
      <button class="btn-icon btn-action" @click="$emit('onDelete', id)">
        <picture>
          <delete-icon/>
        </picture>
      </button>
      <button class="btn-icon btn-action" @click="$emit('onArchive', id)">
        <picture>
          <archive-icon/>
        </picture>
      </button>
    </div>
  </div>
</template>

<script>
import ArchiveIcon from "@/components/icons/ArchiveIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

export default {
  name: 'news-card',
  components: {DeleteIcon, EditIcon, ArchiveIcon},
  emits: ['onDelete', 'onArchive'],
  props: {
    title: String,
    image: String,
    date: String,
    author: String,
    id: Number,
    isCanEdit: {
      type: Boolean,
      defaultValue: false
    }
  },
  date: function () {
    return {
      options_date: {day: 'numeric', month: 'numeric', year: 'numeric'},
    }
  }
}
</script>