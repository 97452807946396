<template>
  <v-select class="sorts__select" v-model="selectedSection"
            :class="{ show: true}"
            :label="$vuetify.lang.t('$vuetify.selectedSection')"
            :items="sections" item-text="name" item-value="value"
            :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: 200,
                                      contentClass: 'select-menu select-menu--sort',
                                  }" outlined hide-details attach>
    <template v-slot:selection="{item }">
      {{ $vuetify.lang.t("$vuetify.menu." + item) }}
    </template>
    <template v-slot:item="{ item }">
      {{ $vuetify.lang.t("$vuetify.menu." + item) }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: "BookkeepingSelect",

  props: ['mode'],

  data: function () {
    return {
      selectedSection: this.mode,
      sections: [
        'documents-absence',
        'documents-timings',
        'timingChangesList',
        'reports',
        'extracts',
        'contracts',
        'documents',
        'property',
        'purchases',
        'sales',
        'offers',
        'waybills',
        'garage',
      ],
    }
  },

  watch: {
    selectedSection: function(val){
      switch (val) {
        case 'documents-absence':
          this.$router.push({
            name: 'documents-absence'
          })
          break;
        case 'documents-timings':
          this.$router.push({
            name: 'documents-timings'
          })
          break;
        case 'timingChangesList':
          this.$router.push({
            name: 'timingChangesList'
          })
          break;
        default:
          this.$router.push({
            name: 'documents', params: {
              mode: val
            }
          })
          break;
      }


    },
  }
}
</script>