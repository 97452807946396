<template>
  <div class="page-wrap">
    <div class="page-head">
      <div class="breadcrumbs">
        <div class="h1" v-if="permissions.read.indexOf('Бухгалтерия') !== -1">
          <router-link class="link-not-like" to="/bookkeeping">
            {{$vuetify.lang.t('$vuetify.menu.accounting')}}
          </router-link>
        </div>
        <svg v-if="permissions.read.indexOf('Бухгалтерия') !== -1" width="9" height="15" viewBox="0 0 9 15" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.accounting.' + mode + '.label') }}
        </div>
      </div>
    </div>
    <add-document-big v-if="mode === 'sales' || mode === 'offers'" :mode="mode" :id="id"></add-document-big>
    <add-document v-else :mode="mode" :id="id"></add-document>
  </div>
</template>

<script>
import AddDocumentBig from "@/views/AddDocumentBig";
import AddDocument from "@/views/AddDocument";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "addBookkeeping",
  components: {AddDocument, AddDocumentBig},
  props: {
    mode: [String],
    id: [String, Number]
  },

  created() {
    if (!this.mode === 'waybills')
      this.checkPermission('Бухгалтерия')
  },

  methods: {
    ...mapActions(['checkPermission'])
  },

  computed: {
    ...mapGetters(['permissions'])
  }

}
</script>

<style scoped>

</style>