var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"sorts__select",class:{ show: true},attrs:{"label":_vm.$vuetify.lang.t('$vuetify.selectedSection'),"items":_vm.sections,"item-text":"name","item-value":"value","menu-props":{
                                    bottom: true,
                                    offsetY: true,
                                    maxHeight: 200,
                                    contentClass: 'select-menu select-menu--sort',
                                },"outlined":"","hide-details":"","attach":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.menu." + item))+" ")]}},{key:"item",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.menu." + item))+" ")]}}]),model:{value:(_vm.selectedSection),callback:function ($$v) {_vm.selectedSection=$$v},expression:"selectedSection"}})}
var staticRenderFns = []

export { render, staticRenderFns }