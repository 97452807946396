<template>
  <tr class="man-wrap" :class="{ rows__single: type === 'rows' }">
    <div class="cards__single" v-if="type === 'cards'">
      <span class="unread-dot" v-if="unread"></span>
      <router-link
        :to="'/objects/' + id"
        class="link-not-like photo photo-human"
      >
        <img v-if="photo" :src="$media_url + photo" loading="lazy" alt="photo"/>
      </router-link>
      <div class="cards__main-info">
        <h2>
          <router-link class="link-not-like" :to="'/objects/' + id">{{
              options.array[0].value
            }}
          </router-link>
        </h2>
        <span class="main-info__description">
         {{ options.array[1].value }}
        </span>
      </div>
      <div class="cards__buttons">
        <button v-if="permissions.edit.indexOf('Объекты') !== -1" class="btn-icon btn-action"
                @click="$emit('edit', id)">
          <edit-icon/>
        </button>
        <button v-if="permissions.edit.indexOf('Объекты') !== -1" class="btn-icon btn-action"
                @click="confirm_archive_modal=true">
          <archive-icon/>
        </button>
        <button v-if="permissions.edit.indexOf('Объекты') !== -1" class="btn-icon btn-action"
                @click="confirm_open(id)">
          <delete-icon/>
        </button>
      </div>
      <ul class="cards__info">
        <template v-for="(option, idx) in options.array.slice(2, 7)">
          <li v-if="option.name === 'client' && permissions.admin" :key="idx">
            <span v-html="option.icon"></span>
            <router-link :to="'/clients/'+client" v-if="client">{{ option.value }}</router-link>
          </li>
          <li v-else-if="option.name === 'address'" :key="idx">
            <span v-html="option.icon"></span>
            <a target="_blank"
               :href="'https://www.google.com/maps/search/?api=1&query='+address">
              {{ option.value }}
            </a>
          </li>
          <li v-else :key="idx">
            <span v-html="option.icon"></span>
            <span>{{ option.value }}</span>
          </li>
        </template>
      </ul>
    </div>
    <template v-if="type === 'rows'">
      <template v-for="(header) in headers">
        <template v-if="$parent.showHeaders[header.key]">
          <td v-if="header.key === 'name'" :key="header.key">
            <router-link :to="'/objects/' + id" class="link-not-like flex"
            >
              <span class="unread-dot" v-if="unread"></span>
              <div class="avatar">
                <img v-if="photo" :src="$media_url + photo" loading="lazy" alt=""/>
              </div>
              {{ options[header.key] }}
            </router-link>
          </td>
          <td v-else-if="header.key === 'client_id__name' && permissions.admin" :key="header.key">
            <router-link :to="'/clients/'+client" v-if="client">{{ options[header.key] }}</router-link>
          </td>
          <td v-else-if="header.key === 'address'" :key="header.key">
            <a target="_blank"
               :href="'https://www.google.com/maps/search/?api=1&query='+address">
              {{ options[header.key] }}
            </a>
          </td>
          <td v-else :key="header.key" v-html="options[header.key] "></td>
        </template>
      </template>
      <td class="rows__dot-menu" v-if="permissions.edit.indexOf('Объекты') !== -1">
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <svg
                v-bind="attrs"
                v-on="on"
                width="20"
                height="5"
                viewBox="0 0 20 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="10" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="18" cy="2.5" r="2" fill="#2C1521"/>
              </svg>
            </v-btn>
          </template>
          <div class="rows__dot-menu-content">
            <div class="btn-wrap" @click="$emit('copy', id)">
              {{ $vuetify.lang.t('$vuetify.copy') }}
              <button class="btn-icon btn-action">
                <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyOutlinedIcon">
                  <path
                    d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
                </svg>
              </button>
            </div>
            <div class="btn-wrap" @click="$emit('edit', id)">
              {{ $vuetify.lang.t('$vuetify.edit') }}
              <button class="btn-icon btn-action">
                <edit-icon/>
              </button>
            </div>
            <div class="btn-wrap"
                 @click="confirm_archive_modal=true">
              <span v-if="!active">{{ $vuetify.lang.t('$vuetify.toArchive') }}</span>
              <span v-else>{{ $vuetify.lang.t('$vuetify.deleteArchive') }}</span>
              <button class="btn-icon btn-action">
                <archive-icon/>
              </button>
            </div>
            <div class="btn-wrap" @click="confirm_open(id)">
              {{ $vuetify.lang.t('$vuetify.delete') }}
              <button class="btn-icon btn-action">
                <delete-icon/>
              </button>
            </div>
          </div>
        </v-menu>
      </td>
    </template>
    <ConfirmModal v-model="confirm_modal" @confirm="tmp_delete">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
    <ConfirmArchiveModal v-model="confirm_archive_modal" @confirm="tmp_archive" :active="!active">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>
  </tr>
</template>
<script>
import ConfirmModal from "@/components/confirmModal.vue";
import EditIcon from "@/components/icons/EditIcon";
import ArchiveIcon from "@/components/icons/ArchiveIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal";
import {mapGetters} from "vuex";

export default {
  name: "object-card",

  components: {
    ConfirmArchiveModal,
    DeleteIcon,
    ArchiveIcon,
    EditIcon,
    ConfirmModal,
  },

  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    active: Boolean,
    checked: Boolean,
    type: String,
    options: Object,
    headers: Array,
    photo: String,
    id: Number,
    client: [Number, undefined],
    unread: Number,
    address: String,
  },

  data: function () {
    return {
      the_checked: this.checked,
      tmp: false,
      confirm_modal: false,
      confirm_archive_modal: false,
      tmp_to_delete: "",
      alertMsg: "",
      alertError: false,
    };
  },

  methods: {
    confirm_open(id) {
      this.confirm_modal = true;
      this.tmp_to_delete = id;
    },

    confirm_close() {
      this.confirm_modal = false;
      this.tmp_to_delete = "";
    },

    tmp_delete() {
      this.$axios
        .delete(this.$hostname + "time-tracking/objects", {
          data: {
            id: this.id,
          },
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.alertMsg = "Объект удален";
          this.confirm_modal = false;
          this.$destroy();

          this.$el.parentNode.removeChild(this.$el);
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },
    tmp_archive() {
      this.$axios({
        url: this.$hostname + "time-tracking/objects",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.id,
          active: !this.active,
        },
      }).then(() => {
        this.confirm_archive_modal = false
        this.$emit('archive')
      }).catch((response) => {
        this.loader = false
        this.alertMsg = this.$catch(response);
        this.alertError = true;
      });
    },
  },
  computed: {
    ...mapGetters(['permissions'])
  }
};
</script>
