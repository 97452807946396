<template>
  <v-dialog v-model="confirm_modal">
    <div class="modal modal-small">
      <div class="h1">{{ $vuetify.lang.t('$vuetify.sure') }}</div>
      <div class="error--text">
        <slot></slot>
      </div>
      <div class="sign-up__buttons btn-group">
        <button class="btn btn-secondary text-capitalize" @click="confirm_modal = false">
          {{ $vuetify.lang.t('$vuetify.no') }}
        </button>
        <button class="btn btn-primary text-capitalize" @click="$emit('confirm', true)">
          {{ $vuetify.lang.t('$vuetify.yes') }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm-modal",
  props: {
    value: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      confirm_modal: this.value,
    };
  },

  watch: {
    value() {
      this.confirm_modal = this.value;
    },
    confirm_modal() {
      this.$emit("input", this.confirm_modal);
    },
  },
};
</script>
