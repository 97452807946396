<template>
  <div class="page-wrapper">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.tariffPlans') }}</h1>
      <div class="page-head__buttons">
        <button class="btn-secondary btn" @click="arch = !arch; page=1">
          <span v-if="!arch">{{ $vuetify.lang.t('$vuetify.archive') }}</span>
          <span v-else>{{ $vuetify.lang.t('$vuetify.outArchive') }}</span>
        </button>
        <button class="btn-secondary btn" @click="action='add'; planCreateOpen();">
          + {{ $vuetify.lang.t('$vuetify.add') }}
        </button>
      </div>
    </div>

    <div class="cards">
      <div class="cards__single" v-for="plan in tariffPlans" :key="plan.id">
        <div class="cards__main-info">
          <h2 class="tariff-plan__title"> {{
              plan.name[$vuetify.lang.current] ? plan.name[$vuetify.lang.current] : plan.name['eng'] ? plan.name['eng'] : plan.name['fin']
            }} </h2>
          <div class="tariff-plan__price">{{ plan.price }}€</div>
        </div>
        <div class="tariff-plan__description" v-html="plan.description"></div>
        <div class="">
          <span class="bolder">{{ $vuetify.lang.t("$vuetify.workerLimit") }}: </span>
          <span>{{ plan.worker_limit }}</span>
        </div>
        <div class="bolder">{{ $vuetify.lang.t("$vuetify.functions") }}:</div>
        <ul class="tariff-plan__functions">
          <template v-for="func in functions">
            <li v-if="plan.features.indexOf(func.id) !== -1" :key="func.id">
              {{ $vuetify.lang.t("$vuetify.menu." + func.name.toLowerCase() + "") }}
            </li>
          </template>
        </ul>
        <div class="cards__buttons">
          <button class="btn-icon btn-action"
                  @click="newPlan = plan; planCreateModal=true">
            <picture>
              <edit-icon/>
            </picture>
          </button>
          <button class="btn-icon btn-action"
                  @click="newPlan=plan;archiveConfirmModal=true">
            <picture>
              <archive-icon/>
            </picture>
          </button>
          <button v-if="!plan.active" class="btn-icon btn-action"
                  @click="deleted = plan.id; deleteConfirmModal=true">
            <picture>
              <delete-icon/>
            </picture>
          </button>
        </div>
      </div>
    </div>

    <v-dialog v-model="planCreateModal">
      <div class="modal">
        <div class="h1">
          <span v-if="action == 'add'">{{ $vuetify.lang.t('$vuetify.add') }}</span><span
          v-if="action == 'edit'">{{ $vuetify.lang.t('$vuetify.edit') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <template v-for="lang in Object.keys($vuetify.lang.locales)">
            <v-text-field v-if="['eng', 'rus'].includes(lang)" :key=lang
                          v-model="newPlan.name[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                          :rules="[$rules.required]"></v-text-field>
            <v-text-field v-else-if="!['en', 'ru'].includes(lang)" :key=lang v-model="newPlan.name[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined></v-text-field>
          </template>
          <h3>{{ $vuetify.lang.t("$vuetify.description") }}</h3>
          <editor v-model="newPlan.description"
                  api-key="660vq0feuhjyub7s7o01nh9an48e4eq55ucbneldw55cr22l"
                  :init="$editorInit" :id="'editor'"
                  @change="$saveForm(action+'newPlan', newPlan)"/>
          <v-text-field v-model.number="newPlan.price"
                        :label="$vuetify.lang.t('$vuetify.accounting.card.price')" outlined
                        :rules="[$rules.required, $rules.positiveNumbers]"></v-text-field>
          <v-text-field v-model.number="newPlan.price_id"
                        :label="$vuetify.lang.t('$vuetify.stripe')" outlined
                        :rules="[$rules.required]"></v-text-field>
          <v-text-field v-model.number="newPlan.worker_limit"
                        :label="$vuetify.lang.t('$vuetify.workerLimit')" outlined
                        :rules="[$rules.required, $rules.positiveNumbers]"></v-text-field>
          <h3>{{ $vuetify.lang.t("$vuetify.functions") }}</h3>
          <div class="tariff-plan__functions">
            <div v-for="func in functions" :key="func.id">
              <v-checkbox :value="func.id" v-model="newPlan.features" :label="func.text"></v-checkbox>
            </div>
          </div>

          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="planCreateClose">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addPlan">
              {{ $vuetify.lang.t('$vuetify.save') }}
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>

    <ConfirmModal v-model="deleteConfirmModal" @confirm="deletePlan">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
    <ConfirmArchiveModal v-model="archiveConfirmModal" @confirm="archivePlan"
                         :active="newPlan.active">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import SpinnerLoader from "@/components/SpinnerLoader";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal";
import ConfirmModal from "@/components/confirmModal";
import EditIcon from "@/components/icons/EditIcon";
import ArchiveIcon from "@/components/icons/ArchiveIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import {mapGetters} from "vuex";

export default {
  name: "TariffPlansView",
  components: {
    DeleteIcon,
    ArchiveIcon,
    EditIcon,
    ConfirmModal,
    ConfirmArchiveModal,
    SpinnerLoader,
    editor: Editor
  },
  data: function () {
    return {
      action: "",
      tariffPlans: [],
      functions: [],

      newPlan: {
        id: 0,
        name: {},
        description: "",
        price: "",
        worker_limit: "",
        features: [],
        active: true,
      },
      planCreateModal: false,
      archiveConfirmModal: false,
      deleteConfirmModal: false,
      arch: false,
      deleted: 0,

      alertError: false,
      alertMsg: '',
      loader: false,
    }
  },
  watch: {
    newPlan: {
      deep: true,
      handler() {
        this.$saveForm(this.action + 'newPlan', this.newPlan)
      },
    },
    arch() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/tariff-plans",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          arch: !this.arch,
        },
      }).then((response) => {
        this.tariffPlans = response.data.data.data
        this.functions = response.data.data.functions
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      }).then(() => {
        this.loader = false
      })
    },

    addPlan() {
      if (this.$refs.form.validate()) {
        let form = structuredClone(this.newPlan)
        form.name = JSON.stringify(form.name)
        form.features = JSON.stringify(form.features)
        this.$axios({
          url: this.$hostname + "time-tracking/tariff-plans" + (this.newPlan.id ? "/" + this.newPlan.id : ""),
          method: this.newPlan.id ? "PUT" : "POST",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: form,
        }).then(() => {
          this.planCreateClose();
          this.loadData();
        }).catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        }).then(() => {
          this.form_loader = false
        })
      }
    },
    archivePlan() {
      let form = structuredClone(this.newPlan)
      form.name = JSON.stringify(form.name)
      form.features = JSON.stringify(form.features)
      form.active = !form.active
      this.$axios({
        url: this.$hostname + "time-tracking/tariff-plans/" + this.newPlan.id,
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: form,
      }).then(() => {
        this.planCreateClose();
        this.loadData();
        this.archiveConfirmModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response);
        this.alertError = true;
      }).then(() => {
        this.form_loader = false
      })
    },
    planCreateOpen() {
      this.planCreateModal = true
      let form = this.$loadForm(this.action + 'newPlan')
      if (form.name)
        this.newPlan = form
    },
    planCreateClose() {
      this.planCreateModal = false
      this.newPlan = {
        id: 0,
        name: {},
        description: "",
        price: "",
        worker_limit: "",
        features: [],
        active: true,
      }
    },

    deletePlan() {
      this.$axios({
        url: this.$hostname + "time-tracking/tariff-plans/" + this.deleted ,
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        this.loadData();
        this.deleteConfirmModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response);
        this.alertError = true;
      })
    }
  },

  computed: {
    ...mapGetters(['permissions'])
  }

}
</script>

<style scoped>

</style>