<template>
  <v-autocomplete class="sorts__select show" v-model="address"
                  :label="label"
                  :loading="loading" :search-input.sync="input"
                  :items="predictions" hide-no-data clearable
                  @click:clear="clearAddress"
                  :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: 200,
                                      contentClass: 'select-menu select-menu--sort',
                                  }" outlined hide-details attach>
  </v-autocomplete>
</template>

<script>
export default {
  name: "AddressAutocomplete",

  props: {
    label: String
  },

  data() {
    return {
      loading: false,
      input: '',
      address: '',
      predictions: [],
    }
  },

  methods: {

    getPlaces(val) {
      this.loading = true
      this.$axios({
        url: this.$hostname + "time-tracking/get-predictions",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          input: val,
        },
      }).then((response) => {
        console.log(response.data.data)
        this.predictions = response.data.data
      }).catch((error) => {
        // this.alertMsg = this.$catch(error);
        alert(this.$catch(error));
      }).finally(() => {
        this.loading = false
      });
    },
    getCoordinates() {
      this.$axios({
        url: this.$hostname + "time-tracking/get-coordinates",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          address: this.address,
        },
      }).then((response) => {
        console.log('got coordinates')
        this.latitude = response.data.data.lat
        this.longitude = response.data.data.lng
        this.$emit('input', response.data.data.lat, response.data.data.lng)
      }).catch((error) => {
        // this.alertMsg = this.$catch(error);
        alert(this.$catch(error));
      });
    },
    clearAddress() {
      this.address = ''
      this.input = ''
      this.latitude = null
      this.longitude = null
      this.$emit('clear')
    },
  },

  watch: {
    input(val) {
      val && val !== this.address && this.getPlaces(val)
    },
    address() {
      if (this.address)
        this.getCoordinates()
    },
  }

}
</script>

<style scoped>

</style>