<template>
  <div class="profile-object">
    <div class="profile-object__photo" v-if="photo">
      <img :src="$media_url + photo" alt="photo">
    </div>
    <div class="profile-object__title">
      <router-link v-if="object_link" class="link-not-like" :to="'/objects/' + object_id">
        {{ object_name }}
      </router-link>
      <span v-else>{{ object_name }}</span>
    </div>

    <div class="profile-object__dates">
      <div v-if="start_date">{{$vuetify.lang.t("$vuetify.start")}}: {{ start_date }}</div>
      <div v-if="end_date">{{$vuetify.lang.t("$vuetify.deadline")}}: {{ end_date }}</div>
    </div>

    <router-link
        v-if="show_client"
        :to="'/clients/' + client_id"
        class="profile-object__company link-not-like"
    >
      <div class="profile-object__logo">
        <img :src="$media_url + (client_logo ? client_logo : '')" alt=""/>
      </div>
      <div class="profile-object__company-title">
        {{ client_name }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "profile-object-card",
  props: [
    "object_link",
    "photo",
    "object_name",
    "object_id",
    "start_date",
    "end_date",
    "client_logo",
    "client_name",
    "client_id",
    "show_client"
  ],
  data: function () {
    return {};
  },
};
</script>
