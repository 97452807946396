const state = () => ({
    workers: [],
    scale: 25,
    hiddenTasks: [],
    work_types: [],
})

const getters = {
    workTypes: (state) => {
        return state.work_types;
    },
    getWorkers: (state) => {
        return state.workers;
    },

    scale: (state) => {
        return state.scale
    },

    hiddenTasks: (state) => {
        return state.hiddenTasks
    }
}

const actions = {
    setWorkTypes ({ commit }, workTypes) {
        console.log('set work types')
        console.log(workTypes)
        console.log(workTypes[0])
        console.log(workTypes[0].id)
        commit('setWorkTypes', workTypes);
    },

    setWorkers ({ commit }, workers) {
        commit('setWorkers', workers);
    },

    setScale({ commit }, scale) {
        commit('setScale', scale)
    },

    setHiddenTasks({commit}, tasks){
        commit('setHiddenTasks', tasks)
    },

    hideTask({commit}, taskId){
        commit('hideTask', taskId)
    },
    openTask({commit}, taskId){
        commit('openTask', taskId)
    },
}

const mutations = {
    setWorkTypes (state, workTypes) {
        state.work_types = workTypes
    },

    setWorkers (state, workers) {
        state.workers = workers
    },

    setScale(state, scale){
        state.scale = scale
    },

    setHiddenTasks(state, tasks){
        state.hiddenTasks = tasks
    },


    openTask(state, taskId){
        state.hiddenTasks = state.hiddenTasks.filter(t=>t != taskId)
    },

    hideTask(state, taskId){
        state.hiddenTasks.push(taskId)
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}