<template>
  <tr class="man-wrap" :class="{ rows__single: type === 'rows' }">
    <div class="cards__single" v-if="type === 'cards'">
      <span class="link-not-like photo photo-human">
        <img :src="$media_url + (photo ? photo : 'users/noavatar.png')" alt=""/>
      </span>
      <div class="cards__main-info">
        <h2>
          <span class="link-not-like">{{ options.array[0].value }}</span>
        </h2>
        <span class="main-info__description">
          {{
            options.array[1].value[$vuetify.lang.current] ? options.array[1].value[$vuetify.lang.current] : options.array[1].value['eng'] ? options.array[1].value['eng'] : options.array[1].value['fin']
          }}
        </span>
      </div>
      <div class="cards__buttons">
        <button v-if="permissions.edit.indexOf('Контакты') !== -1" class="btn-icon btn-action"
                @click="$emit('edit', id)">
          <edit-icon/>
        </button>
        <button v-if="permissions.edit.indexOf('Контакты') !== -1" class="btn-icon btn-action"
                @click="confirm_archive_modal=true">
          <archive-icon/>
        </button>
        <button v-if="permissions.edit.indexOf('Контакты') !== -1 && !active" class="btn-icon btn-action"
                @click="confirm_open(id)">
          <delete-icon/>
        </button>
      </div>
      <ul class="cards__info">
        <li v-for="(option, idx) in options.array.slice(2, 7)" :key="idx">
          <span v-html="option.icon"></span>
          <router-link v-if="option.name === 'company' && permissions.read.indexOf('Клиенты') !== -1"
                       :to="'/clients/'+company">{{ option.value }}
          </router-link>
          <span v-else v-html="option.value"></span>
        </li>
      </ul>
    </div>
    <template v-if="type === 'rows'">
      <template v-for="(header) in headers">
        <template v-if="$parent.showHeaders[header.key]">
          <td v-if="header.key === 'fullname'" :key="header.key">
            <span class="link-not-like flex">
                <div class="avatar">
                  <img v-if="photo" :src="$media_url + photo" alt=""/>
                </div>
                {{ options[header.key] }}
              </span>
          </td>
          <td v-else-if="header.key === 'client__name' && permissions.read.indexOf('Клиенты') !== -1"
              :key="header.key">
            <router-link :to="'/clients/'+company">{{ options[header.key] }}</router-link>
          </td>
          <td v-else-if="header.key === 'position__name'" :key="header.key">
            {{
              options[header.key][$vuetify.lang.current] ? options[header.key][$vuetify.lang.current] : options[header.key]['eng'] ? options[header.key]['eng'] : options[header.key]['fin']
            }}
          </td>
          <td v-else :key="header.key" v-html="options[header.key] "></td>
        </template>
      </template>
      <td class="rows__dot-menu">
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <svg
                v-bind="attrs"
                v-on="on"
                width="20"
                height="5"
                viewBox="0 0 20 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="10" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="18" cy="2.5" r="2" fill="#2C1521"/>
              </svg>
            </v-btn>
          </template>
          <div class="rows__dot-menu-content">
            <div v-if="permissions.edit.indexOf('Контакты') !== -1" class="btn-wrap" @click="$emit('edit', id)">
              {{ $vuetify.lang.t('$vuetify.edit') }}
              <button class="btn-icon btn-action">
                <edit-icon/>
              </button>
            </div>
            <div v-if="permissions.edit.indexOf('Контакты') !== -1" class="btn-wrap"
                 @click="confirm_archive_modal=true">
              <span v-if="active">{{ $vuetify.lang.t('$vuetify.toArchive') }}</span>
              <span v-else>{{ $vuetify.lang.t('$vuetify.deleteArchive') }}</span>
              <button class="btn-icon btn-action">
                <archive-icon/>
              </button>
            </div>
            <div v-if="permissions.edit.indexOf('Контакты') !== -1 && !active" class="btn-wrap"
                 @click="confirm_open(id)">
              {{ $vuetify.lang.t("$vuetify.delete") }}
              <button class="btn-icon btn-action">
                <delete-icon/>
              </button>
            </div>
          </div>
        </v-menu>
      </td>
    </template>
    <ConfirmModal v-model="confirm_modal" @confirm="tmp_delete">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
    <ConfirmArchiveModal v-model="confirm_archive_modal" @confirm="tmp_archive" :active="active">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>
  </tr>
</template>
<script>
import ConfirmModal from "@/components/confirmModal.vue";
import EditIcon from "@/components/icons/EditIcon";
import ArchiveIcon from "@/components/icons/ArchiveIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal";
import {mapGetters} from "vuex";

export default {
  name: "contact-card",

  components: {
    ConfirmArchiveModal,
    DeleteIcon,
    ArchiveIcon,
    EditIcon,
    ConfirmModal,
  },

  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    active: Boolean,
    checked: Boolean,
    type: String,
    options: Object,
    headers: Array,
    photo: String,
    id: Number,
    company: Number,
  },

  computed: {
    ...mapGetters(['permissions'])
  },

  data: function () {
    return {
      the_checked: this.checked,
      tmp: false,

      confirm_modal: false,
      confirm_archive_modal: false,
      tmp_to_delete: "",

      alertMsg: "",
      alertError: false,
    };
  },

  methods: {
    confirm_open(id) {
      this.confirm_modal = true;
      this.tmp_to_delete = id;
    },

    confirm_close() {
      this.confirm_modal = false;
      this.tmp_to_delete = "";
    },

    tmp_delete() {
      this.$axios
        .delete(this.$hostname + "time-tracking/clients-employees", {
          data: {
            id: this.id,
          },
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.alertMsg = "Пользователь удален";
          this.confirm_modal = false;
          this.$destroy();
          this.$el.parentNode.removeChild(this.$el);
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },

    tmp_archive() {
      this.$axios({
        url: this.$hostname + "time-tracking/clients-employees",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.id,
          active: !this.active
        },
      }).then(() => {
        this.confirm_archive_modal = false
        this.$emit('archive')
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
  },
};
</script>
