<template>
  <div class="per-page">
    <span>{{ $vuetify.lang.t("$vuetify.perPage") }}</span>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <button class="per-page__btn"
                v-bind="attrs"
                v-on="on"
        >
          <span>{{ value }}</span>
          <svg
            width="9"
            height="5"
            viewBox="0 0 9 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 0.5L4.5 4.5L8 0.5"
              stroke="#2C1521"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </template>
      <v-list class="per-page__list">
        <template v-for="(item) in perPageSelect">
          <v-list-item :key="item" @click="perPageClick(item)">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PerPageSelect",
  props: {
    value: {
      type: Number,
    },
    perPageSelect: {
      type: Array,
      default: () => [24, 48, 72, 96],
    },
  },
  methods: {
    perPageClick(item) {
      if (this.perPageSelect[0] === 24)
        localStorage.setItem("per_page", item);
      this.$emit("input", item);
    }
  }
}
</script>

<style scoped>

</style>