<template>
  <div class="cv-wrap">
    <div class="documents">
      <div class="page__sorts">

      </div>

      <v-select v-model="status" class="custom" v-if="profile"
                :items="statuses"
                :label="$vuetify.lang.t('$vuetify.cvs.status')" outlined attach
                :menu-props="{
                              bottom: true,
                              offsetY: true,
                              maxHeight: width<1024 ? 200 : 800,
                              contentClass: 'select-menu',
                            }"
                @change="saveStatus"
      >
        <template v-slot:selection="{item }">
          {{ $vuetify.lang.t("$vuetify.cvs.statuses." + item) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $vuetify.lang.t("$vuetify.cvs.statuses." + item) }}
        </template>
      </v-select>
      <v-text-field v-else class="custom" :disabled="!profile"
                    :value="$vuetify.lang.t('$vuetify.cvs.statuses.' + status)"
                    :label="$vuetify.lang.t('$vuetify.cvs.status')" outlined attach
      ></v-text-field>

      <table class="main-table">
        <thead>
        <td>{{ $vuetify.lang.t("$vuetify.name2") }}</td>
        <td class="td-spec">{{ $vuetify.lang.t("$vuetify.hh.position") }}</td>
        <td>
          <div class="main-table__controls-td">
            <button v-if="profile" class="btn btn-secondary"
                    @click="openCreateCVModal">
              + {{ $vuetify.lang.t('$vuetify.add') }}
            </button>
          </div>
        </td>
        </thead>
        <tbody>
        <tr v-for="item in cv" :key="item.id">
          <td>
            <router-link :to="{name: 'CV', params: {id: item.id}}">
              {{ item.name }}
            </router-link>
          </td>
          <td class="td-spec">
            {{ item.position }}
          </td>
          <td v-if="profile">
            <div class="main-table__controls-td">
              <button class="btn-icon btn-action" @click="openEditCVModal(item)">
                <edit-icon></edit-icon>
              </button>
              <button class="btn-icon btn-action" @click="confirmDeleteCV(item)">
                <delete-icon></delete-icon>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <v-dialog v-model="addCVModal">
      <div class="modal modal-big modal-cv">
        <div class="modal-cv__header">
          <div class="modal-cv__nav">
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===1}"
                 @click="chooseStep(1)"
            >
              <div class="modal-cv__nav-number">1</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===2}"
                 @click="chooseStep(2)"
            >
              <div class="modal-cv__nav-number">2</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===3}"
                 @click="chooseStep(3)"
            >
              <div class="modal-cv__nav-number">3</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===4}"
                 @click="chooseStep(4)"
            >
              <div class="modal-cv__nav-number">4</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===5}"
                 @click="chooseStep(5)"
            >
              <div class="modal-cv__nav-number">5</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===6}"
                 @click="chooseStep(6)"
            >
              <div class="modal-cv__nav-number">6</div>
              <div class="modal-cv__nav-title"></div>
            </div>
            <div class="modal-cv__nav-step"
                 :class="{'active' : cvStep===7}"
                 @click="chooseStep(7)"
            >
              <div class="modal-cv__nav-number">7</div>
              <div class="modal-cv__nav-title"></div>
            </div>
          </div>
          <button class="btn btn-primary" @click="saveCV">{{ $vuetify.lang.t('$vuetify.saveAndClose') }}</button>
        </div>
        <div class="modal-cv__steps">
          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===1" key="1-step">
            <h2>{{ $vuetify.lang.t('$vuetify.cvs.personalData') }}</h2>
            <div class="modal-cv__main-info">


              <v-text-field v-model="newCV.name"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.name')" outlined></v-text-field>
              <v-text-field v-model="newCV.firstname" :label="$vuetify.lang.t('$vuetify.signup.name')"
                            outlined :rules="[$rules.required, $rules.text]"></v-text-field>
              <v-text-field v-model="newCV.lastname" :label="$vuetify.lang.t('$vuetify.signup.lastname')"
                            outlined :rules="[$rules.required, $rules.text]"></v-text-field>
              <v-text-field v-model="newCV.phone" :label="$vuetify.lang.t('$vuetify.phone')"
                            outlined></v-text-field>
              <v-text-field v-model="newCV.email" :label="$vuetify.lang.t('$vuetify.email')"
                            outlined :rules="[$rules.required, $rules.email]"></v-text-field>

              <div class="modal-cv__main-photo">
                <div class="modal-cv__Fmain-photo-wrap">
                  <img class="cv-photo" :src="$media_url + (newCV.photo_path ? newCV.photo_path : 'users/noavatar.png')" alt="">
                </div>
                <div class="modal-cv__main-photo-buttons">

                  <button class="btn btn-secondary" @click="newCV.photo_path=currentProfile.photo_path; $forceUpdate()">
                    {{ $vuetify.lang.t('$vuetify.loadFromProfile') }}
                  </button>
                  <v-file-input class="file-input--hide" v-model="cvPhoto" accept=".jpg, .png, .jpeg"
                                :placeholder=" $vuetify.lang.t('$vuetify.uploadImg')"
                                prepend-icon="" id="photo"
                                :rules="[$rules.fileSize]" outlined
                                @change="showFileCV"
                  ></v-file-input>
                  <button class="btn btn-secondary" @click="clickInput">
                    <span v-if="cvPhoto">{{ cvPhoto.name }}</span>
                    <span v-else>{{ $vuetify.lang.t('$vuetify.uploadImg') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="cancelAddCV">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>

          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===2" key="2-step">
            <h2>{{ $vuetify.lang.t("$vuetify.experience") }}</h2>
            <div v-if="!experienceForm" class="cv-list">
              <div v-for="(item, i) in newCV.experience" :key="i" class="flex cv-list__item">
                <span>{{ item.company_name }}</span>
                <button class="btn-icon btn-action" @click="deleteExperience(i)">
                  <delete-icon></delete-icon>
                </button>
                <button class="btn-icon btn-action" @click="editExperience(item)">
                  <edit-icon></edit-icon>
                </button>
              </div>
              <button v-if="!experienceForm" class="btn btn-secondary modal-cv__button" @click="experienceForm=true">
                {{ $vuetify.lang.t("$vuetify.addExperience") }}
              </button>
              <v-progress-linear :class="{form__progress : form_loader}"
                                 :active="form_loader"
                                 indeterminate
                                 rounded
                                 height="6"
              ></v-progress-linear>
              <div class="btn-group footer-btns">
                <button class="btn btn-secondary" @click="cvStep -= 1">
                  {{ $vuetify.lang.t('$vuetify.back') }}
                </button>
                <button class="btn btn-primary" @click="nextStep">
                  {{ $vuetify.lang.t('$vuetify.forward') }}
                </button>
              </div>
            </div>
            <template v-if="experienceForm">
              <v-text-field v-model="newExperience.company_name"
                            :label="$vuetify.lang.t('$vuetify.companyName')" outlined></v-text-field>
              <v-text-field v-model="newExperience.company_branch" :label="$vuetify.lang.t('$vuetify.activityType')"
                            outlined :rules="[$rules.required]"></v-text-field>
              <v-text-field v-model="newExperience.position" :label="$vuetify.lang.t('$vuetify.position')"
                            outlined></v-text-field>
              <div class="form__row form__row--mb">
                <div>
                  <v-menu ref="date" v-model="menuStartDate" :close-on-content-click="false"
                          transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="newExperience.start_date ? new Date(newExperience.start_date).toLocaleDateString('ru-Ru').substring(3) : ''"
                          prepend-inner-icon="$calendar" :placeholder="$vuetify.lang.t('$vuetify.startDate')"
                          outlined hide-details v-bind="attrs" v-on="on" readonly :rules="[$rules.required]">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="newExperience.start_date" type="month" @input="menuStartDate = false"
                                   no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                                   show-adjacent-months></v-date-picker>
                  </v-menu>
                </div>
                <div>
                  <v-menu ref="date" v-model="menuEndDate" :close-on-content-click="false"
                          transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="newExperience.end_date ? new Date(newExperience.end_date).toLocaleDateString('ru-Ru').substring(3) : ''"
                          prepend-inner-icon="$calendar"
                          :placeholder="$vuetify.lang.t('$vuetify.endDate')" outlined
                          hide-details v-bind="attrs" v-on="on" readonly>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="newExperience.end_date" type="month" @input="menuEndDate = false"
                                   no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                                   show-adjacent-months></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <v-textarea v-model="newExperience.comment" :label="$vuetify.lang.t('$vuetify.comment')"
                          outlined counter="300"></v-textarea>
              <div class="btn-group footer-btns">
                <button class="btn btn-danger" @click="deleteExperience">
                  {{ $vuetify.lang.t('$vuetify.delete') }}
                </button>

                <button class="btn btn-primary" @click="saveExperience">
                  {{ $vuetify.lang.t('$vuetify.save') }}
                </button>
              </div>
            </template>


            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
          </v-form>

          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===3" key="3-step">
            <h2>{{ $vuetify.lang.t("$vuetify.education") }}</h2>
            <div v-if="!educationForm">
              <div class="cv-list">
                <div v-for="(item, i) in newCV.education" :key="i" class="flex cv-list__item">
                  <span>{{ item.institution }}</span>
                  <button class="btn-icon btn-action" @click="deleteEducation(i)">
                    <delete-icon></delete-icon>
                  </button>
                  <button class="btn-icon btn-action" @click="editEducation(item)">
                    <edit-icon></edit-icon>
                  </button>
                </div>

              </div>
              <button v-if="!educationForm" class="btn btn-secondary modal-cv__button" @click="educationForm=true">
                {{ $vuetify.lang.t("$vuetify.add") }}
              </button>


              <div class="btn-group footer-btns">
                <button class="btn btn-secondary" @click="cvStep -= 1">
                  {{ $vuetify.lang.t('$vuetify.back') }}
                </button>
                <button class="btn btn-primary" @click="nextStep">
                  {{ $vuetify.lang.t('$vuetify.forward') }}
                </button>
              </div>
            </div>
            <template v-if="educationForm">
              <v-select v-model="newEducation.level"
                        :items="educationLevel"
                        :label="$vuetify.lang.t('$vuetify.education_level')" outlined attach
                        :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }">
                <template v-slot:selection="{item }">
                  {{ $vuetify.lang.t("$vuetify.educationLevel." + item) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ $vuetify.lang.t("$vuetify.educationLevel." + item) }}
                </template>
              </v-select>
              <div v-if="newEducation.level" :key="newEducation.level">
                <v-text-field v-model="newEducation.institution"
                              :label="$vuetify.lang.t('$vuetify.educationalInstitution')" outlined></v-text-field>
                <v-select v-if="newEducation.level === 'high'" v-model="newEducation.high_level"
                          :items="educationHighLevel"
                          :label="$vuetify.lang.t('$vuetify.educationHighLevel')" outlined attach
                          :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }" :rules="[$rules.required]">
                  <template v-slot:selection="{item }">
                    {{ $vuetify.lang.t("$vuetify.educationLevel." + item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ $vuetify.lang.t("$vuetify.educationLevel." + item) }}
                  </template>
                </v-select>
                <div class="form__row">
                  <div>
                    <v-text-field v-model="newEducation.start_year" :label="$vuetify.lang.t('$vuetify.startYear')"
                                  outlined :rules="[$rules.required, $rules.positiveNumbers]"></v-text-field>

                  </div>
                  <div>
                    <v-text-field v-model="newEducation.end_year" :label="$vuetify.lang.t('$vuetify.endYear')"
                                  outlined></v-text-field>

                  </div>
                </div>
                <template v-if='newEducation.level === "high" || newEducation.level === "technical"'>
                  <v-text-field v-model="newEducation.sphere"
                                :label="$vuetify.lang.t('$vuetify.educationSphere')" outlined></v-text-field>
                  <v-text-field v-model="newEducation.speciality"
                                :label="$vuetify.lang.t('$vuetify.speciality')" outlined></v-text-field>
                </template>
                <v-textarea v-model=" newEducation.comment" :label="$vuetify.lang.t('$vuetify.comment')"
                            outlined counter="150"></v-textarea>
              </div>
              <div class="btn-group footer-btns">
                <button class="btn btn-danger" @click="deleteEducation">
                  {{ $vuetify.lang.t('$vuetify.delete') }}
                </button>
                <button class="btn btn-primary" @click="saveEducation">
                  {{ $vuetify.lang.t('$vuetify.save') }}
                </button>
              </div>
            </template>

            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
          </v-form>

          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===4" key="4-step">

            <div v-if="!certificateForm">
              <h2>{{ $vuetify.lang.t("$vuetify.licenses") }}</h2>
              <div class=" flex">
                {{ $vuetify.lang.t('$vuetify.driverLicense') }}
              </div>
              <div>
                <div class="flex flex-wrap">
                  <v-checkbox
                      v-model="newCV.driver_license"
                      :label="$vuetify.lang.t('$vuetify.no')"
                      :value="null"
                      @click="newCV.driver_license = [null]"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newCV.driver_license"
                      label="A"
                      value="A"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newCV.driver_license"
                      label="B"
                      value="B"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newCV.driver_license"
                      label="C"
                      value="C"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newCV.driver_license"
                      label="D"
                      value="D"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newCV.driver_license"
                      label="E"
                      value="E"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="newCV.driver_license"
                      label="F"
                      value="F"
                  ></v-checkbox>
                </div>

              </div>

              <h2>{{ $vuetify.lang.t("$vuetify.licenses") }}</h2>

              <div class="cv-list">

                <div v-for="(item, i) in newCV.certificates" :key="i" class="flex cv-list__item">
                  <span>{{ item.name }}</span>
                  <button class="btn-icon btn-action" @click="deleteCertificate(i)">
                    <delete-icon></delete-icon>
                  </button>
                  <button class="btn-icon btn-action" @click="editCertificate(item)">
                    <edit-icon></edit-icon>
                  </button>
                </div>
                <button class="btn btn-secondary cv-list__item"
                        @click="addCertificate">
                  {{ $vuetify.lang.t("$vuetify.add") }}
                </button>
                <div class="btn-group footer-btns">
                  <button class="btn btn-secondary" @click="cvStep -= 1">
                    {{ $vuetify.lang.t('$vuetify.back') }}
                  </button>
                  <button class="btn btn-primary" @click="nextStep">
                    {{ $vuetify.lang.t('$vuetify.forward') }}
                  </button>
                </div>
              </div>

            </div>
            <template v-if="certificateForm">
              <v-text-field v-model="newCertificate.name" :label="$vuetify.lang.t('$vuetify.name2')"
                            outlined :rules="[$rules.required]"></v-text-field>
              <v-text-field v-model="newCertificate.number_url" :label="$vuetify.lang.t('$vuetify.numberOrUrl')"
                            outlined :rules="[$rules.required]"></v-text-field>
              <v-text-field v-model="newCertificate.issued" :label="$vuetify.lang.t('$vuetify.issued')"
                            outlined></v-text-field>
              <v-text-field v-model="newCertificate.year" :label="$vuetify.lang.t('$vuetify.year')"
                            outlined :rules="[$rules.positiveNumbers]"></v-text-field>
              <v-file-input v-model="newCertificate.file_input" accept=".jpg, .png, .jpeg"
                            :value="newCertificate.file ? newCertificate.file : null"
                            :placeholder=" $vuetify.lang.t('$vuetify.downloadPhoto')" :prepend-icon="$photo"
                            :messages="newCertificate.file ? newCertificate.file : null"
                            :rules="[$rules.fileSize]"></v-file-input>

              <div class="btn-group mt-3">
                <button class="btn btn-danger" @click="deleteCertificate">
                  {{ $vuetify.lang.t('$vuetify.delete') }}
                </button>
                <button class="btn btn-primary" @click="saveCertificate">
                  {{ $vuetify.lang.t('$vuetify.save') }}
                </button>

              </div>
            </template>

            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>

          </v-form>

          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===5" key="5-step">
            <h2>{{ $vuetify.lang.t("$vuetify.settings.language-settings") }}</h2>
            <div v-for="(item, i) in newCV.languages" :key="i" class="flex language-item">
              <v-select v-model="item.language"
                        :items="Object.keys($vuetify.lang.locales)"
                        :label="$vuetify.lang.t('$vuetify.language')" outlined attach
                        :rules="[$rules.required]"
                        :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }">
                <template v-slot:selection="{item }">
                  {{ $vuetify.lang.t("$vuetify.settings.languages." + item) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ $vuetify.lang.t("$vuetify.settings.languages." + item) }}
                </template>

              </v-select>
              <v-select v-model="item.level"
                        class="modal-cv__lang-level-select"
                        :items="languageLevels"
                        :label="$vuetify.lang.t('$vuetify.languageLevel')" outlined attach
                        item-value="name"
                        :rules="[$rules.required]"
                        :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }">
                <template v-slot:selection="{item }">
                  <div class="modal-cv__lang-level">
                    {{ $vuetify.lang.t("$vuetify.languageLevels." + item.name) }}
                    <div class="rating__content-wrapper">
                      <div class="rating__content">
                      </div>
                      <mark :style="'width: ' + (item.stars/6)*100+'%'"></mark>
                    </div>
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div class="modal-cv__lang-level">
                    {{ $vuetify.lang.t("$vuetify.languageLevels." + item.name) }}
                    <div class="rating__content-wrapper">
                      <div class="rating__content">
                      </div>
                      <mark :style="'width: ' + (item.stars/6)*100+'%'"></mark>
                    </div>
                  </div>
                </template>
              </v-select>
              <button class="btn-icon btn-action form-icon-h" @click="deleteLanguage(i)">
                <delete-icon></delete-icon>
              </button>
            </div>

            <button class="btn btn-secondary" @click="addLanguage">
              {{ $vuetify.lang.t("$vuetify.add") }}
            </button>

            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="cvStep -= 1">
                {{ $vuetify.lang.t('$vuetify.back') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>

          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===6" key="6-step">
            <h2>{{ $vuetify.lang.t("$vuetify.cvs.location") }}</h2>


<!--            <v-text-field v-model="newCV.residence_country" :label="$vuetify.lang.t('$vuetify.cvs.residence_country')"-->
<!--                          outlined></v-text-field>-->
<!--            <v-text-field v-model="newCV.residence_region" :label="$vuetify.lang.t('$vuetify.cvs.residence_region')"-->
<!--                          outlined></v-text-field>-->
<!--            <v-text-field v-model="newCV.residence_city" :label="$vuetify.lang.t('$vuetify.cvs.residence_city')"-->
<!--                          outlined></v-text-field>-->

            <address-map-component v-model="newCV.residence_address"
                                   :label="$vuetify.lang.t('$vuetify.cvs.residence_address')"
                                   v-on:latitude="setLatitude"
                                   v-on:longitude="setLongitude"></address-map-component>
            <v-text-field v-model.number="newCV.radius" type="number" :label="$vuetify.lang.t('$vuetify.cvs.radius')"
                          outlined :rules="[$rules.nullPositiveNumbers]"></v-text-field>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="cvStep -= 1">
                {{ $vuetify.lang.t('$vuetify.back') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.forward') }}
              </button>
            </div>
          </v-form>
          <v-form ref="cvForm" @submit.prevent="" v-if="cvStep===7" key="7-step">
            <h2>{{ $vuetify.lang.t("$vuetify.cvs.about") }}</h2>

            <v-text-field v-model="newCV.position" :label="$vuetify.lang.t('$vuetify.signup.mainSpec')"
                          outlined></v-text-field>
            <v-textarea v-model=" newCV.skills" :label="$vuetify.lang.t('$vuetify.skills')"
                        outlined counter="500"></v-textarea>

            <v-textarea v-model=" newCV.about" :label="$vuetify.lang.t('$vuetify.cvs.aboutMe')"
                        outlined counter="500"></v-textarea>


            <v-progress-linear :class="{form__progress : form_loader}"
                               :active="form_loader"
                               indeterminate
                               rounded
                               height="6"
            ></v-progress-linear>

            <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
            <div class="btn-group footer-btns">
              <button class="btn btn-secondary" @click="cvStep -= 1">
                {{ $vuetify.lang.t('$vuetify.back') }}
              </button>
              <button class="btn btn-primary" @click="nextStep">
                {{ $vuetify.lang.t('$vuetify.save') }}
              </button>
            </div>
          </v-form>
        </div>

        <div class="modal-cv__footer">
          <button class="btn btn-primary" @click="saveCV">{{ $vuetify.lang.t('$vuetify.saveAndClose') }}</button>

        </div>
      </div>
    </v-dialog>


    <ConfirmModal v-model="confirmDeleteCVModal" @confirm="deleteCV">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>


  </div>
</template>

<script>
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import ConfirmModal from "@/components/confirmModal.vue";
import AddressMapComponent from "@/components/AddressMapComponent.vue";

export default {
  name: "CVs-component",
  components: {AddressMapComponent, ConfirmModal, EditIcon, DeleteIcon},

  props: {
    currentProfile: Object,
    profile: Boolean
  },

  data: function () {
    return {
      width: screen.width,
      statuses: [
        'busy',
        'free',
        'busy_additional',
      ],
      status: '',

      cv: [],
      addCVModal: false,
      cvStep: 1,
      newCV: {},
      cvPhoto: null,
      confirmDeleteCVModal: false,
      deletingCV: null,


      photoHtmlCV: null,
      fileHtmlCV: null,


      menuBirth: false,
      experienceForm: false,
      editedExperience: false,
      newExperience: {},
      menuStartDate: false,
      menuEndDate: false,
      educationForm: false,
      editedEducation: false,
      newEducation: {},
      educationLevel: ['general', 'middle', 'technical', 'high'],
      educationHighLevel: ['diploma', 'bachelor', 'master', 'doctor'],
      certificateForm: false,
      editedCertificate: false,
      newCertificate: {},
      driverLicenceForm: false,
      driverLicence: [],
      languageForm: false,
      editedLanguage: false,
      newLanguage: {},
      languageLevels: [
        {
          "name": "A1",
          "stars": 1
        },
        {
          "name": "A2",
          "stars": 2
        },
        {
          "name": "B1",
          "stars": 3
        },
        {
          "name": "B2",
          "stars": 4
        },
        {
          "name": "C1",
          "stars": 5
        },
        {
          "name": "C2",
          "stars": 6
        }
      ],


      loader: false,
      form_loader: false,
      alertMsg: '',
      alertError: false,
    }
  },

  methods: {
    async chooseStep(step) {
      if (this.$refs.cvForm.validate()) {
        // this.form_loader = true;
        switch (this.cvStep) {
          case 1:
            if (this.cvPhoto) {
              this.newCV.photo_path = await this.uploadCVFiles(this.cvPhoto);
            }
            break;
          case 4:
            for (let cert of this.newCV.certificates) {
              if (cert.file_input) {
                cert.file = await this.uploadCVFiles(cert.file_input);
                delete cert.file_input
              }
            }
            break;
        }
        // if (this.cvStep < 7) {
          this.cvStep = step
        // } else {
        //   this.saveCV();
        //   this.addCVModal = false;
        // }
      }
    },
    saveStatus() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.currentProfile.id,
          auth_user_id: this.currentProfile.auth_user_id.id,
          status: this.status
        },
      })
          .then(() => {
          })
          .catch((error) => {
            // this.alertMsg = this.$catch(error);
            alert(this.$catch(error));
          });
    }
    ,
    loadCV() {
      let url = this.profile ? "my-resume" : "profile/resume/" + this.currentProfile.auth_user_id.id
      this.$axios({
        url: this.$hostname + "time-tracking/" + url,
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.cv = response.data.data.data
        this.addCVModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
      });
    }
    ,

    // eslint-disable-next-line no-unused-vars
    showFileCV(e) {
      var f = this.cvPhoto;
      this.fileHtmlCV = '';
      this.photoHtmlCV = '';

      if (!f.type.match("image.*")) {
        this.fileHtmlCV = f.name
      } else {
        var fr = new FileReader();
        fr.onload = (() => {
          return (e) => {
            this.photoHtmlCV = e.target.result;
          };
        })(f);
        fr.readAsDataURL(f);
      }
    }
    ,
    saveCV() {
      let data = JSON.parse(JSON.stringify(this.newCV));
      data.experience = JSON.stringify(data.experience)
      data.education = JSON.stringify(data.education)
      data.driver_license = JSON.stringify(data.driver_license)
      data.certificates = JSON.stringify(data.certificates)
      data.languages = JSON.stringify(data.languages)
      this.$axios({
        url: this.$hostname + "time-tracking/my-resume",
        method: this.newCV.id ? "PUT" : "POST",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: data,
      }).then((response) => {
        this.newCV.id = response.data.data.data
        // if (this.cvStep === 6) {
        this.loadCV()
        // }
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.addCVModal = true
      }).finally(() => {
        this.form_loader = false
      });
    }
    ,
    deleteCV() {
      this.$axios({
        url: this.$hostname + "time-tracking/my-resume",
        method: "DELETE",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.deletingCV
        },
      }).then(() => {
        this.loadCV()
        this.confirmDeleteCVModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      });
    }
    ,
    confirmDeleteCV(cv) {
      this.deletingCV = cv.id
      this.confirmDeleteCVModal = true
    }
    ,
    openCreateCVModal() {
      this.newCV = {
        name: "CV-" + (this.cv.length + 1),
        firstname: this.currentProfile.name,
        lastname: this.currentProfile.lastname,
        birthdate: this.currentProfile.birthdate,
        email: this.currentProfile.auth_user_id.email,
        phone: this.currentProfile.phone,
        experience: [],
        education: [],
        certificates: [],
        languages: [],
        driver_license: [null],
        status: 'free'
      }
      this.cvStep = 1
      this.addCVModal = true
    }
    ,
    openEditCVModal(cv) {
      this.newCV = cv
      this.cvStep = 1
      this.addCVModal = true
      this.experienceForm = false
      this.educationForm = false
      this.driverLicenceForm = false
      this.certificateForm = false
    }
    ,
    async nextStep() {
      if (this.$refs.cvForm.validate()) {
        // this.form_loader = true;
        switch (this.cvStep) {
          case 1:
            if (this.cvPhoto) {
              this.newCV.photo_path = await this.uploadCVFiles(this.cvPhoto);
            }
            break;
          case 4:
            for (let cert of this.newCV.certificates) {
              if (cert.file_input) {
                cert.file = await this.uploadCVFiles(cert.file_input);
                delete cert.file_input
              }
            }
            break;
        }
        if (this.cvStep < 7) {
          this.cvStep += 1;
        } else {
          this.saveCV();
          this.addCVModal = false;
        }
      }
    }
    ,
    cancelAddCV() {
      this.openCreateCVModal()
      this.addCVModal = false
    }
    ,
    editExperience(item) {
      this.newExperience = item
      this.experienceForm = true
      this.editedExperience = true
    }
    ,
    saveExperience() {
      if (this.editedExperience) {
        this.editedExperience = false
      } else {
        this.newCV.experience.push(this.newExperience)
      }
      this.newExperience = {}
      this.experienceForm = false
    }
    ,
    deleteExperience(i) {
      if (typeof (i) == "number") {
        this.newCV.experience.splice(i, 1)
      }
      this.newExperience = {}
      this.experienceForm = false
    }
    ,
    editEducation(item) {
      this.newEducation = item
      this.educationForm = true
      this.editedEducation = true
    }
    ,
    saveEducation() {
      if (this.editedEducation) {
        this.editedEducation = false
      } else {
        this.newCV.education.push(this.newEducation)
      }
      this.newEducation = {}
      this.educationForm = false
    }
    ,
    deleteEducation(i) {
      console.log(i)
      if (typeof (i) == "number") {
        this.newCV.education.splice(i, 1)
      }
      this.newEducation = {}
      this.educationForm = false
    }
    ,
    addCertificate(){
      this.certificateForm = true;
      this.driverLicenceForm = false
    },
    editCertificate(item) {
      this.newCertificate = item
      this.newCertificate.file_input = null
      this.certificateForm = true
      this.editedCertificate = true
    }
    ,
    saveCertificate: async function () {
      if (this.newCertificate.file_input) {
        this.form_loader = true
        this.newCertificate.file = await this.uploadCVFiles(this.newCertificate.file_input);
        delete this.newCertificate.file_input
        this.form_loader = false
      }

      if (this.editedCertificate) {
        this.editedCertificate = false
      } else {
        this.newCV.certificates.push(this.newCertificate)
      }
      this.newCertificate = {}
      this.certificateForm = false
    }
    ,
    deleteCertificate(i) {
      if (i != undefined) {
        this.newCV.certificates.splice(i, 1)
      }
      this.newCertificate = {}
      this.certificateForm = false
    }
    ,
    addLanguage() {
      this.newCV.languages.push({})
    }
    ,
    deleteLanguage(i) {
      if (i != undefined) {
        this.newCV.languages.splice(i, 1)
      }
      this.newLanguage = {}
      this.languageForm = false
    }
    ,

    uploadCVFiles(file) {
      return this.$axios({
        url: this.$hostname + "time-tracking/images/upload/users",
        method: "POST",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          file: file,
        },
      }).then((response) => {
        console.log(response.data)
        console.log(response.data.data)
        return response.data.data;
      }).catch((error) => {
        this.alertMsg = "Ошибка загрузки файла";
        switch (error.response.status) {
          case 400:
            this.alertMsg = error.message;
            break;
          case 403:
            this.alertMsg = "Доступ запрещен";
            break;
          case 404:
            this.alertMsg = "Страница не найдена";
            break;
        }
        alert(this.alertMsg);
        return null;
      });
    },

    clickInput() {
      document.getElementById("photo").click();
    },

    setLatitude(value) {
      this.newCV.latitude = value
    },
    setLongitude(value) {
      this.newCV.longitude = value
    },
  },

  mounted() {
    this.loadCV();
    this.status = this.currentProfile.status
  }

}
</script>
