<template>
  <div class="page-wrapper">
    <div class="page-head">

      <div class="breadcrumbs">
        <div class="h1" v-if="$route.path.includes('bookkeeping')">
          <router-link class="link-not-like" to="/bookkeeping">{{ $vuetify.lang.t('$vuetify.menu.accounting') }}
          </router-link>
        </div>
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <h1>{{ $vuetify.lang.t('$vuetify.menu.timingChangesList') }}</h1>
        <svg v-if="name" width="9" height="15" viewBox="0 0 9 15" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="page-head__btns">

        <BookkeepingSelect :mode="'timingChangesList'"></BookkeepingSelect>
      </div>

    </div>

    <div class="page-head">
      <div class="page__sorts">
        <v-menu ref="date" v-model="menus.date"
                :close-on-content-click="false" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="search-field" :value="range.join(' ~ ')"
                          @keypress.enter="loadData()" prepend-inner-icon="$calendar"
                          :placeholder="$vuetify.lang.t('$vuetify.date')" outlined
                          hide-details v-bind="attrs" v-on="on" readonly>
            </v-text-field>
          </template>
          <v-date-picker v-model="range" no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                         show-adjacent-months
                         range @input="range.length == 2  ? menus.date = false : menus.date = true"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete v-model="filter.worker" clearable
                        :label="$vuetify.lang.t('$vuetify.accounting.card.worker')"
                        :items="workers" item-text="label" item-value="id"
                        @change="loadData"
                        :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: 200,
                                      contentClass: 'select-menu select-menu--sort',
                                  }" outlined hide-details attach>
          <template v-slot:selection="{ item }">
            {{ item.name }} {{ item.lastname }},
            <template v-if="item.position && item.position.name">
              ({{
                item.position.name[$vuetify.lang.current] ? item.position.name[$vuetify.lang.current] : item.position.name['eng'] ? item.position.name['eng'] : item.position.name['fin']
              }})
            </template>
          </template>
          <template v-slot:item="{ item }">
            {{ item.name }} {{ item.lastname }},
            <template v-if="item.position && item.position.name">
              ({{
                item.position.name[$vuetify.lang.current] ? item.position.name[$vuetify.lang.current] : item.position.name['eng'] ? item.position.name['eng'] : item.position.name['fin']
              }})
            </template>
          </template>
        </v-autocomplete>
        <button class="btn-secondary btn" @click="clearFilters">
          {{ $vuetify.lang.t('$vuetify.main.see_all') }}
        </button>
      </div>
    </div>

    <table class="main-table">
      <thead class="timing-wrap timing__head">
      <td>{{ $vuetify.lang.t('$vuetify.date') }}</td>
      <td>{{ $vuetify.lang.t('$vuetify.author') }}</td>
      <td>{{ $vuetify.lang.t('$vuetify.beforeChanges') }}</td>
      <td>{{ $vuetify.lang.t('$vuetify.afterChanges') }}</td>
      </thead>
      <tbody>
      <tr class="timing-wrap rows__single"
          v-for="item in history" :ref="'item'+item.id" :key="item.id">
        <td>{{ new Date(item.datetime).toLocaleDateString('ru-Ru') }}
          {{ new Date(item.datetime).toLocaleTimeString('ru-Ru').substring(0, 5) }}
        </td>
        <td>
          <router-link v-if="permissions.admin || permissions.read.indexOf('Работники') !== -1"
                       :to="'/workers/' + item.author.id">
            {{ item.author.name }} {{ item.author.lastname }}
          </router-link>
          <span v-else>{{ item.author.name }} {{ item.author.lastname }}</span>
        </td>
        <td>
          {{ $vuetify.lang.t('$vuetify.wages.rate') }} {{ item.old.options.rate }}€ <br>
          {{ item.old.time_start.substring(0, 5) }}-{{ item.old.time_end.substring(0, 5) }}
        </td>
        <td>
          {{ $vuetify.lang.t('$vuetify.wages.rate') }} {{ item.changes.options.rate }}€ <br>
          {{ item.changes.time_start.substring(0, 5) }}-{{ item.changes.time_end.substring(0, 5) }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="page-footer">
      <per-page-select v-model="perPage" :per-page-select="[50, 100, 150, 200]"></per-page-select>
      <v-pagination
        v-model="page"
        :length="length"
        @input="loadData"
      ></v-pagination>
    </div>


    <SpinnerLoader :overlay="loader"></SpinnerLoader>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";
import PerPageSelect from "@/components/PerPageSelect.vue";
import {mapActions, mapGetters} from "vuex";
import BookkeepingSelect from "@/components/BookkeepingSelect.vue";

export default {
  name: "TimingChangesList",
  components: {BookkeepingSelect, PerPageSelect, SpinnerLoader},

  data() {
    return {
      name: "",
      sort: '',
      filter: {
        worker: 0,
      },
      page: 1,
      perPage: 50,
      length: 0,

      history: [],
      workers: [],
      range: [
        new Date(new Date().setDate(1)).toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      dateMenu: false,
      menus: {
        date: false,
      },

      alertError: false,
      alertMsg: "",
      loader: false,
      width: screen.width,
    }
  },

  watch: {
    type: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    range: function () {
      if (this.range.length == 2)
        this.loadData()
    },
    perPage: function () {
      if ((this.page * this.perPage) > (this.page * this.history.length))
        this.page = 1
      this.loadData()
    },
  },


  created: function () {
    this.checkPermission('Бухгалтерия').then(()=>{
      this.loadData()
      this.loadWorkers()
    });
  },


  methods: {
    ...mapActions(['checkPermission']),

    allowedDates: val => val <= new Date().toISOString(),

    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/history",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          table: 'TimeReport',
          range: JSON.stringify(this.range),
          filter: JSON.stringify(this.filter),
          page: this.page,
          per: this.perPage
        },
      }).then((response) => {
        this.history = response.data.data.data
        this.length = response.data.data.pages
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      }).then(() => {
        this.loader = false
      })
    },
    loadWorkers() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/active",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      })
        .then((response) => {
          this.workers = response.data.data.data;
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },

    clearFilters() {
      this.range = []
      this.filter.worker = 0
      this.loadData()
    },
  },

  computed: {
    ...mapGetters(['permissions'])
  }
}
</script>

<style scoped>
.main-table thead, .main-table .timing-wrap {
  grid-template-columns: 20% 20% 30% 30% !important;
}

.timing-wrap.rows__single {
  max-height: unset;
}
</style>