<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.5953 2.25374C11.0036 1.84617 11.5541 1.61299 12.131 1.60334C12.7078 1.59369 13.2658 1.80832 13.6875 2.20202C14.1092 2.59571 14.3617 3.13773 14.3916 3.71386C14.4215 4.28999 14.2267 4.85525 13.8481 5.29054L13.7441 5.40254L6.05928 13.0873C5.89548 13.2509 5.69814 13.377 5.48088 13.4569L5.34888 13.4993L2.10488 14.3841C2.04204 14.4015 1.97593 14.4032 1.91228 14.3891C1.84864 14.375 1.7894 14.3456 1.73971 14.3034C1.69003 14.2612 1.65141 14.2075 1.62721 14.147C1.60302 14.0865 1.59398 14.021 1.60088 13.9561L1.61368 13.8921L2.49848 10.6489C2.55928 10.4249 2.66808 10.2169 2.81688 10.0409L2.91048 9.93854L10.5953 2.25374ZM9.90728 4.07374L3.47608 10.5041C3.4026 10.5777 3.34418 10.665 3.30408 10.7609L3.27048 10.8593L2.56888 13.4273L5.13848 12.7273C5.20542 12.7091 5.26969 12.6822 5.32968 12.6473L5.41528 12.5897L5.49368 12.5217L11.9241 6.08974L9.90728 4.07374ZM13.1785 2.81934C12.9269 2.56758 12.5903 2.41897 12.2347 2.40268C11.8792 2.3864 11.5304 2.50363 11.2569 2.73134L11.1609 2.81934L10.4729 3.50734L12.4889 5.52494L13.1785 4.83694C13.4302 4.58537 13.5789 4.24875 13.5951 3.89321C13.6114 3.53767 13.4942 3.18888 13.2665 2.91534L13.1785 2.81934Z"
        fill="#2C1521"/>
  </svg>
</template>

<script>
export default {
  name: "EditIcon"
}
</script>

<style scoped>

</style>