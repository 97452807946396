<template>
  <tr class="man-wrap profile--company" :class="{ rows__single: type === 'rows' }">
    <div class="cards__single" v-if="type === 'cards'">
      <svg class="confirmed confirmed-mark" v-if="typeof id == 'string'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           width="100" height="100" viewBox="0 0 24 24"
           style="fill:#FFFFFF;">
        <path
          d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z"></path>
      </svg>
      <router-link
        :to="'/clients/' + id"
        class="link-not-like photo photo-human">
        <img v-if="photo" :src="$media_url + photo" alt="photo"/>
      </router-link>
      <div class="cards__main-info">
        <h2>
          <router-link class="link-not-like" :to="'/clients/' + id">
            {{ options.array[0].value }}
          </router-link>
        </h2>
        <span class="main-info__description">{{ options.array[1].value }} </span>
      </div>
      <div class="cards__buttons">
        <button v-if="permissions.edit.indexOf('Клиенты') !== -1 && typeof id != 'string'" class="btn-icon btn-action"
                @click="$emit('edit', id)">
          <picture>
            <edit-icon/>
          </picture>
        </button>
        <button v-if="permissions.edit.indexOf('Клиенты') !== -1 && typeof id != 'string'" class="btn-icon btn-action"
                @click="confirm_archive_modal=true">
          <picture>
            <archive-icon/>
          </picture>
        </button>
        <button v-if="permissions.edit.indexOf('Клиенты') !== -1 && (!active || typeof id == 'string')"
                class="btn-icon btn-action"
                @click="confirm_open(id)">
          <picture>
            <delete-icon/>
          </picture>
        </button>
      </div>
      <ul class="cards__info">
        <template v-for="(option, idx) in options.array.slice(2, 7)">
          <li v-if="option.name === 'address'" :key="idx">
            <span v-html="option.icon"></span>
            <a target="_blank"
               :href="'https://www.google.com/maps/search/?api=1&query='+address">
              {{ option.value }}
            </a>
          </li>
          <li v-else :key="idx">
            <span v-html="option.icon"></span>
            <span v-html="option.value"></span>
          </li>
        </template>
      </ul>
    </div>
    <template v-if="type === 'rows'">
      <template v-for="(header) in headers">
        <template v-if="$parent.showHeaders[header.key]">
          <td v-if="header.key==='name'" :key="header.key">
            <router-link :to="'/clients/' + id" class="link-not-like flex">
              <svg class="confirmed confirmed-mark" v-if="typeof id == 'string'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   width="100" height="100" viewBox="0 0 24 24"
                   style="fill:#FFFFFF;">
                <path
                  d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z"></path>
              </svg>
              <div class="avatar avatar--client">
                <img v-if="photo" :src="$media_url + photo" alt=""/>
              </div>
              {{ options[header.key] }}
            </router-link>
          </td>
          <td v-else :key="header.key" v-html="options[header.key] "></td>
        </template>
      </template>
      <td class="rows__dot-menu">
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <svg
                v-bind="attrs"
                v-on="on"
                width="20"
                height="5"
                viewBox="0 0 20 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="10" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="18" cy="2.5" r="2" fill="#2C1521"/>
              </svg>
            </v-btn>
          </template>
          <div class="rows__dot-menu-content">
            <div v-if="permissions.edit.indexOf('Клиенты') !== -1 && typeof id != 'string'" class="btn-wrap" @click="$emit('edit', id)">
              {{ $vuetify.lang.t('$vuetify.edit') }}
              <button class="btn-icon btn-action">
                <picture>
                  <edit-icon/>
                </picture>
              </button>
            </div>
            <div v-if="permissions.edit.indexOf('Клиенты') !== -1 && typeof id != 'string'" class="btn-wrap"
                 @click="confirm_archive_modal=true">
              <span v-if="active">{{ $vuetify.lang.t('$vuetify.toArchive') }}</span>
              <span v-else>{{ $vuetify.lang.t('$vuetify.deleteArchive') }}</span>
              <button class="btn-icon btn-action">
                <picture>
                  <archive-icon/>
                </picture>
              </button>
            </div>
            <div v-if="permissions.edit.indexOf('Клиенты') !== -1 && (!active || typeof id == 'string')" class="btn-wrap"
                 @click="confirm_open(id)">
              {{ $vuetify.lang.t('$vuetify.delete') }}
              <button class="btn-icon btn-action">
                <picture>
                  <delete-icon/>
                </picture>
              </button>
            </div>
          </div>
        </v-menu>
      </td>
    </template>
    <ConfirmModal v-model="confirm_modal" @confirm="tmp_delete">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
    <ConfirmArchiveModal v-model="confirm_archive_modal" @confirm="tmp_archive" :active="active">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>
  </tr>
</template>
<script>
import ConfirmModal from "@/components/confirmModal.vue";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import ArchiveIcon from "@/components/icons/ArchiveIcon";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal";
import {mapGetters} from "vuex";

export default {
  name: "client-card",

  components: {
    ConfirmArchiveModal,
    ArchiveIcon,
    DeleteIcon,
    EditIcon,
    ConfirmModal,
  },

  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: Boolean,
    active: Boolean,
    type: String,
    options: Object,
    headers: Array,
    photo: String,
    id: [Number, String],
    address: String,
  },

  data: function () {
    return {
      the_checked: this.checked,
      tmp: false,

      confirm_modal: false,
      confirm_archive_modal: false,
      tmp_to_delete: "",
      alertMsg: "",
      alertError: false,
    };
  },

  methods: {
    confirm_open(id) {
      this.confirm_modal = true;
      this.tmp_to_delete = id;
    },

    confirm_close() {
      this.confirm_modal = false;
      this.tmp_to_delete = "";
    },

    tmp_delete() {
      if (typeof this.id == 'string') {
        this.$axios
          .delete(this.$hostname + "time-tracking/partner", {
            data: {
              id: this.id.substring(3),
            },
            headers: {
              Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.alertMsg = "Клиент удален";
            this.confirm_modal = false;
            this.$destroy();

            this.$el.parentNode.removeChild(this.$el);
          })
          .catch((response) => {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          });
      } else
        this.$axios
          .delete(this.$hostname + "time-tracking/clients", {
            data: {
              id: this.id,
            },
            headers: {
              Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.alertMsg = "Клиент удален";
            this.confirm_modal = false;
            this.$destroy();

            this.$el.parentNode.removeChild(this.$el);
          })
          .catch((response) => {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          });
    },
    tmp_archive() {
      this.$axios({
        url: this.$hostname + "time-tracking/clients",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.id,
          active: !this.active
        },
      }).then(() => {
        this.confirm_archive_modal = false
        this.$emit('archive')
      }).catch((response) => {
        this.loader = false
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    }
  },

  computed: {
    ...mapGetters(['permissions'])
  }
};
</script>
