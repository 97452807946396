import { render, staticRenderFns } from "./SpinnerLoader.vue?vue&type=template&id=4ef399cc&scoped=true&"
import script from "./SpinnerLoader.vue?vue&type=script&lang=js&"
export * from "./SpinnerLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef399cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VOverlay,VProgressCircular})
