import store from "@/store";

const state = () => ({
    permissions: {
        read: localStorage.getItem('permission_read') ?? [],
        edit: localStorage.getItem('permission_edit') ?? [],
        admin: localStorage.getItem('permission_read') ?? false,
        loaded: localStorage.getItem('permission_loaded') ?? false,
    },

    active: false
})

const getters = {

    permissions: (state) => {
        return state.permissions;
    },

    active: (state) => {
        return state.active
    }
}

const actions = {

    checkPermission({state}, part = null, admin = null) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')) {
                if (!state.permissions.loaded) {
                    this._vm.$axios({
                        url: this._vm.$hostname + "time-tracking/group",
                        type: "GET",
                        headers: {
                            "Authorization": "Token " + (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")),
                        },
                        // eslint-disable-next-line no-unused-vars
                    }).then((response) => {
                        store.dispatch('setPermissions', {
                            read: response.data.data.read,
                            edit: response.data.data.edit,
                            admin: response.data.data.admin,
                            loaded: true
                        }).then(() => {
                            store.dispatch("checkPermission", part, admin).then(res => {
                                console.log('resolved checkPermission')
                                console.log(res)
                                resolve(res)
                            }).catch((err) => {
                                console.log(err)
                                reject()
                            });
                        })

                    }).catch(err => {
                        console.log(err)
                    })
                } else {
                    resolve(state.permissions.read.indexOf(part) === -1)
                    if (part != null && state.permissions.read.indexOf(part) === -1) {
                        this._vm.$router.push({name: "404"})
                        reject()
                    } else if (admin != null) {
                        if (state.permissions.admin)
                            resolve(true)

                        else {
                            this._vm.$router.push({name: "404"})
                            reject()
                        }
                    } else {
                        resolve(true)
                    }
                }
            } else {
                this._vm.$router.push({name: 'AuthLayout'})
                reject()
            }
        })

    },
    setPermissions({commit}, permissions) {
        commit('setPermissions', permissions);
    },

    setActive({commit}, active){
        commit('setActive', active)
    }

}

const mutations = {
    setPermissions(state, permissions) {
        state.permissions = permissions
        localStorage.setItem('permission_read', permissions.read)
        localStorage.setItem('permission_edit', permissions.edit)
        localStorage.setItem('permission_admin', permissions.admin)
        localStorage.setItem('permission_loaded', true)
    },

    setActive(state, active){
        state.active = active
    }

}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}