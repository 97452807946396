<template>
  <div class="page-wrap">
    <div class="page-head">
      <div class="h1">
        {{ $vuetify.lang.t('$vuetify.menu.notifications') }}
      </div>


      <div class="page-head__buttons page-head__buttons-one">
        <button v-if="permissions.edit.indexOf('Оповещения') !== -1" class="btn-secondary btn"
                @click="action='add'; openCreateModal()">
          + {{ $vuetify.lang.t('$vuetify.add') }}
        </button>
      </div>
    </div>

    <div class="faq accordions">
      <div class="accordion cards__single" v-for="(item, idx) in notifications" :key="idx">
        <div class="accordion__title">
          <div class="accordion__title-text h2 flex">
            {{ new Date(item.date).toLocaleDateString('ru-Ru') }}
            {{ new Date(item.date + " " + item.time).toLocaleTimeString('ru-Ru').substr(0, 5) }}
            <div class="useful-item__icon cp"
                 v-if="permissions.admin || (permissions.edit.indexOf('Оповещения') !== -1 && item.author===user)"
                 @click.stop="newNotify = item; openCreateModal()">
              <edit-icon/>
            </div>
            <div class="useful-item__icon cp"
                 v-if="permissions.admin || (permissions.edit.indexOf('Оповещения') !== -1 && item.author===user)"
                 @click.stop="notifyToDelete = item.id; confirmModal = true">
              <delete-icon/>
            </div>
          </div>
        </div>
        <div class="accordion__content">
          <div v-if="translated.indexOf(item.id) === -1" v-html="item.text"></div>
          <div v-else v-html="item.translated"></div>
          <div>
            <span v-if="translated.indexOf(item.id) === -1" class="link cp text-right"
                  @click="translate(item)">
                        {{ $vuetify.lang.t("$vuetify.translate") }}
                      </span>
            <span v-else class="link cp text-right"
                  @click="translated.splice(translated.indexOf(item.id), 1); $forceUpdate()">
                        {{ $vuetify.lang.t("$vuetify.showOriginal") }}
                      </span>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="createModal" :retain-focus="false">
      <div class="modal modal-big">
        <div class="h1">
          <span v-if="action == 'add'"> {{ $vuetify.lang.t('$vuetify.add') }}</span>
          <span v-if="action == 'edit'"> {{ $vuetify.lang.t('$vuetify.edit') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <editor :id="newNotify.id"
                  v-model="newNotify.text"
                  api-key="660vq0feuhjyub7s7o01nh9an48e4eq55ucbneldw55cr22l"
                  :init="$editorInit"
          />

          <v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="newNotify.date ? new Date(newNotify.date).toLocaleDateString('ru-Ru') : ''"
                            :label="$vuetify.lang.t('$vuetify.date')" append-icon="$calendar" v-bind="attrs" v-on="on"
                            outlined readonly :rules="[$rules.required]">
              </v-text-field>
            </template>
            <v-date-picker v-model="newNotify.date" @input="dateMenu = false"
                           no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                           show-adjacent-months></v-date-picker>
          </v-menu>
          <v-menu ref="timeMenu" v-model="timeMenu"
                  :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="newNotify.time"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="newNotify.time"
                            :label="$vuetify.lang.t('$vuetify.time')" readonly v-bind="attrs"
                            v-on="on" outlined :rules="[$rules.required]"></v-text-field>
            </template>
            <v-time-picker v-if="timeMenu" v-model="newNotify.time" full-width
                           format="24hr" :allowed-minutes="allowedMinutes"
                           @click:minute="$refs.timeMenu.save(newNotify.time)"></v-time-picker>
          </v-menu>
          <div class="h2">{{ $vuetify.lang.t('$vuetify.forWhom') }}</div>
          <v-autocomplete v-model="newNotify.options.position" :items="positions"
                          outlined attach clearable item-text="name" item-value="id"
                          :placeholder="$vuetify.lang.t('$vuetify.signup.selectSpec')"
                          :menu-props="{
            bottom: true,
            offsetY: true,
            contentClass: 'select-menu',
          }">
            <template v-slot:selection="{ item }">
              {{
                item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
              }}
            </template>
            <template v-slot:item="{ item }">
              {{
                item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
              }}
            </template>
          </v-autocomplete>
          <v-autocomplete v-model="newNotify.options.skill" attach clearable multiple
                          :label="$vuetify.lang.t('$vuetify.skills')"
                          :items="skills"
                          item-value="id"
                          :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      contentClass: 'select-menu',
                                  }" outlined>
            <template v-slot:selection="{ item }">
              {{
                item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
              }}
            </template>
            <template v-slot:item="{ item }">
              {{
                item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
              }}
            </template>
          </v-autocomplete>

          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="closeCreateModal">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addNotification">
              <span>{{ $vuetify.lang.t('$vuetify.save') }}</span>
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <ConfirmModal v-model="confirmModal" @confirm="deleteNotification">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import ConfirmModal from "@/components/confirmModal";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import SpinnerLoader from "@/components/SpinnerLoader";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NotificationsList",
  components: {editor: Editor, DeleteIcon, EditIcon, ConfirmModal, SpinnerLoader},

  data() {
    return {
      notifications: [],
      user: null,
      newNotify: {
        options: {},
      },
      positions: [],
      skills: [],

      action: 'add',
      createModal: false,
      confirmModal: false,
      notifyToDelete: null,

      dateMenu: false,
      timeMenu: false,

      translated: [],

      loader: false,
      alertError: false,
      alertMsg: "",
    }
  },
  created: function () {
    this.checkPermission('Оповещения').then(()=>{

      this.loadData();
      if (this.permissions.edit.indexOf("Оповещения") !== -1) {
        this.loadSkills()
        this.loadPositions()
      }
    })
  },

  methods: {
    ...mapActions(['checkPermission']),
    allowedMinutes: v => v % 5 == 0,

    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/notification",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        this.notifications = response.data.data.data
        this.user = response.data.data.user
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      }).finally(() => {
        this.loader = false
      })
    },

    loadPositions() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/positions",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.positions = response.data.data.data;
        })
        .catch((error) => {
          this.alertMsg = this.$catch(error)
        });
    },
    loadSkills() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/skills",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.skills = response.data.data.data;
        })
        .catch((error) => {
          this.alertMsg = this.$catch(error)
        });
    },

    addNotification() {
      if (this.$refs.form.validate()) {
        this.newNotify.options = JSON.stringify(this.newNotify.options)
        this.$axios({
          method: this.newNotify.id ? "PUT" : "POST",
          url: this.$hostname + "time-tracking/notification",
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          },
          data: this.newNotify,
        }).then(() => {
          this.loadData()
          this.closeCreateModal()
        }).catch((response) => {
          this.alertMsg = this.$catch(response)
          this.alertError = true
          this.newNotify.options = JSON.parse(this.newNotify.options)
        });
      } else {
        this.alertError = true;
        this.alertMsg = this.$vuetify.lang.t("$vuetify.fillForm")
      }
    },
    deleteNotification() {
      this.loader = true
      this.$axios({
        method: "DELETE",
        url: this.$hostname + "time-tracking/notification",
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
        },
        data: {id: this.notifyToDelete},
      }).then(() => {
        this.loadData()
        this.confirmModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      }).finally(() => {
        this.loader = false
      })
    },

    openCreateModal() {
      this.$forceUpdate()
      if (!this.newNotify.id) {
        this.newNotify.date = new Date().toISOString().substr(0, 10)
        this.newNotify.time = new Date().toLocaleTimeString().substr(0, 5)
      }
      this.createModal = true
    },
    closeCreateModal() {
      this.createModal = false
      this.newNotify = {}
      this.newNotify.text = ""
      this.newNotify.options = {}
      this.alertError = false
      this.$forceUpdate()
    },

    translate: function (item) {
      this.$axios({
        url: "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCcA3--66Nt9HiqrZeamki4_ZcG1KeJOL0",
        method: "POST",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          q: item.text,
          target: this.$vuetify.lang.current === "est" ? "et" : this.$vuetify.lang.current.substr(0, 2),
          format: "html"
        }
      })
        .then((response) => {
          item.translated = response.data.data.translations[0].translatedText
          this.translated.push(item.id)
          this.$forceUpdate()
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },
  },

  computed: {
    ...mapGetters(['permissions'])
  }
}
</script>

<style scoped>

</style>