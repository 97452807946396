<template>
  <div class="dgantt__view-list-item"
       :style="{ 'margin-left': calculateLeftPosition(task) + 'px', width: getTaskLength(task)+'px', background: task.color+ (getSubTasks(task.id).length? '60':'00') }"

  >

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <div class="dgantt__view-click-zone"
             :style="{width: getTaskLength(task)-12+'px'}"
             @click="openTask(task)">
        <v-tooltip right v-if="permission">
          <template v-slot:activator="{ on, attrs }">

            <div class="dgantt__view-task-add" @click.stop="openSubTask(task.id)"
                 v-bind="attrs"
                 v-on="on"
            >
              +
            </div>
          </template>
          <span>Добавить подзадачу</span>
        </v-tooltip>
        </div>
        <div class="dgantt__view-task-late"
             :style="{ width: getTaskLateLength(task)+'px', 'margin-left' : getTaskLength(task) - getTaskLateLength(task)  + 'px'}"
        ></div>

        <div class="dgantt__view-title" :style="{ 'left': calculateLeftPosition(task) + 'px', width: getTaskLength(task)+'px',}">
          {{ taskWorkers }}
        </div>
        <div class="dgantt__view-task" :class="{'holiday-work' : task.holidays_work}" :style="{background: task.color}"
             v-bind="attrs"
             v-on="on"
        >

        </div>
      </template>
      <span>{{ workType }}</span>
    </v-tooltip>
    <div class="dgantt__view-list" v-if="!isHidden && getSubTasks(task.id).length">
      <DGanttViewTask v-for="taskItem in getSubTasks(task.id)" :key="taskItem.id" :task="taskItem" :tasks="tasks"
                      :permission="permission"
                      v-on:openSubTask="openSubTask($event)"
                      v-on:openTask="openTask($event)">

      </DGanttViewTask>
    </div>

  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: "DGanttViewTask",

  props: ['tasks', 'task', 'permission'],

  methods: {
    openSubTask(task_id) {
      console.log('open sub task: ' + task_id)

      this.$emit('openSubTask', task_id)

    },

    openTask(task) {
      this.$emit('openTask', task)
    },

    getParentTask(task) {
      return this.tasks.find(t => t.id == task.parent_task)
    },

    getSubTasks(id = null) {
      return this.tasks.filter(t => t.parent_task == id)
    },

    info(t) {

      const minDate = new Date(t.parent_task ? this.getParentTask(t).date_start : this.minDate);
      return {
        minDate
      }
    },

    calculateLeftPosition(t) {
      const minDate = new Date(t.parent_task ? this.getParentTask(t).date_start : this.minDate);
      const currentDate = new Date(t.date_start);
      const differenceInDays = (currentDate - minDate) / (1000 * 60 * 60 * 24);
      return differenceInDays * this.scale; // предполагаем, что 1 день равен 25px
    },

    getTaskLength(task) {
      return this.scale * ((new Date(task.date_end_real) - new Date(task.date_start)) / (1000 * 60 * 60 * 24) + 1);
    },

    getTaskLateLength(task) {
      return this.scale * ((new Date(task.date_end_real) - new Date(task.date_end)) / (1000 * 60 * 60 * 24) );
    }
  },

  computed: {
    ...mapGetters(['getWorkers', 'scale', "hiddenTasks", 'workTypes']),

    workType(){
      if (!this.workTypes.length) return ''
      return this.workTypes.find(el=> el.id == this.task.work_type).name[this.$vuetify.lang.current]
    },

    isHidden() {
      return this.hiddenTasks.includes(this.task.id)
    },
    taskWorkers() {
      return this.getWorkers.filter(w => this.task.user_profiles.includes(w.id)).map(w => w.name + ' ' + w.lastname).join(', ')
    },

    minDate() {
      return this.tasks.reduce((min, task) => {
        return task.date_start < min ? task.date_start : min;
      }, this.tasks[0].date_start);
    },

    maxDate() {
      return this.tasks.reduce((max, task) => {
        return task.date_end > max ? task.date_end : max;
      }, this.tasks[0].date_end);
    },
  }
}
</script>
