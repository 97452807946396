import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from "/languages/en";
import eng from "/languages/en";
import rus from "/languages/ru";
import est from "/languages/est";
import fin from "/languages/fin";
import hy from "/languages/hy";
import az from "/languages/az";
import be from "/languages/be";
import bg from "/languages/bg";
import cs from "/languages/cs";
import da from "/languages/da";
import de from "/languages/de";
import el from "/languages/el";
import es from "/languages/es";
import fr from "/languages/fr";
import he from "/languages/he";
import it from "/languages/it";
import kk from "/languages/kk";
import lt from "/languages/lt";
import lv from "/languages/lv";
import no from "/languages/no";
import pl from "/languages/pl";
import pt from "/languages/pt";
import ro from "/languages/ro";
import sv from "/languages/sv";
import tg from "/languages/tg";
import tr from "/languages/tr";
import uk from "/languages/uk";
import DocumentIcon from "@/components/icons/DocumentIcon";
import PhotoIcon from "@/components/icons/PhotoIcon";
import CalendarIcon from "@/components/icons/CalendarIcon";
import SearchIcon from "@/components/icons/SearchIcon";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            az,
            be,
            bg,
            cs,
            da,
            de,
            el,
            eng,
            en,
            es,
            est,
            fin,
            fr,
            he,
            hy,
            it,
            kk,
            lt,
            lv,
            no,
            pl,
            pt,
            ro,
            rus,
            sv,
            tg,
            tr,
            uk
        },
        current: 'eng',
        defaultLocale: 'en'
    },
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                primary: '#557EAE',
                secondary: '#E1EBF8',
                background: '#000000',
                text: {
                    primary: '#E1EBF8',
                    secondary: '#557EAE'
                }
            },
            dark: {
                primary: '#2A3C52',
                secondary: '#344D69',
                text: {
                    primary: '#FFFFFF'
                }
            },
            red: {
                primary: '#ca5e70',
                secondary: '#693434',
                text: {
                    primary: '#FFFFFF'
                }
            }
        },
    },
    icons: {
        values: {
            calendar: {
                component: CalendarIcon
            },
            document: {
                component: DocumentIcon
            },
            photo: {
                component: PhotoIcon
            },
            search: {
                component: SearchIcon
            },
        }
    }
});
