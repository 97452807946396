<template>
  <div class="page-wrap">
    <div class="respond">
      <div class="respond__head">
        <div class="respond__side">
          <div class="respond__side-title">
            <router-link :to="{name: 'workers-item', params: {id: respond.auth_user.userprofile.id}}">
              {{ respond.auth_user.userprofile.name }}
              {{ respond.auth_user.userprofile.lastname }}
            </router-link>
          </div>
          <div class="respond__side-subtitle">
            <router-link v-if="respond.resume" :to="{name: 'CV', params: {id: respond.resume.id}}">
              {{ respond.resume.name }}
            </router-link>
            <span v-else>
              {{ $vuetify.lang.t('$vuetify.hh.noCv') }}
            </span>
          </div>
        </div>

        <div class="respond__side">
          <div class="respond__side-title">
            <router-link :to="{name: 'CompanyProfile', params: {id: respond.organization.id}}">
              {{ respond.organization.name }}
            </router-link>
          </div>
          <div class="respond__side-subtitle">
            <router-link v-if="respond.vacancy" :to="{name: 'Vacancy', params: {id: respond.vacancy.id}}">
              {{ respond.vacancy.name }}
            </router-link>
            <span v-else>
              {{ $vuetify.lang.t('$vuetify.noVacancy') }}
            </span>
          </div>
        </div>
      </div>
      <accordion-component :closed="closed" :card="true">
        <template v-slot:title>
          {{ $vuetify.lang.t('$vuetify.hh.cover_letter') }}
        </template>
        <template v-slot:content>
          <div v-html="respond.cover_letter"></div>
        </template>
      </accordion-component>
      <div class="respond__chat">
        <h2>
          {{ $vuetify.lang.t('$vuetify.chat') }}
        </h2>
        <comment-list :table="'ResumeVacancy'" :id="$route.params.id" :show-nothing="false"
                      :hide-edit="true" :hide-reply="true"></comment-list>
      </div>

      <div class="respond__footer">
        <div class="respond__accept-side">
          <div class="respond__accept-side-text">
            <span v-if="side=='worker' && respond.decline_worker">
              <!-- Вы отклонили вакансию -->
              {{ $vuetify.lang.t('$vuetify.hh.has_declined_worker_by_worker') }}
            </span>
            <span v-else-if="side!='worker' && respond.decline_worker">
              <!-- Кандидат отконил Вашу вакансию -->
              {{ $vuetify.lang.t('$vuetify.hh.has_declined_worker_by_company') }}
            </span>
            <span v-else-if="side=='worker' && respond.approve_worker">
              <!-- Вы согласны выйти на работу -->
              {{ $vuetify.lang.t('$vuetify.hh.has_approved_worker_by_worker') }}
            </span>
            <!--            <span v-else-if="side=='worker' && !respond.approve_worker">-->
            <!--              {{ $vuetify.lang.t('$vuetify.hh.has_not_approved_worker_by_worker') }}-->
            <!--            </span>-->
            <span v-else-if="side!='worker' && respond.approve_worker">
              <!-- Кандидат согласен выйти на работу -->
              {{ $vuetify.lang.t('$vuetify.hh.has_approved_worker_by_company') }}
            </span>
            <span v-else-if="side!='worker'">
              <!-- Кандидат еще не принял решение -->
              {{ $vuetify.lang.t('$vuetify.hh.has_not_approved_worker_by_company') }}
            </span>
          </div>
          <template v-if="side=='worker' && !respond.decline_organization && !respond.decline_worker">
            <div class="btn-group">
              <button class="btn btn-primary" v-if="!respond.approve_worker" @click="approve">
                {{ $vuetify.lang.t('$vuetify.hh.approve') }}
              </button>
              <button class="btn btn-secondary" v-if="respond.status == 'pending'" @click="decline">
                {{ $vuetify.lang.t('$vuetify.hh.decline') }}
              </button>
            </div>
          </template>
        </div>
        <div class="respond__accept-side">
          <div class="respond__accept-side-text">
            <span v-if="side=='organization' && respond.decline_organization">
              <!-- Вы отклонили резюме -->
              {{ $vuetify.lang.t('$vuetify.hh.has_declined_organization_by_organization') }}
            </span>
            <span v-else-if="side!='organization' && respond.decline_organization">
              <!-- Вашу кадидатуру отклонила компания -->
              {{ $vuetify.lang.t('$vuetify.hh.has_declined_organization_by_worker') }}
            </span>
            <span v-else-if="side=='organization' && respond.approve_organization">
              <!-- Вы согласились принять кандидата на работу -->
              {{ $vuetify.lang.t('$vuetify.hh.has_approved_organization_by_organization') }}
            </span>
            <!--            <span v-else-if="side=='organization' && !respond.approve_organization">-->
            <!--              &lt;!&ndash;  &ndash;&gt;-->
            <!--              {{ $vuetify.lang.t('$vuetify.hh.has_not_approved_organization_by_organization') }}-->
            <!--            </span>-->
            <span v-else-if="side!='organization' && respond.approve_organization">
              <!-- Вас согласилиь принять на работу -->
              {{ $vuetify.lang.t('$vuetify.hh.has_approved_organization_by_worker') }}
            </span>
            <span v-else-if="side!='organization' && !respond.approve_organization">
              <!-- Компания еще рассматривает ваше резюме -->
              {{ $vuetify.lang.t('$vuetify.hh.has_not_approved_organization_by_worker') }}
            </span>
          </div>
          <template v-if="side=='organization' && !respond.decline_organization && !respond.decline_worker">
            <div class="btn-group">
              <button class="btn btn-primary" v-if="!respond.approve_organization" @click="approve">
                {{ $vuetify.lang.t('$vuetify.hh.approve') }}
              </button>
              <button class="btn btn-secondary" v-if="respond.status == 'pending'" @click="decline">
                {{ $vuetify.lang.t('$vuetify.hh.decline') }}
              </button>
            </div>
          </template>
        </div>
      </div>
      <div class="respond__finish">
        <div class="respond__finish-item error " v-if="respond.status == 'rejected'">
          {{ $vuetify.lang.t("$vuetify.hh.declined") }}
        </div>
        <div class="respond__finish-item success" v-if="respond.status == 'approved' && side == 'worker'">
          {{ $vuetify.lang.t("$vuetify.hh.approved_for_worker") }}
        </div>
        <div class="respond__finish-item success"
             v-if="respond.status == 'approved' && side == 'organization'">
          {{ $vuetify.lang.t("$vuetify.hh.approved_for_organization") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentList from "@/components/icons/CommentList.vue";
import AccordionComponent from "@/components/AccordionComponent.vue";

export default {
  name: "RespondView",
  components: {
    AccordionComponent,
    CommentList
  },

  mounted() {
    if (this.$route.path.indexOf('organization') !== -1) {
      this.side = 'organization'
      this.url = 'time-tracking/vacancy-resume'
    } else {
      this.side = 'worker'
      this.url = 'time-tracking/resume-vacancies'
    }
    this.loadData()
  },

  data: function () {
    return {
      respond: {},
      closed: true,
      side: '',
      url: '',
    }
  },

  methods: {
    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + this.url + '/' + this.$route.params.id,
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.respond = response.data.data.data
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },

    approve() {
      let status = this.respond.status
      if ((this.side == 'worker' && this.respond.approve_organization) || (this.side == 'organization' && this.respond.approve_worker)) {
        status = 'approved'
      }
      this.$axios({
        url: this.$hostname + this.url,
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.respond.id,
          approve_worker: this.side == 'worker' ? true : this.respond.approve_worker,
          approve_organization: this.side == 'organization' ? true : this.respond.approve_organization,
          status: status,
        },
      }).then((response) => {
        this.respond = response.data.data.data
        this.loadData()
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      })
    },

    decline() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.respond.id,
          approve_worker: this.side == 'worker' ? false : this.respond.approve_worker,
          approve_organization: this.side == 'organization' ? false : this.respond.approve_organization,
          decline_worker: this.side == 'worker' ? true : this.respond.decline_worker,
          decline_organization: this.side == 'organization' ? true : this.respond.decline_organization,
          status: "rejected",
        },
      }).then((response) => {
        this.respond = response.data.data.data
        this.loadData()
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      })
    },

  },
}
</script>