<template>
  <v-dialog v-model="contact_create_modal">
    <div class="modal">
      <div class="h1">
        <span v-if="contact_action === 'add'">{{ $vuetify.lang.t('$vuetify.addContact') }}</span>
        <span v-if="contact_action === 'edit'">{{ $vuetify.lang.t('$vuetify.editContact') }}</span>
      </div>
      <div class="h2">{{ $vuetify.lang.t('$vuetify.mainInfo') }}</div>
      <v-form ref="form" @submit.prevent="">
        <v-text-field v-model="newContact.name" :label="$vuetify.lang.t('$vuetify.name')" outlined
                      :rules="[$rules.required]"></v-text-field>
        <v-text-field v-model="newContact.lastname" :label="$vuetify.lang.t('$vuetify.surname')" outlined
                      :rules="[$rules.required]"></v-text-field>
        <v-autocomplete v-model="newContact.position" class="custom" :label="$vuetify.lang.t('$vuetify.position')"
                        :items="selectsPosition" item-text="name" item-value="id" outlined attach
                        :menu-props="{
              bottom: true,
              offsetY: true,
              maxHeight: width<1024 ? 200 : 800,
              contentClass: 'select-menu',
            }" :rules="[$rules.required]">
          <template v-slot:selection="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
          <template v-slot:item="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
        </v-autocomplete>
        <v-autocomplete v-model="newContact.client" v-if="$parent.$parent.type!=='client'" class="custom"
                        :label="$vuetify.lang.t('$vuetify.company')"
                        :items="clients" item-text="name" item-value="id" outlined attach
                        :menu-props="{
              bottom: true,
              offsetY: true,
              maxHeight:  width<1024 ? 200 : 800,
              contentClass: 'select-menu',
            }" :rules="[$rules.required]"></v-autocomplete>
        <v-file-input class="file-input" v-model="photo" accept=".jpg, .png, .jpeg"
                      :placeholder="$vuetify.lang.t('$vuetify.uploadImg')" prepend-icon="$photo"></v-file-input>

        <div class="h2">{{ $vuetify.lang.t('$vuetify.menu.contacts') }}</div>
        <v-text-field v-model="newContact.phone" :label="$vuetify.lang.t('$vuetify.phone')" outlined
                      :rules="[$rules.required]"></v-text-field>
        <v-text-field v-model="newContact.email" label="Email" outlined :rules="[$rules.required, $rules.email, $rules.notSpaces]">
        </v-text-field>

        <v-progress-linear :class="{form__progress : form_loader}"
                           :active="form_loader"
                           indeterminate
                           rounded
                           height="6"
        ></v-progress-linear>

        <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="contact_create_modal=false">
            {{ $vuetify.lang.t('$vuetify.cancel') }}
          </button>
          <button class="btn btn-primary" @click="uploadFile">
            {{ $vuetify.lang.t('$vuetify.save') }}
          </button>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ContactCreateModal",
  props: {
    value: {
      type: Boolean,
    },
    client: [Number, undefined],
    contact: Object,
    contact_action: String,
  },

  data: function () {
    return {
      contact_create_modal: this.value,
      clients: [],
      selectsPosition: [],
      photo: null,

      newContact: this.contact,

      alertError: false,
      alertMsg: "",
      form_loader: false,

      width: screen.width,
    };
  },

  created() {
    this.loadPositions()
    if (this.$parent.$parent.type !== 'client')
      this.loadClients()
  },

  watch: {
    value() {
      this.contact_create_modal = this.value;
    },
    contact() {
      this.newContact = this.contact
      this.$refs.form.resetValidation()
    },
    contact_create_modal() {
      this.$emit("input", this.contact_create_modal);
    },
  },

  methods: {

    loadClients() {
      this.$axios({
        url: this.$hostname + "time-tracking/clients",
        method: "get",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
        params: {
          arch: true,
        }
      })
          .then((response) => {
            if (response.data.data.data) {
              this.clients = [];
              response.data.data.data.forEach((element) => {
                this.clients.push({
                  id: element.id,
                  name: element.name,
                });
              });
            }
          })
          .catch((response) => {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          });
    },

    loadPositions() {
      this.$axios({
        url: this.$hostname + "time-tracking/clients-employees/positions",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      })
          .then((response) => {
            this.selectsPosition = response.data.data.data;
          })
          .catch((response) => {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          });
    },

    submitContact() {
      if (this.contact_action === "add") {
        this.addContact();
      } else {
        this.editContact();
      }
    },

    addContact() {
      this.newContact.organization = JSON.parse(localStorage.getItem('organization')).id
      this.$axios
          .post(
              this.$hostname + "time-tracking/clients-employees",
              this.newContact,
              {
                headers: {
                  Authorization:
                      "Token " +
                      (localStorage.getItem("auth_token") ||
                          sessionStorage.getItem("auth_token")),
                  "Content-Type": "multipart/form-data",
                },
              }
          ).then(() => {
        this.contact_create_modal = false;
        this.$emit("save")
      }).catch((response) => {
        this.alertMsg = this.$catch(response);
        this.alertError = true;
      }).then(() => {
        this.form_loader = false
      })
    },

    editContact() {
      this.$axios
          .put(
              this.$hostname + "time-tracking/clients-employees",
              this.newContact,
              {
                headers: {
                  Authorization:
                      "Token " +
                      (localStorage.getItem("auth_token") ||
                          sessionStorage.getItem("auth_token")),
                  "Content-Type": "multipart/form-data",
                },
              }
          ).then(() => {
        this.contact_create_modal = false;
        this.$emit("save")
      }).catch((response) => {
        this.alertMsg = this.$catch(response);
        this.alertError = true;
      }).then(() => {
        this.form_loader = false
      })
    },

    uploadFile() {
      if (this.$refs.form.validate()) {
        this.form_loader = true
        if (this.photo != null) {
          this.$axios({
            url: this.$hostname + "time-tracking/images/upload/contacts",
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: {
              file: this.photo,
            },
          })
              .then((response) => {
                this.newContact.photo_path = response.data.data;
                this.submitContact();
              })
              .catch((error) => {
                alert("Ошибка загрузки файла: " + error.message);
              });
        } else {
          this.submitContact();
        }
      } else {
        this.alertMsg = "Заполните все необходимые данные";
        this.alertError = true;
      }
    },
  },
}
</script>

<style scoped>

</style>