<template>
  <div class="page-wrapper">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.settings') }}</h1>
    </div>

    <div class="settings-admin">
      <router-link to="/settings/company">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.companyData') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/groups">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.groups') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/worker-position">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.workerPosition') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/skills">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.skills') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/work-types">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.workTypes') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/cards">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.cards') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/contact-position">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.contactPosition') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/cheque-categories">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.chequeCategories') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/reports-categories">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.reportsCategories') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/goals">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.goals') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/terms">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.terms') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/banks">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.banks') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/tax">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.tax') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/absence-reasons">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.reason') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/trip-time">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.tripTime') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/holidays">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.holidays') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/languages">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.language-settings') }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SettingsList",

  data() {
    return {
    }
  },

  mounted() {
    console.log('setting list')

    console.log(this.permissions)
    if (!this.permissions.admin) {
      this.$router.push({name: '404'})
    }
  },

  methods: {},

  computed: {
    ...mapGetters(['permissions'])
  }
}
</script>

<style scoped>

</style>