<template>
  <div class="flex flex-nowrap mb-3">
    <div>
      <form class="flex align-baseline" @submit.prevent="">
        <v-text-field v-model="residence_address" :label="label"
                      outlined></v-text-field>
        <button class="btn-action btn-icon" @click="getCoordinates">
          <search-icon></search-icon>
        </button>
      </form>
<!--      <div>{{ latitude }} {{ longitude }}</div>-->
      <div v-if="residence_address != address">{{ address }}</div>

    </div>
    <iframe v-if="address" loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            style="max-width: 50%;"
            :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyCLXQMTRi0e4wGAYd69wt8BFZgpv3YaO5Q&q='+ address.replaceAll(' ', '+')"
    ></iframe>
  </div>
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";

export default {
  name: "AddressMapComponent",
  components: {SearchIcon},
  props: {
    value: {
      type: String
    },
    label: String
  },

  data: function () {
    return {
      residence_address: this.value,
      address: this.value,
      longitude: '',
      latitude: '',
    }
  },

  methods: {
    getCoordinates() {
      this.$axios({
        url: this.$hostname + "time-tracking/get-coordinates",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          address: this.residence_address,
        },
      }).then((response) => {
        this.latitude = response.data.data.lat
        this.longitude = response.data.data.lng
        this.getAddress();
      }).catch((error) => {
        // this.alertMsg = this.$catch(error);
        alert(this.$catch(error));
      });
    },
    getAddress() {
      this.$axios({
        url: this.$hostname + "time-tracking/get-address",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          lat: this.latitude,
          lng: this.longitude,
        },
      }).then((response) => {
        this.address = response.data.data.address
        this.$forceUpdate()
      }).catch((error) => {
        // this.alertMsg = this.$catch(error);
        alert(this.$catch(error));
      });
    },

  },


  watch: {
    value() {
      this.address = this.value;
    },
    address() {
      this.$emit("input", this.address);
    },
    longitude() {
      this.$emit("longitude", this.longitude);
    },
    latitude() {
      this.$emit("latitude", this.latitude);
    },
  },
}
</script>

<style scoped>

</style>