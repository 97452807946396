<template>
  <div class="page-wrapper">
    <div class="page-head">

      <div class="breadcrumbs">
        <h1>{{ $vuetify.lang.t('$vuetify.menu.safe') }}</h1>
      </div>

      <button class="btn-secondary btn"
              @click="openPasswordForm('edit')">
        {{ $vuetify.lang.t('$vuetify.updatePassword') }}
      </button>
    </div>
    <div class="main">
      <div class="discount-cards-wrapper">
        <div class="page-head">

          <h2>{{ $vuetify.lang.t('$vuetify.discountCards') }}</h2>

          <div class="page-head__buttons">
            <button class="btn-secondary btn" @click="arch = !arch; page=1">
              <span v-if="!arch">{{ $vuetify.lang.t('$vuetify.archive') }}</span>
              <span v-else>{{ $vuetify.lang.t('$vuetify.outArchive') }}</span>
            </button>
            <button class="btn btn-primary" @click="cardCreateModal = true; action='add'">
              {{ $vuetify.lang.t('$vuetify.add') }}
            </button>
          </div>
        </div>
        <table class="main-table">
          <tbody class="cards">
          <DiscountCard v-for="card in discountCards" :key="card.id" :card="card" :type="'cards'"
                        @edit="
                  (id) => {
                    // action = 'edit';
                    cardEditOpen(card);
                  }"
                        @archive=" ()=> {loadDiscountCards()}"
                        @password="openPasswordForm"
          ></DiscountCard>
          </tbody>
        </table>
      </div>
      <div class="cards widgets main__widgets">
        <div
          v-for="(widget, i) in widgetsUsers"
          :key="widget.id"
          class="cards__single widgets__single note"
        >
          <div class="widgets__header">
            <h1 v-if="!widget.settings.name">
              {{ $vuetify.lang.t("$vuetify.widgets." + widget.widget.type + ".label") }}
              <span v-if="widget.widget.type === 'note'">{{ i + 1 }}</span>
            </h1>
            <h1 v-else>{{ widget.settings.name }}</h1>
            <div class="header__buttons">
              <button class="btn-icon"
                      @click="
                newWidget = widget;
                addWidgetUserForm = true;
              "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8934 3.38037C16.5059 2.769 17.3317 2.41924 18.1969 2.40476C19.0622 2.39029 19.8993 2.71224 20.5318 3.30278C21.1644 3.89333 21.543 4.70635 21.5879 5.57054C21.6328 6.43474 21.3405 7.28263 20.7726 7.93557L20.6166 8.10357L9.08941 19.6308C8.8437 19.8761 8.5477 20.0653 8.22181 20.1852L8.02381 20.2488L3.15781 21.576C3.06355 21.6019 2.96438 21.6045 2.86891 21.5834C2.77345 21.5623 2.68458 21.5182 2.61006 21.4549C2.53553 21.3916 2.4776 21.311 2.44131 21.2203C2.40502 21.1295 2.39146 21.0312 2.40181 20.934L2.42101 20.838L3.74821 15.9732C3.83941 15.6372 4.00261 15.3252 4.22581 15.0612L4.36621 14.9076L15.8934 3.38037ZM14.8614 6.11037L5.21461 15.756C5.10439 15.8664 5.01676 15.9972 4.95661 16.1412L4.90621 16.2888L3.85381 20.1408L7.70821 19.0908C7.80861 19.0634 7.90502 19.023 7.99501 18.9708L8.12341 18.8844L8.24101 18.7824L17.8866 9.13437L14.8614 6.11037ZM19.7682 4.22877C19.3908 3.85113 18.8859 3.6282 18.3526 3.60378C17.8193 3.57936 17.2961 3.7552 16.8858 4.09677L16.7418 4.22877L15.7098 5.26077L18.7338 8.28717L19.7682 7.25517C20.1459 6.8778 20.3688 6.37289 20.3932 5.83957C20.4176 5.30626 20.2418 4.78308 19.9002 4.37277L19.7682 4.22877Z"
                    fill="#ADB2B7"
                  />
                </svg>
              </button>
              <button class="btn-icon" @click="openWidgetConfirm(widget.id)">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.25 5.99976C17.25 5.40302 17.0129 4.83072 16.591 4.40877C16.169 3.98681 15.5967 3.74976 15 3.74976C14.4033 3.74976 13.831 3.98681 13.409 4.40877C12.9871 4.83072 12.75 5.40302 12.75 5.99976H11.25C11.25 5.00519 11.6451 4.05137 12.3483 3.34811C13.0516 2.64484 14.0054 2.24976 15 2.24976C15.9946 2.24976 16.9484 2.64484 17.6517 3.34811C18.3549 4.05137 18.75 5.00519 18.75 5.99976H25.5C25.6989 5.99976 25.8897 6.07877 26.0303 6.21943C26.171 6.36008 26.25 6.55084 26.25 6.74976C26.25 6.94867 26.171 7.13943 26.0303 7.28009C25.8897 7.42074 25.6989 7.49976 25.5 7.49976H24.669L22.725 24.3448C22.6404 25.0758 22.2899 25.7503 21.7404 26.2399C21.1908 26.7294 20.4805 26.9999 19.7445 26.9998H10.2555C9.51952 26.9999 8.80918 26.7294 8.25963 26.2399C7.71007 25.7503 7.35964 25.0758 7.275 24.3448L5.3295 7.49976H4.5C4.32446 7.49981 4.15446 7.4383 4.01961 7.32592C3.88476 7.21354 3.7936 7.05743 3.762 6.88476L3.75 6.74976C3.75 6.55084 3.82902 6.36008 3.96967 6.21943C4.11032 6.07877 4.30109 5.99976 4.5 5.99976H17.25ZM23.157 7.49976H6.8415L8.7645 24.1723C8.80683 24.5379 8.98216 24.8753 9.25709 25.1201C9.53203 25.3648 9.88738 25.5 10.2555 25.4998H19.7445C20.1124 25.4996 20.4673 25.3643 20.742 25.1196C21.0166 24.8748 21.1917 24.5377 21.234 24.1723L23.157 7.49976ZM12.75 11.2498C13.1175 11.2498 13.425 11.4823 13.488 11.7883L13.5 11.9068V21.0943C13.5 21.4558 13.164 21.7498 12.75 21.7498C12.3825 21.7498 12.075 21.5173 12.012 21.2113L12 21.0928V11.9083C12 11.5453 12.336 11.2513 12.75 11.2513V11.2498ZM17.25 11.2498C17.6175 11.2498 17.925 11.4823 17.988 11.7883L18 11.9068V21.0943C18 21.4558 17.664 21.7498 17.25 21.7498C16.8825 21.7498 16.575 21.5173 16.512 21.2113L16.5 21.0928V11.9083C16.5 11.5453 16.836 11.2513 17.25 11.2513V11.2498Z"
                    fill="#557EAE"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            v-if="widget.widget.type === 'note'"
            :style="'background-color:' + widget.settings.color"
            class="note__content"
          >
            <pre>{{ widget.settings.text }}</pre>
          </div>
        </div>
        <div class="cards__single widgets__single widgets__new"
             @click="addWidgetForm = true">
          <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="68" cy="68" r="67" stroke="#557EAE" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" stroke-dasharray="15 20"/>
            <path d="M68 41.5V89.5M92 65.5L44 65.5" stroke="#557EAE" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <div class="new__title">{{ $vuetify.lang.t('$vuetify.widgets.new') }}</div>
        </div>
      </div>

      <v-dialog max-width="500px" v-model="passwordForm" persistent>
        <div class="modal modal-big">
          <div class="h1">{{ $vuetify.lang.t('$vuetify.safePassword') }}</div>
          <v-form ref="auth" lazy-validation @submit.prevent="">
            <v-text-field
              v-model="password.password"
              :label="$vuetify.lang.t('$vuetify.password')"
              outlined
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[$rules.required, $rules.min, $rules.notSpaces]"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            ></v-text-field>
            <v-text-field v-if="['create', 'edit'].includes(passwordFormType)"
                          v-model="password.re_password"
                          :label="$vuetify.lang.t('$vuetify.repeatPassword')"
                          outlined
                          :rules="[$rules.required, $rules.min, $rules.notSpaces, same_password]"
                          type="password"
            ></v-text-field>
            <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
            <div class="btn-group">
              <div v-if="passwordFormType === 'edit'"
                      class="btn btn-secondary"
                      @click="closePasswordForm"
              >
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </div>
              <div v-if="!['edit'].includes(passwordFormType)"
                      class="btn btn-secondary"
                      @click="$router.go(-1)"
              >
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </div>
              <button class="btn btn-primary"
                      @click="['create', 'edit'].includes(passwordFormType) ? addPassword() : getToken()">
                <template v-if="passwordFormType === 'create'">
                  {{ $vuetify.lang.t('$vuetify.save') }}
                </template>
                <template v-else-if="passwordFormType === 'edit'">
                  {{ $vuetify.lang.t('$vuetify.edit') }}
                </template>
                <template v-else>
                  {{ $vuetify.lang.t('$vuetify.enter') }}
                </template>
              </button>
            </div>
          </v-form>
        </div>
      </v-dialog>

      <v-dialog max-width="500px" v-model="addWidgetForm">
        <div class="modal">
          <div class="h1">{{ $vuetify.lang.t("$vuetify.widgets.selectWidget") }}</div>
          <div class="widgets__select"
               v-for="item in widgets"
               :key="item.id"
               @click="openAddWidgetUser(item)"
          >
            {{ $vuetify.lang.t("$vuetify.widgets." + item.type + ".label") }}
          </div>
        </div>
      </v-dialog>
      <v-dialog max-width="500px" v-model="addWidgetUserForm">
        <div class="modal modal-big">
          <div class="h1">{{ $vuetify.lang.t('$vuetify.widgets.note.label') }}</div>
          <v-form ref="form" @submit.prevent="">
            <v-text-field v-model="newWidget.settings.name" :label="$vuetify.lang.t('$vuetify.name2')"
                          outlined></v-text-field>
            <v-textarea
              v-if="newWidget.settings.text !== undefined"
              v-model="newWidget.settings.text"
              filled
              :background-color="newWidget.settings.color"
              outlined
            ></v-textarea>

            <v-menu
              ref="menuColor"
              v-model="menuColor"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newWidget.settings.color"
                  :label="$vuetify.lang.t('$vuetify.color')"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  readonly
                ></v-text-field>
              </template>
              <v-color-picker
                v-if="newWidget.settings.color !== undefined"
                v-model="newWidget.settings.color"
              ></v-color-picker>
            </v-menu>
            <div class="error--text" v-if="alertError">{{ alertMsg }}</div>

            <div class="btn-group">
              <button
                class="btn btn-secondary"
                @click="
                addWidgetUserForm = false;
                addWidgetForm = false;
              "
              >
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click="addWidgetUser">
                {{ $vuetify.lang.t('$vuetify.save') }}
              </button>
            </div>
          </v-form>
        </div>
      </v-dialog>
      <ConfirmModal v-model="confirmModal" @confirm="deleteWidget"></ConfirmModal>
    </div>
    <div class="faq-no-question">
      <span class="faq__text-us">{{ $vuetify.lang.t("$vuetify.safeAlert") }}</span>
    </div>
    <v-dialog v-model="cardCreateModal">
      <div class="modal">
        <div class="h1">
          <span v-if="action == 'add'">{{ $vuetify.lang.t('$vuetify.add') }}</span>
          <span v-if="action == 'edit'">{{ $vuetify.lang.t('$vuetify.edit') }}</span>
        </div>
        <div class="h2">{{ $vuetify.lang.t('$vuetify.mainInfo') }}</div>
        <v-form ref="form" @submit.prevent="">
          <div class="form__row align-baseline">
            <v-autocomplete v-model="newCard.category"
                            :items="categories" return-object :item-text="$vuetify.lang.current"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.category')" outlined attach
                            :rules="[$rules.required]"
                            :menu-props="{
                              bottom: true,
                              offsetY: true,
                              maxHeight: width < 1024 ? 200 : 800,
                              contentClass: 'select-menu',
                            }"
            ></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button class="btn-icon btn-action" @click="categoryCreateOpen"
                        v-bind="attrs"
                        v-on="on">
                  +
                </button>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.addCategory") }}</span>
            </v-tooltip>
          </div>

          <v-file-input v-if="newCard.front_photo" class="file-input" v-model="front_photo" accept=".jpg, .png, .jpeg"
                        :label="$vuetify.lang.t('$vuetify.frontPhoto')" :rules="[$rules.fileSize]"
                        :placeholder="$vuetify.lang.t('$vuetify.uploadImg')" prepend-icon="$photo"
                        :messages="newCard.front_photo ? newCard.front_photo : ''"
          ></v-file-input>
          <v-file-input v-else class="file-input" v-model="front_photo" accept=".jpg, .png, .jpeg"
                        :label="$vuetify.lang.t('$vuetify.frontPhoto')" :rules="[$rules.fileSize, $rules.required]"
                        :placeholder="$vuetify.lang.t('$vuetify.uploadImg')" prepend-icon="$photo"
          ></v-file-input>

          <v-file-input class="file-input mb-4" v-model="back_photo" accept=".jpg, .png, .jpeg"
                        :label="$vuetify.lang.t('$vuetify.backPhoto')" :rules="[$rules.fileSize]"
                        :placeholder="$vuetify.lang.t('$vuetify.uploadImg')" prepend-icon="$photo"
                        :messages="newCard.back_photo ? newCard.back_photo : ''"></v-file-input>

          <v-text-field v-model="newCard.card_name" :label="$vuetify.lang.t('$vuetify.name2')"
                        outlined></v-text-field>
          <v-text-field v-model="newCard.card_number" :label="$vuetify.lang.t('$vuetify.cardNumber')"
                        outlined></v-text-field>
          <v-menu ref="dc-dueTo" v-model="datePickers.due_to" :close-on-content-click="false"
                  transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="newCard.due_to ? new Date(newCard.due_to).toLocaleDateString('ru-Ru') : ''"
                :label="$vuetify.lang.t('$vuetify.dueTo')"
                v-bind="attrs" v-on="on" outlined readonly></v-text-field>
            </template>
            <v-date-picker v-model="newCard.due_to" @input="datePickers.due_to = false"
                           no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                           show-adjacent-months>
            </v-date-picker>
          </v-menu>
          <v-text-field v-model="newCard.company" :label="$vuetify.lang.t('$vuetify.company')" outlined></v-text-field>
          <v-text-field v-model="newCard.phone" :label="$vuetify.lang.t('$vuetify.phone')" outlined></v-text-field>
          <v-text-field v-model="newCard.email" label="Email" outlined>
          </v-text-field>
          <v-text-field v-model="newCard.address" :label="$vuetify.lang.t('$vuetify.address')" outlined></v-text-field>
          <v-textarea :label="$vuetify.lang.t('$vuetify.accounting.card.comment')" v-model="newCard.comment"
                      outlined></v-textarea>

          <v-progress-linear :class="{form__progress : form_loader}"
                             :active="form_loader"
                             indeterminate
                             rounded
                             height="6">
          </v-progress-linear>

          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <button class="btn btn-secondary" @click="cardCreateClose">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </button>
            <button class="btn btn-primary" @click="uploadFile">
              {{ $vuetify.lang.t('$vuetify.save') }}
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>

    <v-dialog v-model="categoryCreateModal">
      <div class="modal">
        <div class="h1">{{ $vuetify.lang.t('$vuetify.addCategory') }}</div>
        <v-form ref="category" @submit.prevent="">
          <template v-for="lang in Object.keys($vuetify.lang.locales)">
            <v-text-field v-if="['eng', 'rus'].includes(lang)" :key=lang
                          v-model="newCategory[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                          :rules="[$rules.required]"></v-text-field>
            <v-text-field v-else-if="!['en', 'ru'].includes(lang)" :key=lang v-model="newCategory[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined></v-text-field>
          </template>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="categoryCreateModal = false">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addCategory">
              <span>{{ $vuetify.lang.t('$vuetify.save') }}</span>
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>


  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal";
import DiscountCard from "@/views/parts/DiscountCard.vue";

export default {
  name: "SafeView",
  components: {DiscountCard, ConfirmModal},
  data() {
    return {
      datePickers: {
        dueTo: false
      },
      front_photo: "",
      back_photo: "",
      newCategory: {},
      categoryCreateModal: false,
      categories: [],
      cardCreateModal: false,
      width: window.width,

      newCard: {
        card_name: "",
        card_number: "",
        due_to: "",
        company: "",
        phone: "",
        email: "",
        address: "",
        front_photo: "",
        back_photo: "",
        comment: "",
        // category: null,
      },

      action: 'add',
      form_loader: false,

      discountCards: [],
      widgetsUsers: [],

      widgets: [],

      newWidget: {
        widget: "",
        settings: "",
      },
      widgetToDelete: 0,
      confirmModal: false,
      addWidgetForm: false,
      addWidgetUserTitle: "",
      addWidgetUserForm: false,
      hex: "#FFFFFF",
      menuColor: false,

      password: {
        password: '',
        re_password: '',
      },
      show: false,
      passwordForm: false,
      passwordFormType: '',
      same_password: (v) => v === this.password.password || this.$vuetify.lang.t('$vuetify.samePasswordRule'),

      arch: false,
      alertMsg: '',
      alertError: false,
    };
  },

  created() {
    if (!(localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"))) {
      this.$router.push({name: "AuthLayout"});
    }
    if (localStorage.getItem("safe_token")) {
      this.loadSafe();
      this.loadWidgets();
      this.loadDiscountCards()
    } else {
      this.checkPassword()
    }
  },

  methods: {
    cardCreateClose() {
      this.newCard = {}
      this.alertError = false
      this.front_photo = null
      this.back_photo = null
      this.cardCreateModal = false
    },

    addDiscountCard() {
      if (this.$refs.form.validate()) {
        this.loader = true
        this.newCard.category = JSON.stringify(this.newCard.category)
        this.$axios({
          url: this.$hostname + "time-tracking/discount-cards" + (this.newCard.id ? ("/" + this.newCard.id) : ""),
          method: this.newCard.id ? "PUT" : "POST",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },

          params: {
            "safetoken": localStorage.getItem("safe_token")
          },
          data: this.newCard
        }).then(() => {
          this.cardCreateClose()
          this.loadDiscountCards()
        }).catch((err) => {
          if (err.response.status === 403) {
            this.openPasswordForm()
          } else {
            this.alertMsg = this.$catch(err);
            this.alertError = true;
          }
        }).then(() => {
          this.form_loader = false
        })
      } else {
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm');
        this.alertError = true;
      }
    },

    addCategory() {
      if (this.$refs.category.validate()) {
        this.categories.push(this.newCategory)
        this.newCard.category = this.newCategory
        this.categoryCreateModal = false
      } else {
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm')
        this.alertError = true
      }
    },

    categoryCreateOpen() {
      let locales = Object.keys(this.$vuetify.lang.locales)
      locales.forEach(loc => {
        this.newCategory[loc] = ""
      })
      this.categoryCreateModal = true
    },
    cardEditOpen(card) {
      this.newCard = card
      this.action = 'edit'
      this.cardCreateModal = true
    },

    checkPassword() {
      this.$axios({
        url: this.$hostname + "time-tracking/token-password",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        this.openPasswordForm()
      }).catch((error) => {
        if (error.response.status === 404) {
          this.openPasswordForm('create')
        }
      });
    },
    addPassword() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "time-tracking/token-password",
          method: this.passwordFormType === 'create' ? "POST" : "PUT",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: {
            password: this.password.password,
          },
        }).then(() => {
          this.getToken()
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true
        });
      }
    },
    getToken() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "time-tracking/token-safe",
          method: "POST",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: {
            password: this.password.password,
          },
        }).then((response) => {
          localStorage.setItem("safe_token", response.data.data.token);
          this.loadSafe()
          this.loadWidgets()
          this.loadDiscountCards()
        }).catch((error) => {
          if (error.response.status === 401) {
            this.alertMsg = this.$vuetify.lang.t("$vuetify.invalidPassword")
          } else {
            this.alertMsg = this.$catch(error)
          }
          this.alertError = true;
        });
      }
    },

    uploadFile() {
      if (this.$refs.form.validate()) {
        this.form_loader = true;
        let promises = [];

        if (this.front_photo) {
          const frontPhotoPromise = this.uploadFileToServer(this.front_photo)
            .then((response) => {
              this.newCard.front_photo = response.data.data;
            })
            .catch((error) => {
              alert("Ошибка загрузки файла: " + error.message);
            });

          promises.push(frontPhotoPromise);
        }

        if (this.back_photo) {
          const backPhotoPromise = this.uploadFileToServer(this.back_photo)
            .then((response) => {
              this.newCard.back_photo = response.data.data;
            })
            .catch((error) => {
              alert("Ошибка загрузки файла: " + error.message);
            });

          promises.push(backPhotoPromise);
        }

        Promise.all(promises)
          .then(() => {
            this.addDiscountCard();
          })
          .catch((error) => {
            console.error("Error during file upload:", error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.alertMsg = this.$vuetify.lang.t("$vuetify.fillForm");
        this.alertError = true;
        this.loader = false;
      }
    },
    uploadFileToServer(file) {
      const formData = new FormData();
      formData.append("file", file);

      return this.$axios({
        url: this.$hostname + "time-tracking/images/upload/businesscards",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
    },


    loadDiscountCards() {
      this.$axios({
        url: this.$hostname + "time-tracking/discount-cards",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },

        params: {
          "safetoken": localStorage.getItem("safe_token"),
          arch: !this.arch,
          sort: this.sort,
          search: this.search,
          page: this.page,
          per: this.perPage,
          filter: JSON.stringify(this.filter),
        }
      }).then((response) => {
        this.discountCards = response.data.data.data;
        this.categories = response.data.data.categories
        this.$forceUpdate()
      }).catch((response) => {
        if (response.response.status === 403) {
          this.openPasswordForm()
        } else {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        }
      });
    },

    loadWidgets() {
      this.$axios({
        url: this.$hostname + "time-tracking/widgets",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      })
        .then((response) => {
          this.widgets = response.data.data.data;
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },
    loadSafe() {
      this.$axios({
        url: this.$hostname + "time-tracking/safe",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          "safetoken": localStorage.getItem("safe_token")
        }
      })
        .then((response) => {
          this.closePasswordForm()
          this.widgetsUsers = response.data.data.data;
          this.widgetsUsers.forEach((widget) => {
            widget.settings = JSON.parse(widget.settings);
          });
        })
        .catch((response) => {
          if (response.response.status === 403) {
            this.openPasswordForm()
          } else {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          }
        });
    },

    addWidgetUser() {
      if (this.newWidget.settings.last_save !== undefined) {
        this.newWidget.settings.last_save = new Date().toISOString();
      }
      this.newWidget.settings = JSON.stringify(this.newWidget.settings);
      this.$axios({
        url: this.$hostname + "time-tracking/safe",
        method: this.newWidget.id ? "PUT" : "POST",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          "safetoken": localStorage.getItem("safe_token")
        },
        data: this.newWidget,
      })
        .then(() => {
          this.loadSafe();
          this.addWidgetForm = false;
          this.addWidgetUserForm = false;
          this.newWidget = {
            widget: "",
            settings: "",
          };
        })
        .catch((response) => {
          if (response.response.status === 403) {
            this.openPasswordForm()
          } else {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          }
        });
    },
    deleteWidget() {
      this.$axios({
        url: this.$hostname + "time-tracking/safe",
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          "safetoken": localStorage.getItem("safe_token")
        },
        data: {id: this.widgetToDelete},
      })
        .then(() => {
          this.loadSafe();
          this.confirmModal = false;
        })
        .catch((response) => {
          if (response.response.status === 403) {
            this.openPasswordForm()
          } else {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          }
        });
    },
    openAddWidgetUser(type) {
      this.newWidget = {
        widget: type.id,
        settings: type.default_settings,
      };
      if (type.default_settings) {
        this.addWidgetUserTitle = this.$vuetify.lang.t(
          "$vuetify.widgets." + type.type + ".label"
        );
        this.addWidgetUserForm = true;
      } else {
        this.addWidgetUser();
      }
    },
    openWidgetConfirm(id) {
      this.widgetToDelete = id;
      this.confirmModal = true;
    },

    openPasswordForm(type) {
      this.passwordFormType = type
      this.passwordForm = true
    },
    closePasswordForm() {
      this.passwordFormType = ''
      this.password = {password: '', re_password: ''}
      this.passwordForm = false
    },
  },

  watch: {
    sort: function () {
      this.loadDiscountCards()
    },
    arch: function () {
      this.loadDiscountCards()
    },
    perPage: function () {
      if ((this.page * this.perPage) > (this.page * this.cards.length))
        this.page = 1
      this.loadDiscountCards()
    },
  },
};
</script>

<style lang="sass" scoped>
.discount-cards-wrapper
  grid-column: 1/3
</style>