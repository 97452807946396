<template>
  <div class="page-wrapper">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.accounting') }}</h1>
    </div>

    <div class="cards cards-bookkeeping">
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.purchases.label') }}</div>

        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/purchases">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.sales.label') }}</div>

        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/sales">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.reports.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/reports">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.extracts.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/extracts">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.contracts.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/contracts">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.documents.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/documents">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.property.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/property">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.garage.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/garage">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.waybills.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/waybills">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.accounting.offers.label') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/offers">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.menu.absence') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/absence">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.menu.timings') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/timings">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
      <div class="cards__single bookkeeping-card">
        <div class="bookkeeping-card__title h2">{{ $vuetify.lang.t('$vuetify.timingChanges') }}</div>
        <router-link class="btn btn-secondary link-not-like bookkeeping-card__button" to="/bookkeeping/timings-changes">
          {{ $vuetify.lang.t('$vuetify.goto') }}
        </router-link>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters} from "vuex";

export default {
  name: 'bookkeeping-view',

  created() {

    // this.checkPermission('Бухгалтерия').then(()=>{
    //   this.$router.push({name: "404"})
    // })

  },

  methods: {
    // ...mapActions(['checkPermission']),

  },

  computed: {
    ...mapGetters(['permissions'])
  }
}
</script>