<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.2 3.19971C9.2 2.88145 9.07357 2.57622 8.84853 2.35118C8.62348 2.12614 8.31826 1.99971 8 1.99971C7.68174 1.99971 7.37652 2.12614 7.15147 2.35118C6.92643 2.57622 6.8 2.88145 6.8 3.19971H6C6 2.66927 6.21071 2.16057 6.58579 1.78549C6.96086 1.41042 7.46957 1.19971 8 1.19971C8.53043 1.19971 9.03914 1.41042 9.41421 1.78549C9.78929 2.16057 10 2.66927 10 3.19971H13.6C13.7061 3.19971 13.8078 3.24185 13.8828 3.31686C13.9579 3.39188 14 3.49362 14 3.59971C14 3.70579 13.9579 3.80753 13.8828 3.88255C13.8078 3.95756 13.7061 3.99971 13.6 3.99971H13.1568L12.12 12.9837C12.0749 13.3736 11.888 13.7333 11.5949 13.9944C11.3018 14.2555 10.9229 14.3998 10.5304 14.3997H5.4696C5.07708 14.3998 4.69823 14.2555 4.40513 13.9944C4.11204 13.7333 3.92514 13.3736 3.88 12.9837L2.8424 3.99971H2.4C2.30638 3.99974 2.21571 3.96693 2.14379 3.907C2.07187 3.84706 2.02325 3.7638 2.0064 3.67171L2 3.59971C2 3.49362 2.04214 3.39188 2.11716 3.31686C2.19217 3.24185 2.29391 3.19971 2.4 3.19971H9.2ZM12.3504 3.99971H3.6488L4.6744 12.8917C4.69698 13.0867 4.79048 13.2666 4.93712 13.3972C5.08375 13.5278 5.27327 13.5998 5.4696 13.5997H10.5304C10.7266 13.5996 10.9159 13.5275 11.0624 13.3969C11.2088 13.2664 11.3022 13.0866 11.3248 12.8917L12.3504 3.99971ZM6.8 5.99971C6.996 5.99971 7.16 6.12371 7.1936 6.28691L7.2 6.35011V11.2501C7.2 11.4429 7.0208 11.5997 6.8 11.5997C6.604 11.5997 6.44 11.4757 6.4064 11.3125L6.4 11.2493V6.35091C6.4 6.15731 6.5792 6.00051 6.8 6.00051V5.99971ZM9.2 5.99971C9.396 5.99971 9.56 6.12371 9.5936 6.28691L9.6 6.35011V11.2501C9.6 11.4429 9.4208 11.5997 9.2 11.5997C9.004 11.5997 8.84 11.4757 8.8064 11.3125L8.8 11.2493V6.35091C8.8 6.15731 8.9792 6.00051 9.2 6.00051V5.99971Z"
        fill="#2C1521"/>
  </svg>
</template>

<script>
export default {
  name: "DeleteIcon"
}
</script>

<style scoped>

</style>