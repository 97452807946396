<template>
  <div class="edit">
    <VDialog v-model="textData.isVisible">
      <div class="edit__dialog">
        <div class="text-h5 pt-4">{{ Math.round(textData.font) }}</div>
        <div class="edit__dialog__picker">
          <VSlider class="edit__dialog__picker__size" min="10" max="100" v-model="textData.font" hide-details/>
          <VDialog>
            <template v-slot:activator="{on}">
              <button v-on="on" class="btn btn-icon edit__dialog__picker__palette">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path
                      d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 32.5-156t88-127Q256-817 330-848.5T488-880q80 0 151 27.5t124.5 76q53.5 48.5 85 115T880-518q0 115-70 176.5T640-280h-74q-9 0-12.5 5t-3.5 11q0 12 15 34.5t15 51.5q0 50-27.5 74T480-80Zm0-400Zm-220 40q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120-160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm200 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120 160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM480-160q9 0 14.5-5t5.5-13q0-14-15-33t-15-57q0-42 29-67t71-25h70q66 0 113-38.5T800-518q0-121-92.5-201.5T488-800q-136 0-232 93t-96 227q0 133 93.5 226.5T480-160Z"/>
                </svg>
              </button>
            </template>

            <template #default>
              <VColorPicker v-model="textData.color"/>
            </template>
          </VDialog>
        </div>
        <VTextField type="text" v-model="textData.text" hide-details/>
        <button class="btn mt-4 btn-100" @click="addText">Добавить</button>
      </div>
    </VDialog>

    <VDialog v-model="editTextData.isVisible">
      <div class="edit__dialog">
        <div class="text-h5 pt-4">{{ Math.round(editTextData.font) }}</div>
        <div class="edit__dialog__picker">
          <VSlider class="edit__dialog__picker__size" min="10" max="100" v-model="editTextData.font" hide-details/>
          <VDialog>
            <template v-slot:activator="{on}">
              <button v-on="on" class="btn btn-icon edit__dialog__picker__palette">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path
                      d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 32.5-156t88-127Q256-817 330-848.5T488-880q80 0 151 27.5t124.5 76q53.5 48.5 85 115T880-518q0 115-70 176.5T640-280h-74q-9 0-12.5 5t-3.5 11q0 12 15 34.5t15 51.5q0 50-27.5 74T480-80Zm0-400Zm-220 40q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120-160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm200 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120 160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM480-160q9 0 14.5-5t5.5-13q0-14-15-33t-15-57q0-42 29-67t71-25h70q66 0 113-38.5T800-518q0-121-92.5-201.5T488-800q-136 0-232 93t-96 227q0 133 93.5 226.5T480-160Z"/>
                </svg>
              </button>
            </template>

            <template #default>
              <VColorPicker v-model="editTextData.color"/>
            </template>
          </VDialog>
        </div>
        <VTextField type="text" v-model="editTextData.text" hide-details/>
        <button class="btn mt-4 btn-100" @click="editText">Добавить</button>
      </div>
    </VDialog>


    <div class="editor">
      <div class="editor__panel">
        <div class="editor__panel__actions">
          <button class="btn btn-icon" :class="getClassByAction('eraser')"
                  @click="switchAction('eraser')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path
                  d="M690-240h190v80H610l80-80Zm-500 80-85-85q-23-23-23.5-57t22.5-58l440-456q23-24 56.5-24t56.5 23l199 199q23 23 23 57t-23 57L520-160H190Zm296-80 314-322-198-198-442 456 64 64h262Zm-6-240Z"/>
            </svg>
          </button>
          <button class="btn btn-icon" :class="getClassByAction('painter')"
                  @click="switchAction('painter')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path
                  d="M240-120q-45 0-89-22t-71-58q26 0 53-20.5t27-59.5q0-50 35-85t85-35q50 0 85 35t35 85q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T320-280q0-17-11.5-28.5T280-320q-17 0-28.5 11.5T240-280q0 23-5.5 42T220-202q5 2 10 2h10Zm230-160L360-470l358-358q11-11 27.5-11.5T774-828l54 54q12 12 12 28t-12 28L470-360Zm-190 80Z"/>
            </svg>
          </button>
          <button class="btn btn-icon"
                  @click="switchAction('text')"
                  :class="getClassByAction('text')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path d="M420-160v-520H200v-120h560v120H540v520H420Z"/>
            </svg>
          </button>
          <button class="btn btn-icon" @click="() => this.$data.editor.removeLast()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/>
            </svg>
          </button>

          <VDialog>
            <template v-slot:activator="{on}">
              <button v-on="on" class="btn btn-icon palette">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path
                      d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 32.5-156t88-127Q256-817 330-848.5T488-880q80 0 151 27.5t124.5 76q53.5 48.5 85 115T880-518q0 115-70 176.5T640-280h-74q-9 0-12.5 5t-3.5 11q0 12 15 34.5t15 51.5q0 50-27.5 74T480-80Zm0-400Zm-220 40q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120-160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm200 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120 160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM480-160q9 0 14.5-5t5.5-13q0-14-15-33t-15-57q0-42 29-67t71-25h70q66 0 113-38.5T800-518q0-121-92.5-201.5T488-800q-136 0-232 93t-96 227q0 133 93.5 226.5T480-160Z"/>
                </svg>
              </button>
            </template>

            <template #default>
              <VColorPicker v-model="painterData.color"/>
            </template>
          </VDialog>

        </div>
      </div>
      <div>
        <div class="text-h5">{{ Math.round(painterData.length) }}</div>
        <VSlider type="range" class="w-auto" min="1" max="100" v-model="painterData.length"/>
      </div>


      <div class="editor__container">
        <canvas
            ref="editorRef"
            class="editor__container__canvas"
            @mousemove="mouseMove"
            @mouseup="mouseUp"
            @mousedown="mouseDown"
        ></canvas>
      </div>

      <button class="btn btn-primary text-h6" @click="updateImage">{{ $vuetify.lang.t('$vuetify.save') }}</button>
    </div>
  </div>
</template>

<script>
import {ColoredPath, ColoredText, ImageEditor, loadImage} from "@/components/painter/editor";

export default {
  components: {},
  props: {
    image: String,
    format: String,
  },
  data() {
    return {
      editor: null,
      currentAction: 'painter',
      painterData: {
        color: '#000000',
        length: 1,
        isPainting: false,
      },
      textData: {
        isVisible: false,
        font: 1,
        text: '',
        x: 0,
        y: 0,
        color: '#000000',
      },

      editTextData: {
        isVisible: false,
        font: 1,
        text: '',
        id: 0,
        x: 0,
        y: 0,
        color: '#000000',
      }
    }
  },
  watch: {
    image() {
      if (!this.$data.editor) return
      if (!this.$props.image) return;

      loadImage(this.$props.image).then((imageData) => {
        this.$data.editor.createBackground(imageData)
      })
    }
  },
  methods: {
    updateImage() {
      this.$refs.editorRef.toBlob((blob) => {
        this.$emit('updateImage', blob)
      }, this.$props.format)
    },
    getClassByAction(action) {
      return this.$data.currentAction === action ? 'btn-icon-active' : '';
    },
    switchAction(action) {
      this.$data.textData.isVisible = false;
      this.$data.currentAction = action;
    },
    addText() {
      if (!this.$data.editor) return;
      if (!this.$refs.editorRef) return;

      const [x, y] = this.$data.editor.mapByImage(this.$data.textData.x, this.$data.textData.y);

      this.$data.editor.add(
          new ColoredText(this.$data.textData.text, this.$data.textData.font, this.$data.textData.color, x, y),
      );
      this.$data.textData.text = '';
      this.$data.textData.x = 0;
      this.$data.textData.y = 0;
      this.$data.textData.isVisible = false;
    },
    mouseDown(e) {
      if (this.$data.currentAction === 'text') {
        if (!this.$refs.editorRef) return;

        const [x, y] = this.$data.editor.mapByImage(e.offsetX, e.offsetY);
        const text = this.$data.editor.getIntersectText(x, y)

        if (text) {
          this.$data.editTextData.text = text.text;
          this.$data.editTextData.font = text.size;
          this.$data.editTextData.color = text.color;
          this.$data.editTextData.x = text.x;
          this.$data.editTextData.y = text.y;
          this.$data.editTextData.id = text.id;
          this.$data.editTextData.isVisible = true

          return
        }

        this.$data.textData.x = e.offsetX;
        this.$data.textData.y = e.offsetY;
        this.$data.textData.isVisible = true;

      } else if (this.$data.currentAction === 'painter') {
        this.$data.painterData.isPainting = true;
        this.$data.editor?.add(
            new ColoredPath(new Path2D(), this.$data.painterData.color, this.$data.painterData.length),
        );
      } else if (this.$data.currentAction === 'eraser') {
        this.$data.painterData.isPainting = true;
      }
    },
    mouseMove(e) {
      if (!this.$data.painterData.isPainting) return;
      if (!this.$refs.editorRef) return;
      if (!this.$data.editor) return;

      const [x, y] = this.$data.editor.mapByImage(e.offsetX, e.offsetY);

      if (this.$data.currentAction === 'painter') {
        this.$data.editor.addPointToPath(x, y);
      } else {
        this.$data.editor?.tryRemoveByPoint(x, y);
      }
    },
    mouseUp() {
      this.$data.painterData.isPainting = false;
      this.$data.editor?.endPoint();
    },
    editText() {
      this.$data.editor.replaceById(this.$data.editTextData.id,
          new ColoredText(this.$data.editTextData.text, this.$data.editTextData.font,
              this.$data.editTextData.color, this.$data.editTextData.x,
              this.$data.editTextData.y, this.$data.editTextData.id)
      )

      this.$data.editTextData.isVisible = false
    }
  },
  async mounted() {
    this.$data.editor = new ImageEditor(this.$refs.editorRef);
    const imageData = await loadImage(this.$props.image)
    this.$data.editor.createBackground(imageData)

    this.$refs.editorRef.ontouchstart = (e) => {
      this.mouseDown({
        offsetX: e.targetTouches[0].pageX - this.$refs.editorRef.getBoundingClientRect().x,
        offsetY: e.targetTouches[0].pageY - this.$refs.editorRef.getBoundingClientRect().y,
      });
    };

    this.$refs.editorRef.ontouchmove = (e) => {
      this.mouseMove({
        offsetX: e.targetTouches[0].pageX - this.$refs.editorRef.getBoundingClientRect().x,
        offsetY: e.targetTouches[0].pageY - this.$refs.editorRef.getBoundingClientRect().y,
      });
    };

    this.$refs.editorRef.ontouchend = () => {
      this.mouseUp();
    };
  }
}
</script>

