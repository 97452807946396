<template>
  <div :class="'dgantt ' + (closedLeft? 'closed' : '')">
<!--    <div class="dgantt__header">-->
      <div class="dgantt__left flex dgantt__header-part dgantt__controls-wrap">
        <div class="dgantt__controls">
          <div class="dgantt__title">
            {{ $vuetify.lang.t('$vuetify.dgantt.tasks') }}
          </div>
          <button class="btn-action btn-icon dgantt__close-btn" @click="closeLeft">
            <svg width="20px" height="20px" viewBox="-5 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-421.000000, -1195.000000)" fill="#000000">
                  <path d="M423.429,1206.98 L434.686,1196.7 C435.079,1196.31 435.079,1195.67 434.686,1195.28 C434.293,1194.89 433.655,1194.89 433.263,1195.28 L421.282,1206.22 C421.073,1206.43 420.983,1206.71 420.998,1206.98 C420.983,1207.26 421.073,1207.54 421.282,1207.75 L433.263,1218.69 C433.655,1219.08 434.293,1219.08 434.686,1218.69 C435.079,1218.29 435.079,1217.66 434.686,1217.27 L423.429,1206.98" id="chevron-left" sketch:type="MSShapeGroup">
                  </path>
                </g>
              </g>
            </svg>
          </button>
          <div class="d-md-none">
          <div class="dgantt__zoom">
            <button class="btn-action btn-icon" @click="filter_modal=true">
              <svg fill="currentcolor" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
            <g>
              <g>
                <path d="M480.159,45.81H31.841C14.284,45.81,0,60.093,0,77.65v37.472c0,17.557,14.284,31.841,31.841,31.841h14.907L211.305,311.52    v144.056c0,4.293,2.586,8.163,6.552,9.806c1.313,0.543,2.692,0.808,4.059,0.808c2.763,0,5.478-1.078,7.508-3.109l68.559-68.56    c1.99-1.991,3.109-4.69,3.109-7.505v-75.498l164.554-164.555h14.514c17.557,0,31.841-14.284,31.841-31.841V77.65    C512,60.093,497.716,45.81,480.159,45.81z M282.968,299.621c-2.096,2.096-3.128,4.85-3.104,7.597v75.404l-47.332,47.332V307.156    c0.007-2.727-1.027-5.455-3.107-7.536L76.768,146.963h358.857L282.968,299.621z M490.773,115.122    c0,5.852-4.761,10.614-10.614,10.614H31.841c-5.852,0-10.614-4.761-10.614-10.614V77.65c0-5.852,4.761-10.614,10.614-10.614    h448.319c5.852,0,10.614,4.761,10.614,10.614V115.122z"/>
              </g>
            </g>
            </svg>
            </button>
            <button v-if="permissions.edit.indexOf('Диаграмма') != -1" class="btn-action btn-icon" @click="openCreateTask">+</button>
          </div>
          </div>
          <div class="d-none d-md-block">
        <div class="dgantt__zoom ">
          <button class="btn btn-primary btn-low btn-short" @click="filter_modal=true">
            Фильтр
          </button>
          <button  v-if="permissions.edit.indexOf('Диаграмма') != -1" class="btn btn-secondary btn-low btn-short" @click="openCreateTask">
            {{ $vuetify.lang.t('$vuetify.addTask') }}
          </button>
        </div>
          </div>

          <div class="dgantt__zoom">
            <button class="btn-action btn-icon" @click="zoomIn">
              <svg fill="#000000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   viewBox="0 0 52.966 52.966" xml:space="preserve" stroke="currentcolor">
                <g>
                  <path d="M28.983,20h-6v-6c0-0.552-0.448-1-1-1s-1,0.448-1,1v6h-6c-0.552,0-1,0.448-1,1s0.448,1,1,1h6v6c0,0.552,0.448,1,1,1
                    s1-0.448,1-1v-6h6c0.552,0,1-0.448,1-1S29.535,20,28.983,20z"/>
                  <path d="M51.704,51.273L36.845,35.82c3.79-3.801,6.138-9.041,6.138-14.82c0-11.58-9.42-21-21-21s-21,9.42-21,21s9.42,21,21,21
                    c5.083,0,9.748-1.817,13.384-4.832l14.895,15.491c0.196,0.205,0.458,0.307,0.721,0.307c0.25,0,0.499-0.093,0.693-0.279
                    C52.074,52.304,52.086,51.671,51.704,51.273z M2.983,21c0-10.477,8.523-19,19-19s19,8.523,19,19s-8.523,19-19,19
                    S2.983,31.477,2.983,21z"/>
                </g>
              </svg>
            </button>
            <button class="btn-action btn-icon" @click="zoomOut">
              <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">

                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                  <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-360.000000, -1139.000000)" fill="#000000">
                    <path d="M373.46,1163.45 C367.17,1163.45 362.071,1158.44 362.071,1152.25 C362.071,1146.06 367.17,1141.04 373.46,1141.04 C379.75,1141.04 384.85,1146.06 384.85,1152.25 C384.85,1158.44 379.75,1163.45 373.46,1163.45 L373.46,1163.45 Z M391.688,1169.25 L383.429,1161.12 C385.592,1158.77 386.92,1155.67 386.92,1152.25 C386.92,1144.93 380.894,1139 373.46,1139 C366.026,1139 360,1144.93 360,1152.25 C360,1159.56 366.026,1165.49 373.46,1165.49 C376.672,1165.49 379.618,1164.38 381.932,1162.53 L390.225,1170.69 C390.629,1171.09 391.284,1171.09 391.688,1170.69 C392.093,1170.3 392.093,1169.65 391.688,1169.25 L391.688,1169.25 Z M378.689,1151.41 L368.643,1151.41 C368.102,1151.41 367.663,1151.84 367.663,1152.37 C367.663,1152.9 368.102,1153.33 368.643,1153.33 L378.689,1153.33 C379.23,1153.33 379.669,1152.9 379.669,1152.37 C379.669,1151.84 379.23,1151.41 378.689,1151.41 L378.689,1151.41 Z" id="zoom-out" sketch:type="MSShapeGroup">

                    </path>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div class="dgantt__day-column">
          <div class="dgantt__day-column-text">
            days
          </div>
        </div>
      </div>
      <div class="dgantt__right dgantt__header-part">
        <div v-if="tasks.length" class="dgantt__dates">
          <div class="dgantt__year" v-for="year in dates" :key="year.yearNumber">
            <!-- Заголовок года -->
            <div class="dgantt__year-head">
              {{ year.yearNumber }}
            </div>
            <div class="dgantt__months">
              <div class="dgantt__month"
                   :style="{width: month.days*scale-1+'px'}"
                   v-for="month in year.months" :key="month.monthName" >
                <!-- Заголовок месяца -->
                <div class="dgantt__month-head">
                  {{ $vuetify.lang.t('$vuetify.months.' + month.monthName ) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tasks.length" class="dgantt__weeks">
          <div class="dgantt__week" v-for="week in weeks" :key="week.number">
            <div class="dgantt__week-head">
              {{ week.number }}
            </div>
            <div class="dgantt__week-days" >
              <div class="dgantt__week-day-wrap" v-for="day in week.days" :key="day.fullDate">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">

                    <div class="dgantt__week-day" :style="{width: scale-1+'px'}" v-bind="attrs"
                         v-on="on">
                      {{day.date}}
                    </div>
                  </template>
                  <span>
                      {{ $vuetify.lang.t('$vuetify.days.'+day.dayOfWeek) }}
                  </span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--    </div>-->
    <div class="dgantt__left dgantt__list-wrap">
      <div class="dgantt__list" >
        <DGanttListTask
            v-on:openTask="openEditTask($event)"
            v-for="taskItem in getSubTasks()"
            :key="taskItem.id"
            :task="taskItem"
            :tasks="tasks"></DGanttListTask>
      </div>

    </div>
    <div class="dgantt__right dgantt__view">
      <div class="dgantt__view-lines">
        <template v-for="week in weeks">
          <div class="dgantt__view-line"
               :class="{'holiday' :
                   !work_days_list.includes(day.dayOfWeek)
                || holidays.includes(day.fullDate)}"
               :style="{width: scale-1+'px'}"
               v-for="day in week.days" :key="day.fullDate">
          </div>
        </template>
      </div>
      <div class="dgantt__view-list">
        <DGanttViewTask v-for="taskItem in getSubTasks()"
                        :key="taskItem.id"
                        :task="taskItem"
                        :tasks="tasks"
                        :permission="permissions.edit.indexOf('Диаграмма') != -1"
                        v-on:openSubTask="openSubTask($event)"
                        v-on:openTask="openEditTask($event)"
        >

        </DGanttViewTask>
      </div>
    </div>


    <v-dialog v-model="filter_modal" >
      <div class="modal modal-big modal-height">
        <v-autocomplete v-model="filter.user_profiles" clearable
                        :label="$vuetify.lang.t('$vuetify.selectWorker')"
                        :items="workers" item-text="name" item-value="id"
                        @change="loadWorkers"
                        :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        maxHeight: 200,
                                        contentClass: 'select-menu',
                                    }" outlined hide-details attach multiple>
          <template v-slot:selection="{ item }">
            {{ item.name }} {{ item.lastname }},
          </template>
          <template v-slot:item="{ item }">
            {{ item.name }} {{ item.lastname }}
          </template>
        </v-autocomplete>
        <div class="mt-5">
        <v-autocomplete v-model="filter.work_type" clearable
                        :label="$vuetify.lang.t('$vuetify.settings.workTypes')"
                        :items="workTypes" :item-text="'name.'+$vuetify.lang.current" item-value="id"

                        @change="loadWorkers"
                        :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        maxHeight: 200,
                                        contentClass: 'select-menu',
                                    }" outlined hide-details attach multiple>
          <template v-slot:selection="{ item }">
            {{ item.name[$vuetify.lang.current] }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.name[$vuetify.lang.current] }}
          </template>
        </v-autocomplete>
        </div>
        <button class="btn btn-primary mt-5" @click.stop="saveFilter">
          {{ $vuetify.lang.t('$vuetify.save') }}

        </button>
      </div>
    </v-dialog>
    <v-dialog v-model="form_modal" >
      <div class="modal modal-big">
        <v-form v-if="permissions.edit.indexOf('Диаграмма') != -1"  ref="dgantt-task" @submit.prevent="">

          <v-autocomplete v-model="editTask.work_type" clearable
                          :label="$vuetify.lang.t('$vuetify.dgantt.workType')"
                          :items="workTypes.filter(el => el.parent == parentTaskType)" item-text="name" item-value="id"
                          @change="loadWorkers"
                          :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        maxHeight: 200,
                                        contentClass: 'select-menu',
                                    }" outlined hide-details attach>
            <template v-slot:selection="{ item }">
              {{ item.name[$vuetify.lang.current] }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.name[$vuetify.lang.current] }}
            </template>
          </v-autocomplete>

          <v-autocomplete v-model="editTask.user_profiles" clearable
                          :label="$vuetify.lang.t('$vuetify.selectWorker')"
                          :items="workers" item-text="name" item-value="id"
                          class="mt-5"
                          :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        maxHeight: 200,
                                        contentClass: 'select-menu',
                                    }" outlined hide-details attach multiple>
            <template v-slot:selection="{ item }">
              {{ item.name }} {{ item.lastname }},
            </template>
            <template v-slot:item="{ item }">
              {{ item.name }} {{ item.lastname }}
            </template>
          </v-autocomplete>

          <div class="form__row mt-5">
            <v-menu ref="date_start" v-model="datePickers.date_start" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="editTask.date_start ? new Date(editTask.date_start).toLocaleDateString('ru-Ru') : ''"
                    :label="$vuetify.lang.t('$vuetify.worksStart')"
                    v-bind="attrs" v-on="on" outlined
                    readonly :rules="[$rules.required]"></v-text-field>
              </template>
              <v-date-picker v-model="editTask.date_start" @input="datePickers.date_start = false"

                             no-title first-day-of-week="1" show-week locale-first-day-of-year="4"
                             show-adjacent-months>
              </v-date-picker>
            </v-menu>
            <v-menu ref="date_end" v-model="datePickers.date_end" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="editTask.date_end ? new Date(editTask.date_end).toLocaleDateString('ru-Ru') : ''"
                    :label="$vuetify.lang.t('$vuetify.worksEnd')"
                    v-bind="attrs" v-on="on" outlined
                    readonly :rules="[$rules.required]"></v-text-field>
              </template>
              <v-date-picker v-model="editTask.date_end" @input="datePickers.date_end = false"
                             no-title first-day-of-week="1"
                             show-week locale-first-day-of-year="4"
                             show-adjacent-months>
              </v-date-picker>
            </v-menu>

          </div>


          <v-menu ref="date_end_real" v-model="datePickers.date_end_real" :close-on-content-click="false"
                  transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="editTask.date_end_real ? new Date(editTask.date_end_real).toLocaleDateString('ru-Ru') : ''"
                  :label="$vuetify.lang.t('$vuetify.worksEndLate')"
                  v-bind="attrs" v-on="on" outlined
                  readonly :rules="[$rules.required]"></v-text-field>
            </template>
            <v-date-picker v-model="editTask.date_end_real" @input="datePickers.date_end_real = false"
                           no-title first-day-of-week="1"
                           :min="editTask.date_end"

                           show-week locale-first-day-of-year="4"
                           show-adjacent-months>
            </v-date-picker>
          </v-menu>


          <v-textarea :label="$vuetify.lang.t('$vuetify.description')" v-model="editTask.description"
                      outlined></v-textarea>
          <div class="flex dgantt__colors mb-5">
            <div
                class="dgantt__color"
                v-for="color in colors"
                :key="color"
            >
              <input type="radio" :value="'#'+color" :id="object_id+'c' +color" checked v-model="editTask.color" :name="object_id+ 'dgantt-color'">
              <label :for="object_id+'c'+color" :style="{background: '#' + color}"></label>
            </div>

          </div>

          <div class="mb-5">
            <v-checkbox v-model="editTask.holidays_work" :label="$vuetify.lang.t('$vuetify.holiday-work')"></v-checkbox>

          </div>
          <div class="error--text mb-5" v-if="alertError">{{ alertMsg }}</div>



          <div class="flex gap-3">
            <button class="btn btn-primary" @click.stop="saveTask">
              {{ $vuetify.lang.t('$vuetify.save') }}

            </button>
            <button v-if="editTask.id" class="btn btn-secondary" @click.stop="deleteTask(editTask.id)">
              {{ $vuetify.lang.t('$vuetify.delete') }}
            </button>
          </div>
        </v-form>
        <div
            class="dgantt__info"
            v-else>
          <div class="dgantt__info-item" v-if="editTask.work_type">
            <div class="dgantt__info-title">
              {{$vuetify.lang.t('$vuetify.dgantt.workType')}}
            </div>
            <div class="dgantt__info-text">
              {{ workTypes.find(el=> el.id == editTask.work_type).name[$vuetify.lang.current]}}
            </div>
          </div>
          <div class="dgantt__info-item" v-if="editTask.user_profiles">
            <div class="dgantt__info-title">
              {{$vuetify.lang.t('$vuetify.menu.workers')}}
            </div>
            <div class="dgantt__info-text">
              {{ workers.filter(el=> editTask.user_profiles.includes(el.id)).map(el=>el.name + ' ' + el.lastname).join(', ')}}
            </div>
          </div>
          <div class="dgantt__info-item" v-if="editTask.description">
            <div class="dgantt__info-title">
              {{$vuetify.lang.t('$vuetify.description')}}
            </div>
            <div class="dgantt__info-text">
              {{ editTask.description }}
            </div>
          </div>

          <button class="btn btn-primary mt-5" @click="form_modal=false">Закрыть</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DGanttViewTask from "@/components/DGanttViewTask.vue";
import DGanttListTask from "@/components/DGanttListTask.vue";

import { mapGetters, mapActions } from 'vuex'
export default {
  name: "dgantt-component",
  components: {DGanttListTask, DGanttViewTask},

  props: ['object_id'],

  mounted(){
    this.getTasks();
    this.loadWorkers();
    this.loadWorkTypes()
    this.loadDataHolidays()
  },

  data: function () {
    return {
      colors: ['4E79A7', 'F28E2B', '76B7B2', '59A14F', 'EDC948', 'B07AA1', 'FF9DA7', '9C755F', 'BAB0AC'],
      form_modal: false,
      filter_modal: false,
      editTask: {
      },

      parentTaskType: null,
      filter: {},
      workers: [],
      workTypes: [],
      datePickers: {
        date_start: false,
        date_end: false,
        date_end_real: false,
      },

      tasks: [],
      closedLeft: false,

      work_days: {},
      holidays: [],

      alertError: false,
      alertMsg: '',
    }
  },

  methods: {
    ...mapActions(['setWorkers', "setScale", 'setWorkTypes']),

    loadDataHolidays(){
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings?key=holidays',
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then(response => {
        const plainObject = JSON.parse(JSON.stringify(response.data.data.data));
        if (Object.keys(plainObject).length !== 0) {
          this.holidays = response.data.data.data
        }
      })
      this.$axios({
        url: this.$hostname + 'time-tracking/organization/settings?key=work_days',
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then(response => {
        this.work_days = response.data.data.data
      })
    },

    loadWorkTypes(){
      this.$axios({
        url: this.$hostname + 'time-tracking/work-types',
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        console.log(JSON.parse(JSON.stringify(response.data.data.data)))
        this.workTypes = JSON.parse(JSON.stringify(response.data.data.data))
        this.setWorkTypes(JSON.parse(JSON.stringify(response.data.data.data)))
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    saveFilter(){
      this.filter_modal=false
      this.getTasks()
    },

    closeLeft(){
      this.closedLeft = !this.closedLeft
    },

    zoomOut(){
      if (this.scale> 20){
        this.setScale(this.scale-5)
      }

    },

    zoomIn(){
      this.setScale(this.scale+5)
    },

    deleteTask(id){
      this.$axios({
        url: this.$hostname + "time-tracking/work-schedule",
        method: "DELETE",
        data: {
          id: id,
        },
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        this.getTasks()
        this.form_modal = false
      })
    },

    openCreateTask(){
      this.alertError = false

      this.parentTaskType=null
      this.editTask = {
        user_profiles: [],
        date_end: null,
        date_end_real: null,
        color: '#'+this.colors[0]
      }
      this.form_modal = true
    },

    loadWorkers() {
      this.$axios({
        url: this.$hostname + "time-tracking/objects/employees/" + this.object_id,

        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      })
          .then((response) => {
            this.workers = response.data.data.data.map(el => el.user_profile_id);
            this.setWorkers(this.workers)
          })
          .catch((response) => {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          });
    },


    getParentTask(task){
      return this.tasks.find(t=> t.id == task.parent_task)
    },

    getSubTasks(id = null){
      return this.tasks.filter(task => task.parent_task == id)
    },

    getTasks(){
      this.$axios({
        url: this.$hostname + "time-tracking/work-schedule",
        method: "GET",
        params: {
          object: this.object_id,
          filter: this.filter,
        },
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.tasks = response.data.data.data
      })
    },

    openSubTask(parent_task){
      this.setParentType(parent_task)

      this.editTask = {
        parent_task
      }
      this.form_modal = true
    },

    setParentType(task_id){
      let parentTask = this.tasks.find(el => el.id === task_id)
      this.parentTaskType = task_id && parentTask ? parentTask.work_type : null
    },

    openEditTask(task){
      this.alertError = false

      let parentTask = this.tasks.find(el => el.parent === task.id)

      this.setParentType(parentTask ? parentTask.id : null)
      this.editTask = task
      // this.editTask.user_profiles = this.editTask.user_profiles.map(el => el.id)
      this.form_modal = true
    },

    saveTaskRequest(editTask){
      this.form_modal = false

      if (editTask.id){
        this.$axios({
          url: this.$hostname + "time-tracking/work-schedule",
          method: "PUT",
          data: {
            id: editTask.id,
            object: this.object_id,
            work_type: editTask.work_type,
            parent_task: editTask.parent_task,
            date_start: editTask.date_start,
            date_end: editTask.date_end,
            date_end_real: editTask.date_end_real,
            color: editTask.color,
            description: editTask.description,
            user_profiles: JSON.stringify(editTask.user_profiles),
            holidays_work: editTask.holidays_work
          },
          headers: {
            Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                    sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          console.log(response.data)
        });
      }
      else {
        this.$axios({
          url: this.$hostname + "time-tracking/work-schedule",
          method: "POST",
          data: {
            object: this.object_id,
            work_type: editTask.work_type,
            parent_task: editTask.parent_task,
            date_start: editTask.date_start,
            date_end: editTask.date_end,
            date_end_real: editTask.date_end_real,

            color: editTask.color,
            description: editTask.description,
            user_profiles: JSON.stringify(editTask.user_profiles),
          },
          headers: {
            Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                    sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          console.log(response.data)
          this.getTasks()

        });
      }
    },

    saveTask(){
      this.alertError = false

      if (this.$refs["dgantt-task"].validate()) {

        let task = this.tasks.find(el=> el.id == this.editTask.id)
        if (task) {
          if (!this.checkChildDate(task)) {
            this.alertError = true
            this.alertMsg = 'child tasks out of date range'
            return
          }
        }
        this.saveTaskRequest(this.editTask)
        this.updateParentDates(this.editTask)
      }
    },

    checkChildDate(task){
      let res = true
      console.log(this.getSubTasks(task.id));
      this.getSubTasks(task.id).forEach(child=> {
        if (new Date(child.start_date) < new Date(task.start_date) || new Date(child.date_end) > new Date(task.date_end) || new Date(child.date_end_real) > new Date(task.date_end_real)){
          res =  false
        }
      })
      return res
    },

    updateParentDates(task){
      let change = false;
      if (!this.getParentTask(task)){
        return
      }

      if ( new Date(task.date_end_real) >  new Date(this.getParentTask(task).date_end_real)){
        this.getParentTask(task).date_end_real = task.date_end_real
        change = true
      }
      if ( new Date(task.date_end) >  new Date(this.getParentTask(task).date_end)){
        this.getParentTask(task).date_end = task.date_end
        change = true
      }
      if ( new Date(task.date_start) <  new Date(this.getParentTask(task).date_start)){
        this.getParentTask(task).date_start = task.date_start
        change = true
      }
      if (change){
        this.saveTaskRequest(this.getParentTask(task))
        this.updateParentDates(this.getParentTask(task))
      }
    },


    addDayToWeek(currentDate, week) {
      const day = currentDate.getDate().toString().padStart(2, '0');
      const formattedDate = `${day}`;
      const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'short' });
      const fullDate =  currentDate.toLocaleString('fr-CA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      week.push({ date: formattedDate, dayOfWeek, fullDate });
    },

    formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${day}.${month}`;
    },
    getWeekNumber(date) {
      return date
    },
    getMonthName(monthIndex) {
      return monthIndex
    },

  },

  computed: {
    ...mapGetters(['getWorkers', 'scale', "permissions"]),

    work_days_list()
    {
      return Object.entries(this.work_days).filter(([, value]) => value === true).map(([day]) => day)
    }
,

    minDate() {
      return this.tasks.reduce((min, task) => {
        if (!min) return task.date_start
        return task.date_start < min ? task.date_start : min;
      }, '');
    },

    maxDate() {
      return this.tasks.reduce((max, task) => {
        return task.date_end_real > max ? task.date_end_real : max;
      }, '');
    },

    dates() {
      // Преобразование minDate и maxDate в объекты Date
      const minDate = new Date(this.minDate);
      const maxDate = new Date(this.maxDate);

      let currentDate = new Date(minDate);
      const result = [];

      while (currentDate <= maxDate) {
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let daysInMonth = new Date(year, month + 1, 0).getDate();

        // Находим индекс текущего года в массиве результатов
        let yearIndex = result.findIndex(y => y.yearNumber === year);
        if (yearIndex === -1) {
          // Если год еще не добавлен, добавляем его
          result.push({ yearNumber: year, months: [] });
          yearIndex = result.length - 1;
        }

        // Находим индекс текущего месяца в массиве месяцев текущего года
        let monthIndex = result[yearIndex].months.findIndex(m => m.monthName === currentDate.toLocaleString('en-US', { month: 'short' }));
        if (monthIndex === -1) {
          // Если месяц еще не добавлен, добавляем его с нулевым количеством дней
          result[yearIndex].months.push({ monthName: currentDate.toLocaleString('en-US', { month: 'short' }), days: 0 });
          monthIndex = result[yearIndex].months.length - 1;
        }

        // Рассчитываем количество дней, которые нужно показать в графике
        let daysToShow = 1; // По умолчанию показываем хотя бы один день
        if (month === minDate.getMonth() && year === minDate.getFullYear()) {
          // Если это начальный месяц, рассчитываем дни от minDate
          daysToShow = daysInMonth - minDate.getDate() + 1;
        } else if (month === maxDate.getMonth() && year === maxDate.getFullYear()) {
          // Если это конечный месяц, рассчитываем дни до maxDate
          daysToShow = maxDate.getDate();
        } else if (currentDate > minDate && currentDate < maxDate) {
          // Для месяцев между minDate и maxDate показываем все дни месяца
          daysToShow = daysInMonth;
        }

        // Обновляем количество дней в текущем месяце
        result[yearIndex].months[monthIndex].days += daysToShow;

        // Переходим к первому числу следующего месяца
        currentDate.setMonth(currentDate.getMonth() + 1, 1);
      }

      return result;
    },

    weeks() {
      const startDate = new Date(this.minDate);
      const endDate = new Date(this.maxDate);
      let weeksArray = [];
      let currentDate = new Date(startDate.getTime());

      // Функция для добавления недели
      const addWeek = (start, end, weekOfYear) => {
        weeksArray.push({
          days: week,
          weekStart: this.formatDate(start),
          number: weekOfYear,
        });
      };

      // Получаем номер недели в году для даты
      const getWeekNumber = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
      };

      let week = [];
      let weekOfYear = getWeekNumber(currentDate);

      // Первая неделя, которая может начаться не с понедельника
      if (currentDate.getDay() !== 1) {
        // Добавляем дни до конца первой недели
        do {
          this.addDayToWeek(currentDate, week);
          currentDate.setDate(currentDate.getDate() + 1);
        } while (currentDate.getDay() !== 1 && currentDate <= endDate);

        const weekEnd = new Date(currentDate.getTime());
        weekEnd.setDate(weekEnd.getDate() - 1); // Конец недели
        addWeek(startDate, weekEnd, weekOfYear);

        // Переходим к следующей неделе
        week = [];
        weekOfYear = getWeekNumber(currentDate);
      }

      // Обработка оставшихся недель
      while (currentDate <= endDate) {
        const weekStart = new Date(currentDate.getTime()); // Начало текущей недели

        do {
          this.addDayToWeek(currentDate, week);
          currentDate.setDate(currentDate.getDate() + 1);
        } while (currentDate.getDay() !== 1 && currentDate <= endDate);

        // Конец текущей недели, если следующий день понедельник
        const weekEnd = new Date(currentDate.getTime());
        weekEnd.setDate(weekEnd.getDate() - (currentDate.getDay() === 1 ? 1 : 0));

        // Добавляем неделю
        addWeek(weekStart, weekEnd, weekOfYear);

        // Если текущий день - понедельник, но это уже следующая неделя, не включать его
        if (currentDate.getDay() === 1 && currentDate > endDate) {
          weekEnd.setDate(weekEnd.getDate() - 1);
        }

        // Переходим к следующей неделе
        week = [];
        weekOfYear = getWeekNumber(currentDate);
      }

      // Убираем последнюю неделю, если она пустая
      if (weeksArray[weeksArray.length - 1] && weeksArray[weeksArray.length - 1].days.length === 0) {
        weeksArray.pop();
      }

      return weeksArray;
    },


  },

  watch: {
    editTask: {
      deep: true,
      handler() {
        console.log('editTask')
        if (!this.editTask.date_end) {
          if (!this.editTask.date_start) {
            return
          }
          if (!this.editTask.date_end || new Date(this.editTask.date_end) < new Date(this.editTask.date_start)){
            this.editTask.date_end = this.editTask.date_start
          }
        }
        if (!this.editTask.date_end_real || new Date(this.editTask.date_end_real) < new Date(this.editTask.date_end)){
          this.editTask.date_end_real = this.editTask.date_end
        }
      },
    }
  }

}
</script>
