<template>
  <div class="page-wrap settings-work-types">
    <div class="page-head">
      <div class="breadcrumbs">
        <div class="h1">
          <router-link class="link-not-like" to="/settings">{{ $vuetify.lang.t('$vuetify.menu.settings') }}
          </router-link>
        </div>
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.settings.' + this.title) }}
        </div>
      </div>
    </div>
    <div class="page-head">
    </div>

    <div class="settings-items__list">
      <button class="btn btn-primary" @click="create_open()">
        + {{ $vuetify.lang.t('$vuetify.add') }}
      </button>
      <work-types-item v-for="child in items.filter(el => el.parent === null)" :key="child.id"
                       :item="child"
                       :items="items"
                       @updateItem="update_open"
                       @createItem="create_open"
                       @deleteItem="deleteItem"

      ></work-types-item>
    </div>

    <v-dialog v-model="create_modal">
      <div class="modal">
        <div class="h1">
          <span v-if="action === 'add'">{{ $vuetify.lang.t('$vuetify.add') }}</span><span
          v-if="action === 'edit'">{{ $vuetify.lang.t('$vuetify.edit') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <template>
            <template v-for="lang in Object.keys($vuetify.lang.locales)">
              <v-text-field v-if="['eng', 'rus'].includes(lang)" :key=lang
                            v-model="newItem.name[lang]"
                            :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                            :rules="[$rules.required]"></v-text-field>
              <v-text-field v-else-if="!['en', 'ru'].includes(lang)" :key=lang v-model="newItem.name[lang]"
                            :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined></v-text-field>
            </template>
          </template>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="create_close">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addItem">
              {{ $vuetify.lang.t('$vuetify.save') }}
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-dialog v-model="alertError2">
      <div class="modal">
        {{alertMsg2}}
      </div>
    </v-dialog>


    <ConfirmModal v-model="confirm_modal" @confirm="deleteItem">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal";
import {mapActions} from "vuex";
import WorkTypesItem from "@/components/WorkTypesItem.vue";

export default {
  name: "SettingsView",
  components: {WorkTypesItem, ConfirmModal},


  data: function() {
    return {
      item: "work-types",
      url: "",
      title: "",
      action: "",

      items: [],
      newItem: {
        name: {},
      },
      currentItem: 0,
      create_modal: false,
      confirm_modal: false,

      alertError: false,
      alertMsg: '',
      alertError2: false,
      alertMsg2: '',
    }
  },

  created() {
    this.checkPermission().then(()=>{
      let title = this.item.split('-')
      if (title.length > 1)
        this.title = title[0] + title[1][0].toUpperCase() + title[1].slice(1)
      else
        this.title = this.item

      this.url = "time-tracking/work-types"
      this.loadData()
    })
  },

  methods: {
    ...mapActions(['checkPermission']),

    loadData() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.items = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },


    addItem() {
      if (this.$refs.form.validate()) {

        if (this.newItem.id) {
          this.editItem()
        } else {
          this.$axios({
            url: this.$hostname + this.url,
            method: "POST",
            headers: {
              Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: {
              name: JSON.stringify(this.newItem.name),
              parent: this.newItem.parent
            },
          }).then(() => {
            this.loadData()
            this.create_close()
          }).catch((response) => {
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      }
    },
    editItem() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.newItem.id,
          name: JSON.stringify(this.newItem.name)
        },
      }).then(() => {
        this.loadData()
        this.create_close()
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },


    deleteItem(id) {
      this.$axios({
        url: this.$hostname + this.url,
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {id: id},
      }).then(() => {
        this.confirm_modal = false
        this.loadData()
      }).catch((response) => {
        console.log(response)
        this.alertMsg2 = 'The type is in use in a chart'
        this.alertError2 = true
      })
    },

    update_open(id){
      this.newItem = this.items.find(el => el.id === id)

      this.create_modal = true;
      this.alertError = false
    },

    create_open(parent = null) {
      this.create_modal = true;
      this.alertError = false

        this.newItem = {
          name: {},
          parent
        }
    },

    create_close() {
      this.create_modal = false;
    },
  },
}
</script>

<style scoped>

</style>