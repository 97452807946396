var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.makeOfferToWorkerModal),callback:function ($$v) {_vm.makeOfferToWorkerModal=$$v},expression:"makeOfferToWorkerModal"}},[_c('div',{staticClass:"modal modal-big modal-offer"},[_c('h2',[(_vm.cv_id)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.hh.respond'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.hh.offer'))+" ")])]),_c('v-select',{staticClass:"custom",attrs:{"label":_vm.$vuetify.lang.t('$vuetify.menu.Vacancies'),"items":_vm.vacancies,"item-text":"name","item-value":"id","outlined":"","attach":"","menu-props":{
            bottom: true,
            offsetY: true,
            maxHeight: 200,
            contentClass: 'select-menu',
          }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.offer.vacancy),callback:function ($$v) {_vm.$set(_vm.offer, "vacancy", $$v)},expression:"offer.vacancy"}}),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.makeOfferToWorker}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.save'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }