var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.contact_create_modal),callback:function ($$v) {_vm.contact_create_modal=$$v},expression:"contact_create_modal"}},[_c('div',{staticClass:"modal"},[_c('div',{staticClass:"h1"},[(_vm.contact_action === 'add')?_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.addContact')))]):_vm._e(),(_vm.contact_action === 'edit')?_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.editContact')))]):_vm._e()]),_c('div',{staticClass:"h2"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.mainInfo')))]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.name'),"outlined":"","rules":[_vm.$rules.required]},model:{value:(_vm.newContact.name),callback:function ($$v) {_vm.$set(_vm.newContact, "name", $$v)},expression:"newContact.name"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.surname'),"outlined":"","rules":[_vm.$rules.required]},model:{value:(_vm.newContact.lastname),callback:function ($$v) {_vm.$set(_vm.newContact, "lastname", $$v)},expression:"newContact.lastname"}}),_c('v-autocomplete',{staticClass:"custom",attrs:{"label":_vm.$vuetify.lang.t('$vuetify.position'),"items":_vm.selectsPosition,"item-text":"name","item-value":"id","outlined":"","attach":"","menu-props":{
            bottom: true,
            offsetY: true,
            maxHeight: _vm.width<1024 ? 200 : 800,
            contentClass: 'select-menu',
          },"rules":[_vm.$rules.required]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name[_vm.$vuetify.lang.current] ? item.name[_vm.$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin'])+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name[_vm.$vuetify.lang.current] ? item.name[_vm.$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin'])+" ")]}}]),model:{value:(_vm.newContact.position),callback:function ($$v) {_vm.$set(_vm.newContact, "position", $$v)},expression:"newContact.position"}}),(_vm.$parent.$parent.type!=='client')?_c('v-autocomplete',{staticClass:"custom",attrs:{"label":_vm.$vuetify.lang.t('$vuetify.company'),"items":_vm.clients,"item-text":"name","item-value":"id","outlined":"","attach":"","menu-props":{
            bottom: true,
            offsetY: true,
            maxHeight:  _vm.width<1024 ? 200 : 800,
            contentClass: 'select-menu',
          },"rules":[_vm.$rules.required]},model:{value:(_vm.newContact.client),callback:function ($$v) {_vm.$set(_vm.newContact, "client", $$v)},expression:"newContact.client"}}):_vm._e(),_c('v-file-input',{staticClass:"file-input",attrs:{"accept":".jpg, .png, .jpeg","placeholder":_vm.$vuetify.lang.t('$vuetify.uploadImg'),"prepend-icon":"$photo"},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}}),_c('div',{staticClass:"h2"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.menu.contacts')))]),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.phone'),"outlined":"","rules":[_vm.$rules.required]},model:{value:(_vm.newContact.phone),callback:function ($$v) {_vm.$set(_vm.newContact, "phone", $$v)},expression:"newContact.phone"}}),_c('v-text-field',{attrs:{"label":"Email","outlined":"","rules":[_vm.$rules.required, _vm.$rules.email, _vm.$rules.notSpaces]},model:{value:(_vm.newContact.email),callback:function ($$v) {_vm.$set(_vm.newContact, "email", $$v)},expression:"newContact.email"}}),_c('v-progress-linear',{class:{form__progress : _vm.form_loader},attrs:{"active":_vm.form_loader,"indeterminate":"","rounded":"","height":"6"}}),(_vm.alertError)?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.alertMsg))]):_vm._e(),_c('div',{staticClass:"sign-up__buttons btn-group"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){_vm.contact_create_modal=false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.uploadFile}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.save'))+" ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }