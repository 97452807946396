<template>
  <div class="auth">
    <div class="auth__img">
      <img src="../../assets/img/bg.svg" alt="background"/>
    </div>
    <div class="auth-form">
      <div class="cards__single">
        <h1 class="auth-form__title">{{ $vuetify.lang.t('$vuetify.account') }}</h1>
        <div class="auth-form__tabs">
          <div
            class="tab"
            @click="tab = 'login'"
            :class="{ active: tab === 'login' }"
          >
            <h2 class="tab__header">{{ $vuetify.lang.t('$vuetify.auth') }}</h2>
          </div>
          <div v-if="!app"
               class="tab"
               @click="tab = 'register'"
               :class="{ active: tab === 'register' }"
          >
            <h2 class="tab__header">{{ $vuetify.lang.t('$vuetify.registration') }}</h2>
          </div>
        </div>
        <div class="auth-form__content">
          <div v-if="tab === 'register'" class="mb-5">
            <button class="btn btn-secondary"
                    @click="$router.push({'name': 'SignUpTenantLayout'})">
              {{ $vuetify.lang.t('$vuetify.rentPlatform') }}
            </button>
          </div>
          <v-form ref="auth" @submit.prevent="">
            <v-text-field
              v-model="user.login"
              label="E-mail"
              name="Email"
              outlined
              :rules="[$rules.required, $rules.email, $rules.notSpaces]"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              :label="$vuetify.lang.t('$vuetify.password')"
              name="password"
              outlined
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[$rules.required, $rules.min, $rules.notSpaces]"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            ></v-text-field>
            <a class="link auth-form__forget" href="/password/reset"
               v-if="tab === 'login'">{{ $vuetify.lang.t('$vuetify.forget') }}</a>
            <div v-if="tab === 'login'" class="auth-form__login">
              <button class="btn btn-primary" @click="setLogin">{{ $vuetify.lang.t('$vuetify.enter') }}</button>
              <v-checkbox class="auth-form__checkbox" v-model="checkbox"
                          :label="$vuetify.lang.t('$vuetify.stayIn')"></v-checkbox>
            </div>
            <div v-if="tab === 'register'" class="auth-form__register">
              <button class="btn btn-primary" @click="addUser">
                {{ $vuetify.lang.t('$vuetify.create') }}
              </button>
            </div>
          </v-form>
          <div v-if="alertError" class="error--text">
            {{ alertMsg }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",

  data: function () {
    return {
      tab: "login",
      user: {
        id: 0,
        login: "",
        password: "",
      },
      checkbox: true,
      show: false,
      alertError: false,
      alertMsg: "",
      app: localStorage.getItem('app')
    };
  },

  created() {
    if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
      this.$router.push({name: "main"});
    } else if (this.$route.query.t) {
      if (this.$route.query.save)
        localStorage.setItem(
          "auth_token",
          this.$route.query.t
        );
      else
        sessionStorage.setItem(
          "auth_token",
          this.$route.query.t
        );
      this.$router.push({name: "main"});
    }
  },

  methods: {
    setLogin() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "auth/token/login",
          // url: this.$hostname + "time-tracking/login",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: this.user.login.toLowerCase().replace(" ", ""),
            password: this.user.password,
          },
        }).then((response) => {
          // if (response.data.data.domains.includes(window.location.hostname, 0) || response.data.data.domains.length === 0) {
          if (this.checkbox)
            localStorage.setItem(
              "auth_token",
              response.data.data.attributes.auth_token
              // response.data.data.auth_token
            );
          else
            sessionStorage.setItem(
              "auth_token",
              response.data.data.attributes.auth_token
              // response.data.data.auth_token
            );
          if (this.tab === "login") {
            this.$axios({
              url: this.$hostname + "time-tracking/user-companies",
              method: "GET",
              headers: {
                "Authorization": "Token " + response.data.data.attributes.auth_token
              },
            }).then((response) => {
              let organizations = response.data.data.organizations
              if (organizations.length === 1) {
                localStorage.setItem("organization", JSON.stringify(organizations[0]));
                this.$router.push({name: "main"});
              } else if (organizations.length === 0) {
                localStorage.setItem("organization", JSON.stringify({id: 0, name: ''}));
                this.$router.push({name: "main"});
              } else {
                this.$router.push({name: "SelectCompany"})
              }
            }).catch((response) => {
              this.alertMsg = this.$catch(response)
            })
          } else {
            localStorage.setItem("organization", JSON.stringify({id: 0, name: ''}));
            this.$router.push({name: "SignUpLayout", params: {mode: 'sign-up', id: this.user.id}});
          }
          // } else if (response.data.data.domains.length === 1) {
          //   window.location.href = window.location.protocol + '//' + response.data.data.domains[0] + ':' + window.location.port + '/login?t=' + response.data.data.auth_token + '&save=' + this.checkbox// redirect
          // } else {
          //   this.$router.push({name: "SelectCompany", query: {t: response.data.data.auth_token, save: this.checkbox}})
          // }
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true;
        });
      }
    },
    addUser() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "time-tracking/register-user",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: this.user.login.toLowerCase().replace(" ", ""),
            password: this.user.password,
          },
        }).then((response) => {
          this.user.id = response.data.data.id;
          this.setLogin()
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true;
        });
      }
    },
  },
};
</script>

<style scoped lang="sass"></style>
