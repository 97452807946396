<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.49985 2.99976C9.54518 2.99958 10.5689 3.2973 11.4511 3.85804C12.3333 4.41879 13.0375 5.21933 13.4811 6.16588C13.9246 7.11243 14.0893 8.16579 13.9557 9.20256C13.8221 10.2393 13.3959 11.2165 12.7269 12.0198L16.8539 16.1458C16.9424 16.2339 16.9948 16.3521 17.0006 16.477C17.0063 16.6018 16.9651 16.7243 16.8851 16.8203C16.805 16.9163 16.6919 16.9789 16.568 16.9956C16.4442 17.0123 16.3185 16.9821 16.2159 16.9108L16.1459 16.8538L12.0199 12.7268C11.3411 13.2918 10.5361 13.6851 9.6732 13.8734C8.81027 14.0616 7.91469 14.0393 7.06221 13.8082C6.20974 13.5772 5.42544 13.1442 4.77567 12.546C4.12589 11.9478 3.62975 11.2018 3.3292 10.3713C3.02865 9.54079 2.93254 8.65011 3.04899 7.77459C3.16545 6.89907 3.49104 6.06447 3.99823 5.34139C4.50543 4.6183 5.1793 4.028 5.96286 3.62041C6.74642 3.21282 7.61662 2.99994 8.49985 2.99976ZM8.49985 3.99976C7.30638 3.99976 6.16178 4.47386 5.31787 5.31778C4.47396 6.16169 3.99985 7.30628 3.99985 8.49976C3.99985 9.69323 4.47396 10.8378 5.31787 11.6817C6.16178 12.5257 7.30638 12.9998 8.49985 12.9998C9.69333 12.9998 10.8379 12.5257 11.6818 11.6817C12.5257 10.8378 12.9999 9.69323 12.9999 8.49976C12.9999 7.30628 12.5257 6.16169 11.6818 5.31778C10.8379 4.47386 9.69333 3.99976 8.49985 3.99976Z" fill="#557EAE"/>
  </svg>
</template>

<script>
export default {
  name: "SearchIcon"
}
</script>

<style scoped>

</style>