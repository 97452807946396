
let id = 0;

const generateId = () => {
    return id++;
}

export function loadImage(file) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
            resolve(img);
        };
        img.src = file;
    });
}

export function map(
    x,
    in_min,
    in_max,
    out_min,
    out_max,
) {
    return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}


export class Painter {
    // eslint-disable-next-line no-unused-vars
    draw(_) {
    }

    // eslint-disable-next-line no-unused-vars
    isIntersect(_, _1, _2, _3) {
    }
}

export class ColoredPath extends Painter {
    path;
    color;
    size;
    id;

    constructor(path, color, size) {
        super();
        this.path = path;
        this.color = color;
        this.size = size;
        this.id = generateId();
    }

    draw(context) {
        context.lineCap = 'round';
        context.fillStyle = this.color;
        context.strokeStyle = this.color;
        context.lineWidth = this.size;
        context.stroke(this.path);
    }

    // eslint-disable-next-line no-unused-vars
    isIntersect(context, x, y, _) {
        return context.isPointInPath(this.path, x, y);
    }
}

export class ColoredText extends Painter {
    text;
    size;
    color;
    x;
    y;
    id;

    constructor(text, size, color, x, y, id) {
        super();
        this.text = text;
        this.size = size;
        this.color = color;
        this.x = x;
        this.y = y;
        this.id = id ?? generateId();
    }

    draw(context) {
        context.lineWidth = 1;
        context.font = `bold ${this.size}px serif`;
        context.fillStyle = this.color;
        context.strokeStyle = this.color;
        context.fillText(this.text, this.x, this.y);
    }

    // eslint-disable-next-line no-unused-vars
    isIntersect(context, x, y, editor) {
        const isIntersectX = x > this.x && x < this.x + (this.size * this.text.length * 0.5)
        const isIntersectY = y > this.y - this.size * 0.8 && y < this.y

        return isIntersectY && isIntersectX
    }
}

export class ImageEditor {
    background;
    paths;
    canvas;
    history;

    constructor(canvas) {
        this.canvas = canvas;
        this.paths = []
        this.history = []
    }

    createBackground(image) {
        this.background = image;
        this.paths = [];
        this.history = [];
        this.draw();
    }

    draw() {
        const context = this.canvas.getContext('2d');

        if (!context) return;
        if (!this.background) return;

        this.canvas.width = this.background.width;
        this.canvas.height = this.background.height;
        context.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
        context.drawImage(this.background, 0, 0);

        for (const path of this.paths) {
            path.draw(context);
        }
    }

    add(node) {
        const context = this.canvas.getContext('2d');

        if (!context) return;

        this.paths.push(node);
        this.history.push(() => {
            this.paths.pop();
        })
        context.beginPath();
        node.draw(context);
    }

    removeLast() {
        this.history.at(-1)();
        this.history.pop()
        this.draw();
    }

    clearAll() {
        this.paths = [];
        this.background = null;
        this.canvas.getContext('2d')?.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    addPointToPath(x, y) {
        const path = this.paths.at(-1);
        const context = this.canvas.getContext('2d');
        if (!context) return;

        if (!(path instanceof ColoredPath)) {
            return;
        }

        path.path.lineTo(x, y);
        context.lineTo(x, y);
        context.stroke();
    }

    endPoint() {
        this.canvas.getContext('2d')?.closePath();
    }

    mapByImage(x, y) {
        if (!this.background) throw Error('');

        return [
            map(x, 0, this.canvas.clientWidth, 0, this.background?.width),
            map(y, 0, this.canvas.clientHeight, 0, this.background?.height),
        ];
    }

    tryRemoveByPoint(x, y) {
        const context = this.canvas.getContext('2d');
        if (!context) return;

        const target = this.paths.findIndex((e) => {
            return e.isIntersect(context, x, y, this);
        });

        if (target === -1) return;

        this.history.push(
            ((node) => {
                return () => {
                    this.paths.push(node)
                    this.draw()
                }
            })(this.paths[target])
        )


        this.paths.splice(target, 1);
        this.draw();
    }

    getIntersectText(x, y) {
        const context = this.canvas.getContext('2d');
        if (!context) return;

        return this.paths.find((e) => {
            return e instanceof ColoredText && e.isIntersect(context, x, y, this);
        });
    }

    replaceById(id, node) {
        const index = this.paths.findIndex(e => e.id === id);

        if (index === -1) return;

        const oldNode = this.paths[index]
        this.paths.splice(index, 1);
        this.paths.push(node);

        this.history.push(() => {
            this.paths.pop();
            this.paths.push(oldNode)
            this.draw()
        })
        this.draw()
    }
}