<template>
  <div class="page-wrapper"
       v-if="permissions.read.indexOf('Диаграмма') != -1"
  >
    <div class="page-head page-head--mobile-wrap">
      <div class="breadcrumbs">

        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.menu.charts') }}
        </div>
      </div>

      <div class="page-head__buttons">
        <button class="btn-secondary btn" @click="arch = !arch">
          <span v-if="!arch">{{ $vuetify.lang.t('$vuetify.archive') }}</span>
          <span v-else>{{ $vuetify.lang.t('$vuetify.outArchive') }}</span>
        </button>
        <button
            v-if="permissions.edit.indexOf('Диаграмма') != -1"
            class="btn-secondary btn"
            @click="form_modal = true"
        >
          + {{ $vuetify.lang.t('$vuetify.add') }}
        </button>
      </div>
    </div>
    <div class="dgantts" id="dgantts-top">
      <div class="dgantts__item mt-5" v-for="object in objects" :key="object.id">
        <div class="dgantts__item-header">
          <button class="btn-action btn-icon" @click="toggleObject(object)">
            <svg :class="opened.includes(object.id) ? '' : 'rotate'" width="13" height="9" viewBox="0 0 13 9"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.9718 0.680664L6.49997 5.15254L2.02809 0.680664L0.555176 2.15358L6.49997 8.09837L12.4448 2.15358L10.9718 0.680664Z"
                  fill="#666"></path>
            </svg>
          </button>
          <h3>{{ object.name }}</h3>

          <button
              v-if="permissions.edit.indexOf('Диаграмма') != -1"
              class="ml-3 btn btn-secondary"
              @click="openDelete(object.id)">
            {{ $vuetify.lang.t('$vuetify.delete') }}

          </button>
        </div>
        <DganttComponent class="mt-3" v-if="opened.includes(object.id)" :object_id="object.id"></DganttComponent>
      </div>
    </div>

    <v-dialog v-model="form_modal">
      <div class="modal modal-height">

        <v-autocomplete v-model="newObject" clearable
                        :label="$vuetify.lang.t('$vuetify.objectName')"
                        :items="objectsToAdd" item-text="name" item-value="id"
                        :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        maxHeight: 200,
                                        contentClass: 'select-menu',
                                    }" outlined hide-details attach>
          <template v-slot:selection="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>

        <div class="mt-auto">
          <button class="btn btn-primary mt-5" @click.stop="addObject">
            {{ $vuetify.lang.t('$vuetify.save') }}
          </button>
        </div>
      </div>
    </v-dialog>

    <ConfirmModal v-model="confirm_modal" @confirm="deleteObjectTasks">
    </ConfirmModal>
  </div>
</template>

<script>
import DganttComponent from "@/components/DGantt.vue";
import {mapGetters} from "vuex";
import ConfirmModal from "@/components/confirmModal.vue";

export default {
  name: "DganttList",
  components: {ConfirmModal, DganttComponent},

  mounted() {
    this.loadObjects()
  },

  data: function () {
    return {
      form_modal: false,
      objects: [],
      objectsToAdd: [],

      newObject: {},
      opened: [],
      arch: false,
      toDelete: null,
      confirm_modal: false,
    }
  },

  methods: {
    openDelete(id){
      this.toDelete = id
      this.confirm_modal = true
    },
    deleteObjectTasks() {
      this.confirm_modal = false

      let id = this.toDelete
      this.$axios({
        url: this.$hostname + "time-tracking/work-schedule/objects",
        method: "DELETE",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id
        }
      }).then(() => {
        this.loadObjects()
      })
    },

    loadObjects() {
      this.$axios({
        url: this.$hostname + "time-tracking/work-schedule/objects",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          filter: {
            arch: this.arch
          }
        }
      }).then((response) => {
        this.objects = response.data.data.with_tasks
        this.objectsToAdd = response.data.data.without_tasks
      })
    },

    toggleObject(object) {
      if (this.opened.includes(object.id)) {
        this.opened = this.opened.filter(id => id != object.id)
      } else {
        this.opened.push(object.id)
      }

    },

    addObject() {
      this.form_modal = false
      this.objects.unshift(this.objectsToAdd.find(el => el.id = this.newObject))
      this.objectsToAdd = this.objectsToAdd.filter(el => el.id = this.newObject)
      this.newObject = {}
      document.getElementById('dgantts-top').scrollIntoView();
    }
  },

  watch: {
    arch(){
      this.loadObjects()
    }
  },

  computed:{
    ...mapGetters(['permissions'])
  }
}
</script>
