import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLayout from "@/views/layouts/AuthLayout";
import SignUpLayout from "@/views/layouts/SignUpLayout";
import IndexLayout from "@/views/layouts/IndexLayout";
import NewsList from "@/views/NewsList";
import NewsView from "@/views/open/NewsView";
import NewsAdd from "@/views/NewsAdd";
import WorkersList from "@/views/WorkersList";
import ProfileView from "@/views/open/ProfileView";
import ClientsList from "@/views/ClientsList";
import ClientView from "@/views/open/ClientView";
import ContactsList from "@/views/ContactsList";
import ObjectView from "@/views/open/ObjectView"
import ObjectsList from "@/views/ObjectsList"
import MainView from "@/views/open/MainView"
import CalendarView from "@/views/open/CalendarView";
import DocumentsList from "@/views/DocumentsList"
import ResetPasswordLayout from "@/views/layouts/ResetPasswordLayout";
import BookkeepingView from "@/views/BookkeepingView"
import FaqView from "@/views/FaqView"
import TimingList from "@/views/TimingList";
import SettingsList from "@/views/SettingsList";
import SettingsView from "@/views/open/SettingsView";
import GroupsView from "@/views/open/GroupsView";
import addBookkeeping from "@/views/addBookkeeping";
import NotFoundLayout from "@/views/layouts/NotFoundLayout";
import GarageList from "@/views/GarageList";
import GarageView from "@/views/open/GarageView";
import UsefulLinksList from "@/views/UsefulLinksList";
import AbsenceList from "@/views/AbsenceList";
import TechnicalWorksLayout from "@/views/layouts/TechnicalWorksLayout";
import TodoList from "@/views/TodoList";
import CatalogList from "@/views/CatalogList";
import SignUpTenantLayout from "@/views/layouts/SignUpTenantLayout";
import AddOrder from "@/views/AddOrder";
import BusinessCardsList from "@/views/BusinessCardsList";
import TenantsView from "@/views/TenantsView";
import TariffPlansView from "@/views/TariffPlansView";
import OrganizationsList from "@/views/OrganizationsList";
import SettingsCompanyProfileView from "@/views/open/SettingsCompanyProfileView";
import SuccessLayout from "@/views/layouts/SuccessLayout";
import ErrorLayout from "@/views/layouts/ErrorLayout";
import SafeView from "@/views/open/SafeView";
import NotificationsList from "@/views/NotificationsList";
import SelectCompanyLayout from "@/views/layouts/SelectCompanyLayout.vue";
import TimingChangesList from "@/views/TimingChangesList.vue";
import DganttList from "@/views/DganttList.vue";
import HolidaySettingsView from "@/views/open/HolidaySettingsView.vue";
import WorkTypesView from "@/views/open/WorkTypesView.vue";
import LanguageSettingsView from "@/views/open/LanguageSettingsView.vue";
import PartnersList from "@/views/PartnersList.vue";
import CompanyProfileView from "@/views/open/CompanyProfileView.vue";
import PrivacyPolicyLayout from "@/views/layouts/PrivacyPolicyLayout.vue";
import DeleteAccountLayout from "@/views/layouts/DeleteAccountLayout.vue";
import CVsView from "@/views/CVsView.vue";
import CVView from "@/views/open/CVView.vue";
import MyVacancies from "@/views/MyVacancies.vue";
import VacancyView from "@/views/open/VacancyView.vue";
import VacanciesView from "@/views/VacanciesView.vue";
import RespondsView from "@/views/RespondsView.vue";
import RespondView from "@/views/open/RespondView.vue";
import FindOrganizationsList from "@/views/FindOrganizationsList.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: "",
        name: 'IndexLayout',
        component: IndexLayout,
        children: [
            {
                path: "",
                component: MainView,
                name: 'main'
            },
            {
                path: "/news",
                component: NewsList,
                name: 'news'
            },
            {
                path: "/news/:id",
                component: NewsView,
                props: true,
                name: 'news-item'

            },
            {
                path: "/newsAdd",
                component: NewsAdd,
                props: true,
                name: 'news-add'

            },
            {
                path: "/newsEdit/:id",
                component: NewsAdd,
                props: true,
                name: 'news-edit'
            },
            {
                path: "/workers",
                component: WorkersList,
                name: 'workers'

            },
            {
                path: "/workers/:id",
                component: ProfileView,
                props: true,
                name: 'workers-item'
            },
            {
                path: "/profile",
                component: ProfileView,
                props: true,
                name: 'profile'
            },
            {
                path: "/workers/:worker/timings",
                component: TimingList,
                props: true,
                name: "worker-timings",
            },
            {
                path: "/contacts",
                component: ContactsList,
                name: 'contacts'

            },
            {
                path: "/clients",
                component: ClientsList,
                name: 'clients'
            },
            {
                path: "/clients/:id",
                component: ClientView,
                props: true,
                name: 'clients-item'
            },
            {
                path: "/client-profile",
                component: ClientView,
                props: true,
                name: 'clients-profile'
            },
            {
                path: "/objects",
                component: ObjectsList,
                props: true,
                name: 'objects'
            },
            {
                path: "/objects/:id",
                component: ObjectView,
                props: true,
                name: 'objects-item'
            },
            {
                path: "/objects/:object/timings",
                component: TimingList,
                props: true,
                name: "object-timings",
            },
            {
                path: "/own-:mode",
                component: DocumentsList,
                props: true,
                name: 'waybills'
            },
            {
                path: "/own-:mode/new",
                component: addBookkeeping,
                props: true,
                name: 'addWaybill'
            },
            {
                path: "/own-:mode/new/:id",
                component: addBookkeeping,
                props: true,
                name: 'addWaybill'
            },
            {
                path: "/bookkeeping/",
                component: BookkeepingView,
                props: true,
                name: 'BookkeepingView'
            },
            {
                path: "/bookkeeping/absence",
                component: AbsenceList,
                props: true,
                name: 'documents-absence'
            },
            {
                path: "/bookkeeping/timings",
                component: TimingList,
                props: true,
                name: 'documents-timings'
            },
            {
                path: "/bookkeeping/timings-changes",
                component: TimingChangesList,
                props: true,
                name: 'timingChangesList'
            },
            {
                path: "/bookkeeping/:mode",
                component: DocumentsList,
                props: true,
                name: 'documents'
            },
            {
                path: "/bookkeeping/:mode/new",
                component: addBookkeeping,
                props: true,
                name: 'addBookkeeping'
            },
            {
                path: "/bookkeeping/:mode/new/:id",
                component: addBookkeeping,
                props: true,
                name: 'addBookkeeping'
            },
            {
                path: "/calendar",
                component: CalendarView,
                name: "calendar",
            },
            {
                path: "/timings",
                component: TimingList,
                name: "timings",
            },
            {
                path: "/charts",
                component: DganttList,
                name: "charts",
            },
            {
                path: "/settings",
                component: SettingsList,
                name: "settings",
            },
            {
                path: "/settings/groups",
                component: GroupsView,
                name: "groups",
            },
            {
                path: "/settings/company",
                component: SettingsCompanyProfileView,
                name: "company",
            },
            {
                path: "/settings/work-types",
                component: WorkTypesView,
                name: "company",
            },
            {
                path: "/settings/holidays",
                component: HolidaySettingsView,
                name: "holidays",
            },
            {
                path: "/settings/languages",
                component: LanguageSettingsView,
                name: "languages",
            },
            {
                path: "/settings/:item",
                component: SettingsView,
                name: "settings-item",
                props: true
            },
            {
                path: '/faq',
                component: FaqView,
                name: 'faq'
            },
            {
                path: '/garage',
                component: GarageList,
                name: 'garage'
            },
            {
                path: '/garage/:id',
                component: GarageView,
                name: 'garage-item',
                props: true
            },
            {
                path: '/useful-links',
                component: UsefulLinksList,
                name: 'useful'
            },
            {
                path: '/absence',
                component: AbsenceList,
                name: 'absence'
            },
            {
                path: '/todo',
                component: TodoList,
                name: 'todo'
            },
            {
                path: '/objects/:object/todo',
                component: TodoList,
                name: 'todo-object',
                props: true
            },
            {
                path: '/catalog',
                component: CatalogList,
                name: 'catalog'
            },
            {
                path: '/order/new/',
                component: AddOrder,
                name: 'addOrder',
                props: true
            },
            {
                path: '/order/edit/:id',
                component: AddOrder,
                name: 'editOrder',
                props: true
            },
            {
                path: '/businesscards',
                component: BusinessCardsList,
                name: 'businessCards',
                props: true
            },
            {
                path: '/tenants',
                component: TenantsView,
                name: 'tenants',
            },
            {
                path: '/tenants/tariff-plans',
                component: TariffPlansView,
                name: 'tariffPlans',
            },
            {
                path: '/tenants/organizations',
                component: OrganizationsList,
                name: 'organizations',
            },
            {
                path: '/safe',
                component: SafeView,
                name: 'safe',
            },
            {
                path: '/notifications',
                component: NotificationsList,
                name: 'notifications',
            },
            {
                path: "/partners",
                component: PartnersList,
                name: 'partners'
            },
            {
                path: "/partners/:id",
                component: CompanyProfileView,
                name: 'CompanyProfile'
            },
            {
                path: "/cvs",
                component: CVsView,
                name: 'CVs'
            },
            {
                path: "/cvs/:id",
                component: CVView,
                name: 'CV'
            },
            {
                path: "/my-vacancies/",
                component: MyVacancies,
                name: 'MyVacancies'
            },
            {
                path: "/vacancies/",
                component: VacanciesView,
                name: 'Vacancies'
            },
            {
                path: "/vacancies/:id",
                component: VacancyView,
                name: 'Vacancy'
            },
            {
                path: "/responds",
                component: RespondsView,
                name: 'MyResponds'
            },
            {
                path: "/responds/:id",
                component: RespondView,
                name: 'MyRespond'
            },
            {
                path: "/organization-responds",
                component: RespondsView,
                name: 'Responds'
            },
            {
                path: "/organization-responds/:id",
                component: RespondView,
                name: 'Respond'
            },
            {
                path: "/find-organizations",
                component: FindOrganizationsList,
                name: 'FindOrganizations'
            },
            {
                path: "/find-organizations/:id",
                component: CompanyProfileView,
                name: 'CompanyProfile'
            },


        ]

    },
    {
        path: '/success',
        name: 'SuccessLayout',
        component: SuccessLayout
    },
    {
        path: '/error',
        name: 'ErrorLayout',
        component: ErrorLayout
    },
    {
        path: '/login',
        name: 'AuthLayout',
        component: AuthLayout
    },
    {
        path: '/password/reset',
        name: 'ResetPassword',
        component: ResetPasswordLayout,
    },
    {
        path: '/password/reset/confirm/:uid/:token',
        name: 'ResetPassword',
        component: ResetPasswordLayout,
        props: true,
    },
    {
        path: '/signup/:id',
        name: 'SignUpLayout',
        component: SignUpLayout,
        props: true,
    },
    {
        path: '/signup-client',
        name: 'SignUpTenantLayout',
        component: SignUpTenantLayout,
        props: true,
    },
    {
        path: '/signup/:mode/:id',
        name: 'SignUpLayout',
        component: SignUpLayout,
        props: true,
    },
    {
        path: '/select-company',
        name: 'SelectCompany',
        component: SelectCompanyLayout,
    },
    {
        path: '/technical',
        name: 'TechnicalWorks',
        component: TechnicalWorksLayout,
    },
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyLayout,
    },
    {
        path: '/delete-account',
        name: 'DeleteAccount',
        component: DeleteAccountLayout,
    },
    {
        path: '*',
        name: '404',
        component: NotFoundLayout,
    },

]


const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
