<template>
  <div class="container">
    <div class="page page-404">
      <h1>{{ $vuetify.lang.t('$vuetify.technical') }}</h1>
      <div class="page-404__description">
        {{ $vuetify.lang.t('$vuetify.technicalTime') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnicalWorksLayout"
}
</script>

<style scoped>

</style>