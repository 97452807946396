import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "@/router";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Vuex from 'vuex'
import store from "@/store";
import axios from "axios";
import config from "./assets/config";

Vue.use(CoolLightBox)
Vue.use(Vuex)

async function loadLocales() {
    // const locales = Object.keys(vuetify.framework.lang.locales); // Получаем доступные локали из Vuetify

    try {
        let locale = localStorage.getItem('lang') ?? 'eng'
        // for (const locale of locales) {
            let locFile = locale
            if (locale === "eng") {
                locFile = "en"
            } else if (locale === "rus") {
                locFile = "ru"
            }
            const path = config.base_hostname + `static/languages/${locFile}.json`; // Используем шаблонную строку для динамического URL
            const response = await axios.get(path);
            vuetify.framework.lang.locales[locale] = response.data; // Обновляем локаль в Vuetify
        // }
        console.log('All locales loaded successfully');
    } catch (error) {
        console.error('Unable to load locales', error);
    }
}

// Вызываем загрузку локалей и инициализируем Vue после её завершения
loadLocales().then(() => {
    new Vue({
        store,
        router,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
});
