<template>
  <div class="container">
    <div class="page page-404">
      <div class="page-404__title">OK</div>
      <h1>{{ $vuetify.lang.t('$vuetify.success') }}</h1>
      <router-link to="/" class="btn btn-primary link-not-like">
        {{ $vuetify.lang.t('$vuetify.goMain') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessLayout"
}
</script>

<style scoped>

</style>