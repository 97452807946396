<template>
  <div class="settings-items__item-wrap">
    <div class="settings-items__item">
      <div class="flex">
      <button  class="settings-items__toggle-hide" @click.stop="toggleHide">

        <svg class="rotate" v-if="closed" width="13" height="9" viewBox="0 0 13 9" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M10.9718 0.680664L6.49997 5.15254L2.02809 0.680664L0.555176 2.15358L6.49997 8.09837L12.4448 2.15358L10.9718 0.680664Z"
              fill="currentcolor"></path>
        </svg>
        <svg v-if="!closed" width="13" height="9" viewBox="0 0 13 9" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M10.9718 0.680664L6.49997 5.15254L2.02809 0.680664L0.555176 2.15358L6.49997 8.09837L12.4448 2.15358L10.9718 0.680664Z"
              fill="currentcolor"></path>
        </svg>
      </button>
      <div class="settings-items__item-name">
        {{
          item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
        }}
      </div>

      </div>
      <div class="settings-items__buttons">
        <div class="settings-items__icon cp"
             @click="updateItem(item.id)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8934 3.38037C16.5059 2.769 17.3317 2.41924 18.1969 2.40476C19.0622 2.39029 19.8993 2.71224 20.5318 3.30278C21.1644 3.89333 21.543 4.70635 21.5879 5.57054C21.6328 6.43474 21.3405 7.28263 20.7726 7.93557L20.6166 8.10357L9.08941 19.6308C8.8437 19.8761 8.5477 20.0653 8.22181 20.1852L8.02381 20.2488L3.15781 21.576C3.06355 21.6019 2.96438 21.6045 2.86891 21.5834C2.77345 21.5623 2.68458 21.5182 2.61006 21.4549C2.53553 21.3916 2.4776 21.311 2.44131 21.2203C2.40502 21.1295 2.39146 21.0312 2.40181 20.934L2.42101 20.838L3.74821 15.9732C3.83941 15.6372 4.00261 15.3252 4.22581 15.0612L4.36621 14.9076L15.8934 3.38037ZM14.8614 6.11037L5.21461 15.756C5.10439 15.8664 5.01676 15.9972 4.95661 16.1412L4.90621 16.2888L3.85381 20.1408L7.70821 19.0908C7.80861 19.0634 7.90502 19.023 7.99501 18.9708L8.12341 18.8844L8.24101 18.7824L17.8866 9.13437L14.8614 6.11037ZM19.7682 4.22877C19.3908 3.85113 18.8859 3.6282 18.3526 3.60378C17.8193 3.57936 17.2961 3.7552 16.8858 4.09677L16.7418 4.22877L15.7098 5.26077L18.7338 8.28717L19.7682 7.25517C20.1459 6.8778 20.3688 6.37289 20.3932 5.83957C20.4176 5.30626 20.2418 4.78308 19.9002 4.37277L19.7682 4.22877Z"
                fill="#ADB2B7"/>
          </svg>
        </div>

        <div class="settings-items__icon cp"
             v-if="!children.length"
             @click="deleteItem(item.id)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L23 23M1 23L12 12L23 1" stroke="#6A9DDA" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="settings-items__list" v-if="!closed">
      <button class="btn btn-primary" @click="createItem(item.id)">
        + {{ $vuetify.lang.t('$vuetify.add') }}
      </button>
      <work-types-item v-for="child in children" :key="child.id"
                       :item="child"
                       :items="items"
                       @updateItem="updateItem"
                       @createItem="createItem"
                       @deleteItem="deleteItem"
      ></work-types-item>
    </div>
  </div>
</template>

<script>
export default {
  //; confirm_modal = true; alertError=false"
  name: "WorkTypesItem",
  
  props: ['item', 'items'],

  data: function () {
    return {
      closed: true
    }
  },

  methods: {
    createItem(parent){
      this.$emit('createItem', parent)
    },
    updateItem(id){
      this.$emit("updateItem", id)
    },
    deleteItem(id){
      this.$emit('deleteItem', id)
    },

    toggleHide(){
      this.closed = !this.closed
    }
  },

  computed: {
    children(){
      return this.items.filter(el => el.parent === this.item.id)
    }
  }

}
</script>