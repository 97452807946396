<template>
  <v-dialog v-model="makeOfferToCompanyModal">
    <div class="modal modal-big modal-offer">
      <h2>
          <span v-if="vacancy_id">
            {{ $vuetify.lang.t('$vuetify.hh.respond') }}
          </span>
        <span v-else>
            {{ $vuetify.lang.t('$vuetify.hh.offer') }}
          </span>
      </h2>


      <v-select v-model="offer.resume" class="custom"
                :label="$vuetify.lang.t('$vuetify.cv')"
                :items="cvs" item-text="name" item-value="id" outlined attach
                :menu-props="{
              bottom: true,
              offsetY: true,
              maxHeight: 200,
              contentClass: 'select-menu',
            }">
        <template v-slot:selection="{item }">
          {{ item.name }}
        </template>
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
      </v-select>
      <v-textarea v-model="offer.cover_letter"
                  :label="$vuetify.lang.t('$vuetify.hh.cover_letter')"
                  outlined>

      </v-textarea>
      <button class="btn btn-primary" @click="makeOfferToCompany">
        {{ $vuetify.lang.t('$vuetify.save') }}
      </button>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "make-offer-to-company-modal",

  props: {
    vacancy_id: [Number, null],
    organization_id: Number,
    value: {
      type: Boolean,
      required: true
    },
  },

  data: function () {
    return {
      makeOfferToCompanyModal: this.value,
      offer: {},

      cvs: [],
    }
  },

  methods: {
    loadCvs() {
      this.$axios({
        url: this.$hostname + "time-tracking/my-resume",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.cvs = response.data.data.data
        this.cvs.unshift({name: this.$vuetify.lang.t('$vuetify.hh.noCv'), id: null})
        this.addCVModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
      });
    },

    makeOfferToCompany() {
      this.$axios({
        url: this.$hostname + "time-tracking/make-offer-to-company",
        method: "POST",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          resume: this.offer.resume,
          organization: this.organization_id,
          vacancy: this.vacancy_id,
          cover_letter: this.offer.cover_letter
        },
        // eslint-disable-next-line no-unused-vars
      }).then((response) => {
        this.vacancyFormModal = false
        this.$router.push('/responds')
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
      }).finally(() => {
        this.form_loader = false
      });
    }
  },

  mounted() {
    this.loadCvs()
  },

  watch: {
    value(){
      this.makeOfferToCompanyModal = this.value
    },
    makeOfferToCompanyModal(){
      this.$emit("input", this.makeOfferToCompanyModal);
    },
  }

}
</script>
