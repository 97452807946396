<template>
  <v-app class="work24">
    <main>
      <router-view></router-view>
    </main>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import config from "./assets/config";

Vue.prototype.$axios = axios;
// Добавляем интерсептор для запросов
Vue.prototype.$axios.interceptors.request.use(config => {
  const excludeUrls = ['login', 'logout'];
  const urlPath = config.url.split('?')[0].split('/')[config.url.split('?')[0].split('/').lastIndex]; // Удаляем query params если они есть

  // Проверяем, не содержится ли текущий URL в списке исключений
  if (!excludeUrls.includes(urlPath) && localStorage.getItem('organization')) {
    // Добавляем параметр company к каждому запросу
    const id_company = JSON.parse(localStorage.getItem('organization')).id;
    if (config.params) {
      config.params['company'] = id_company;
    } else {
      config.params = {company: id_company};
    }
  }

  return config;
}, error => {
  // Обрабатываем ошибку
  console.log(error)
  return Promise.reject(error);
});
Vue.prototype.$hostname = config.url;
Vue.prototype.$baseHostname = config.base_hostname;
Vue.prototype.$media_url = config.media_url;
Vue.prototype.$host = config.host;
Vue.prototype.$tech = config.tech;
Vue.prototype.$rules = {
  required: (value) => !!value || "Required",
  requiredNumber: (value) => (String(value).length > 0 || value === 0) || "Required",
  text: (value) => /[^\d\s]/.test(value) || "Letters only",
  positiveNumbers: (v) => /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/.test(v) || "Positive numbers only",
  nullPositiveNumbers: (v) => /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/.test(v) || v === "" || v === 0 || v == null || "Positive or zero",
  email: (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Incorrect E-mail",
  nullEmail: (v) => v == undefined || v == "" || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Incorrect E-mail",
  fileSize: (value) => !value || value.size < 15000000 || 'File size should be less than 15 MB!' + value.size,
  multipleFileSize: (v) => !v || !v.some(file => file.size > 10000000) || 'File size should be less than 10 MB! ',
  domain_length: v => v.length <= 63 || 'Input must be less than 64 characters',
  domain: v => /^[a-zA-Z_][a-zA-Z0-9_]*$/.test(v) || 'Input must start with a letter or underscore, the rest can contain letters, digits, and underscores',
  min: (v) => v.length >= 8 || "Min 8 characters",
  notSpaces: value => {
    if (/\s/.test(value)) {
      return 'Spaces are not allowed.';
    }
    return true;
  },
};
Vue.prototype.$editorInit = {
  menubar: false,
  plugins: [
    "paste advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "bold italic underline backcolor link smiles",
  contextmenu: false,
  setup: (editor) => {
    editor.ui.registry.addMenuButton('smiles', {
      text: '😀',
      fetch: (callback) => {
        const items = [
          {
            type: 'menuitem',
            text: '😀',
            onAction: () => editor.insertContent('😀')
          },
          {
            type: 'menuitem',
            text: '😁',
            onAction: () => editor.insertContent('😁')
          },
          {
            type: 'menuitem',
            text: '😉',
            onAction: () => editor.insertContent('😉')
          },
          {
            type: 'menuitem',
            text: '😂',
            onAction: () => editor.insertContent('😂')
          },
          {
            type: 'menuitem',
            text: '😎',
            onAction: () => editor.insertContent('😎')
          },
          {
            type: 'menuitem',
            text: '😊',
            onAction: () => editor.insertContent('😊')
          },
          {
            type: 'menuitem',
            text: '🤗',
            onAction: () => editor.insertContent('🤗')
          },
          {
            type: 'menuitem',
            text: '🙁',
            onAction: () => editor.insertContent('🙁')
          },
          {
            type: 'menuitem',
            text: '😢',
            onAction: () => editor.insertContent('😢')
          },
          {
            type: 'menuitem',
            text: '🤬',
            onAction: () => editor.insertContent('🤬')
          },
          {
            type: 'menuitem',
            text: '😡',
            onAction: () => editor.insertContent('😡')
          },
          {
            type: 'menuitem',
            text: '🤔',
            onAction: () => editor.insertContent('🤔')
          },
          {
            type: 'menuitem',
            text: '😱',
            onAction: () => editor.insertContent('😱')
          },
          {
            type: 'menuitem',
            text: '🤨',
            onAction: () => editor.insertContent('🤨')
          },
          {
            type: 'menuitem',
            text: '😍',
            onAction: () => editor.insertContent('😍')
          },
          {
            type: 'menuitem',
            text: '🙄',
            onAction: () => editor.insertContent('🙄')
          },
          {
            type: 'menuitem',
            text: '😪',
            onAction: () => editor.insertContent('😪')
          },
          {
            type: 'menuitem',
            text: '😷',
            onAction: () => editor.insertContent('😷')
          },
          {
            type: 'menuitem',
            text: '🤢',
            onAction: () => editor.insertContent('🤢')
          },
          {
            type: 'menuitem',
            text: '🤑',
            onAction: () => editor.insertContent('🤑')
          },
          {
            type: 'menuitem',
            text: '👌',
            onAction: () => editor.insertContent('👌')
          },
          {
            type: 'menuitem',
            text: '👍',
            onAction: () => editor.insertContent('👍')
          },
          {
            type: 'menuitem',
            text: '👎',
            onAction: () => editor.insertContent('👎')
          },
          {
            type: 'menuitem',
            text: '👊',
            onAction: () => editor.insertContent('👊')
          },
          {
            type: 'menuitem',
            text: '✊',
            onAction: () => editor.insertContent('✊')
          },
          {
            type: 'menuitem',
            text: '🖐',
            onAction: () => editor.insertContent('🖐')
          },
          {
            type: 'menuitem',
            text: '🤛',
            onAction: () => editor.insertContent('🤛')
          },
          {
            type: 'menuitem',
            text: '🤜',
            onAction: () => editor.insertContent('🤜')
          },
          {
            type: 'menuitem',
            text: '👏',
            onAction: () => editor.insertContent('👏')
          },
          {
            type: 'menuitem',
            text: '🌹️',
            onAction: () => editor.insertContent('🌹️')
          },
          {
            type: 'menuitem',
            text: '☀️',
            onAction: () => editor.insertContent('☀️')
          },
          {
            type: 'menuitem',
            text: '🌤️',
            onAction: () => editor.insertContent('🌤️')
          },
          {
            type: 'menuitem',
            text: '⛈️',
            onAction: () => editor.insertContent('⛈️')
          },
          {
            type: 'menuitem',
            text: '🌧️',
            onAction: () => editor.insertContent('🌧️')
          },
          {
            type: 'menuitem',
            text: '☁️',
            onAction: () => editor.insertContent('☁️')
          },
          {
            type: 'menuitem',
            text: '❄️',
            onAction: () => editor.insertContent('❄️')
          },
          {
            type: 'menuitem',
            text: '⛄️',
            onAction: () => editor.insertContent('⛄️')
          },
          {
            type: 'menuitem',
            text: '🔥',
            onAction: () => editor.insertContent('🔥')
          },
          {
            type: 'menuitem',
            text: '⚡️',
            onAction: () => editor.insertContent('⚡️')
          },
          {
            type: 'menuitem',
            text: '🥇',
            onAction: () => editor.insertContent('🥇')
          },
          {
            type: 'menuitem',
            text: '🚓',
            onAction: () => editor.insertContent('🚓')
          },
          {
            type: 'menuitem',
            text: '🚕',
            onAction: () => editor.insertContent('🚕')
          },
          {
            type: 'menuitem',
            text: '🚘',
            onAction: () => editor.insertContent('🚘')
          },
          {
            type: 'menuitem',
            text: '🏠',
            onAction: () => editor.insertContent('🏠')
          },
          {
            type: 'menuitem',
            text: '📱',
            onAction: () => editor.insertContent('📱')
          },
          {
            type: 'menuitem',
            text: '🖥️',
            onAction: () => editor.insertContent('🖥️')
          },
          {
            type: 'menuitem',
            text: '💰',
            onAction: () => editor.insertContent('💰')
          },
          {
            type: 'menuitem',
            text: '🧨',
            onAction: () => editor.insertContent('🧨')
          },
          {
            type: 'menuitem',
            text: '📦',
            onAction: () => editor.insertContent('📦')
          },
          {
            type: 'menuitem',
            text: '❤️',
            onAction: () => editor.insertContent('❤️')
          },
          {
            type: 'menuitem',
            text: '❌',
            onAction: () => editor.insertContent('❌')
          },
          {
            type: 'menuitem',
            text: '⛔️',
            onAction: () => editor.insertContent('⛔️')
          },
          {
            type: 'menuitem',
            text: '🚷',
            onAction: () => editor.insertContent('🚷')
          },
          {
            type: 'menuitem',
            text: '💯',
            onAction: () => editor.insertContent('💯')
          },
          {
            type: 'menuitem',
            text: '✅',
            onAction: () => editor.insertContent('✅')
          },
          {
            type: 'menuitem',
            text: '♻️',
            onAction: () => editor.insertContent('♻️')
          },
          {
            type: 'menuitem',
            text: '🚾',
            onAction: () => editor.insertContent('🚾')
          },
          {
            type: 'menuitem',
            text: '🆗',
            onAction: () => editor.insertContent('🆗')
          },
          {
            type: 'menuitem',
            text: '💶',
            onAction: () => editor.insertContent('💶')
          },
          {
            type: 'menuitem',
            text: '⚫️',
            onAction: () => editor.insertContent('⚫️')
          },
          {
            type: 'menuitem',
            text: '▪️',
            onAction: () => editor.insertContent('▪️')
          },
          {
            type: 'menuitem',
            text: '🔹',
            onAction: () => editor.insertContent('🔹')
          },
          {
            type: 'menuitem',
            text: '🔸',
            onAction: () => editor.insertContent('🔸')
          },
          {
            type: 'menuitem',
            text: '🇪🇪️️️',
            onAction: () => editor.insertContent('🇪🇪️')
          },
          {
            type: 'menuitem',
            text: '🇷🇺',
            onAction: () => editor.insertContent('🇷🇺')
          },
          {
            type: 'menuitem',
            text: '🇺🇦',
            onAction: () => editor.insertContent('🇺🇦')
          },
          {
            type: 'menuitem',
            text: '🇱🇻',
            onAction: () => editor.insertContent('🇱🇻')
          },
          {
            type: 'menuitem',
            text: '🇫🇮',
            onAction: () => editor.insertContent('🇫🇮')
          },
          {
            type: 'menuitem',
            text: '🇱🇹',
            onAction: () => editor.insertContent('🇱🇹')
          },
          {
            type: 'menuitem',
            text: '🇸🇪',
            onAction: () => editor.insertContent('🇸🇪')
          },
          {
            type: 'menuitem',
            text: '🇳🇴',
            onAction: () => editor.insertContent('🇳🇴')
          },
          {
            type: 'menuitem',
            text: '🇪🇺',
            onAction: () => editor.insertContent('🇪🇺')
          },
        ];
        callback(items);
      }
    })
  },
}

Vue.prototype.$catch = function (response) {
  let alertMsg;
  if (!response.response || !response.response.status) {
    return
  }
  switch (response.response.status) {
    case 500:
      alertMsg = this.$vuetify.lang.t("$vuetify.error500");
      break;
    case 400:
      alertMsg = response.response.data.errors;
      if (response.response.data.errors === 'worker_limit') {
        alertMsg = this.$vuetify.lang.t('$vuetify.workerLimitError')
      } else if (Array.isArray(response.response.data.errors)) {
        alertMsg = response.response.data.errors[0].detail;
      } else if (response.response.data.errors && typeof response.response.data.errors === "object") {
        const errors = response.response.data.errors;
        const errorMessages = [];

        // Check if errors is an object or an array
        if (Array.isArray(errors)) {
          // Handle error object format: [{ "field_name": ["error_message"] }]
          for (const error of errors) {
            const fieldErrors = Object.entries(error);
            for (const [field, messages] of fieldErrors) {
              if (Array.isArray(messages)) {
                const formattedMessages = messages.map(message => `${field} - ${message}`);
                errorMessages.push(...formattedMessages);
              } else {
                errorMessages.push(`${field} - ${messages}`);
              }
            }
          }
        } else {
          // Handle error object format: { "field_name": ["error_message"] }
          const fieldErrors = Object.entries(errors);
          for (const [field, messages] of fieldErrors) {
            if (Array.isArray(messages)) {
              const formattedMessages = messages.map(message => `${field} - ${message}`);
              errorMessages.push(...formattedMessages);
            } else {
              errorMessages.push(`${field} - ${messages}`);
            }
          }
        }

        alertMsg = errorMessages.join("\n");
      }
      break;
    case 401:
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({name: "AuthLayout"});
      break;
    case 403:
      alertMsg = this.$vuetify.lang.t('$vuetify.permissionError');
      this.$router.push({name: '404'})
      break;
    case 404:
      alertMsg = this.$vuetify.lang.t('$vuetify.404');
      this.$router.push({name: '404'})
      break;
    case 405:
      alertMsg = this.$vuetify.lang.t('$vuetify.methodNotAllowedError');
      break;
    default:
      alertMsg = this.$vuetify.lang.t('$vuetify.unexpectedError');
  }
  return alertMsg;
};


Vue.prototype.$saveForm = function (field, data) {
  localStorage.setItem(field, JSON.stringify(data))
}
Vue.prototype.$loadForm = function (field) {
  if (localStorage.getItem(field)) {
    return JSON.parse(localStorage.getItem(field))
  } else {
    return {}
  }
}
Vue.prototype.$clearForm = function (field) {
  localStorage.removeItem(field)
}

Vue.prototype.$toggleTheme = function (color = null, language = null) {
  if (language != null) {
    switch (language) {
      case 'ru':
        language = 'rus'
        break
      case 'en':
        language = 'eng'
        break
    }
    this.$vuetify.lang.current = language;
    localStorage.setItem("lang", language)
  }
  switch (color) {
    case "dark":
      localStorage.setItem("theme", "dark")
      require("./assets/sass/colors/dark.sass");
      document.documentElement.setAttribute('data-theme', 'dark')
      break;
    case "light":
      localStorage.setItem("theme", "light")
      document.documentElement.setAttribute('data-theme', 'light')
      require("./assets/sass/colors/light.sass");
      break;
    case "green":
      localStorage.setItem("theme", "green")
      document.documentElement.setAttribute('data-theme', 'green')
      require("./assets/sass/colors/green.sass");
      break;
  }
  if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
    this.$axios({
      url: this.$hostname + "time-tracking/user-settings",
      method: "PUT",
      headers: {
        Authorization:
          "Token " +
          (localStorage.getItem("auth_token") ||
            sessionStorage.getItem("auth_token")),
        "Content-Type": "multipart/form-data",
      },
      data: {
        language: language,
        theme: color
      }
    }).then(() => {
      this.lang_modal = false
    }).catch((response) => {
      this.alertMsg = this.$catch(response)
      this.alertError = true
    })
  }
};

export default {
  name: "App",

  components: {},

  created() {
    this.$vuetify.lang.current = 'eng';
    this.$vuetify.theme.current = 'light';
    require("./assets/sass/colors/light.sass");
    if (localStorage.getItem("theme") || localStorage.getItem("lang"))
      this.$toggleTheme(localStorage.getItem("theme") || 'light', localStorage.getItem("lang") || this.$vuetify.lang.current)
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    (function init100vh() {
      function setHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }

      setHeight();
      window.addEventListener('resize', setHeight);
    })();
    document.addEventListener('copy', function (e) {
      // Отменяем стандартное поведение копирования
      e.preventDefault();

      // Получаем выделенный текст
      const text = window.getSelection().toString();

      // Помещаем чистый текст в буфер обмена
      if (e.clipboardData) {
        e.clipboardData.setData('text/plain', text);
      } else if (window.clipboardData) { // для Internet Explorer
        window.clipboardData.setData('Text', text);
      }
    });
    document.title = this.$vuetify.lang.t('$vuetify.menu.' + this.$route.name)
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="sass">
@import "assets/sass/style.sass"
</style>
