<template>
  <div class="container">
    <div class="page page-404">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.SelectCompany') }}</h1>
      <div class="flex flex-column">
        <button class="btn btn-secondary" v-for="org in organizations" :key="org.id"
                @click="selectCompany(org)">
          {{ org.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectCompanyLayout",
  data() {
    return {
      organizations: [],
      port: window.location.port,
      protocol: window.location.protocol,
      query: window.location.search,
    }
  },

  created() {
    if (!(localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"))) {
      this.$router.push({name: "AuthLayout"});
    } else {
      this.loadUser();
    }
  },

  methods: {
    loadUser() {
      this.$axios({
        url: this.$hostname + "time-tracking/user-companies",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.organizations = response.data.data.organizations
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
      })
    },
    selectCompany(org) {
      localStorage.setItem("organization", JSON.stringify(org));
      this.$router.push({name: "main"});
    }
  }
}
</script>

<style scoped>

</style>